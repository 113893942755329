import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import './BreadCrumbs.css'  

const BreadCrumbs = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const link = {
        color: 'rgb(150, 150, 150)'
    }

    console.log(location)

    let bread

    if (location.pathname === '/finances') {
        bread = Array.from({length: 1}, (_, index) => {
            return (
                <>
                <span style={link}>Операции</span>
                </>
            )
        })
    }

    if (location.pathname === '/tracker') {
        bread = Array.from({length: 1}, (_, index) => {
            return (
                <>
                <span style={link}>Трекер</span>
                </>
            )
        })
    }

    if (location.pathname === '/directory/services') {
        bread = Array.from({length: 1}, (_, index) => {
            return (
                <>
                &nbsp;
                <span style={link}>Справочники</span>
                &nbsp;
                &gt;
                &nbsp;
                <Link to='/directory/services' style={link}>Услуги</Link>
                </>
            )
        })
    }

    if (location.pathname === '/directory/debets') {
        bread = Array.from({length: 1}, (_, index) => {
            return (
                <>
                &nbsp;
                <span style={link}>Справочники</span>
                &nbsp;
                &gt;
                &nbsp;
                <Link to='/directory/debets' style={link}>Счета</Link>
                </>
            )
        })
    }

    if (location.pathname === '/directory/categories') {
        bread = Array.from({length: 1}, (_, index) => {
            return (
                <>
                &nbsp;
                <span style={link}>Справочники</span>
                &nbsp;
                &gt;
                &nbsp;
                <Link to='/directory/categories' style={link}>Категории</Link>
                </>
            )
        })
    }

    if (location.pathname === '/directory/clients') {
        bread = Array.from({length: 1}, (_, index) => {
            return (
                <>
                &nbsp;
                <span style={link}>Справочники</span>
                &nbsp;
                &gt;
                &nbsp;
                <Link to='/directory/clients' style={link}>Клиенты</Link>
                </>
            )
        })
    }

    if (location.pathname === '/directory/employes') {
        bread = Array.from({length: 1}, (_, index) => {
            return (
                <>
                &nbsp;
                <span style={link}>Справочники</span>
                &nbsp;
                &gt;
                &nbsp;
                <Link to='/directory/employes' style={link}>Сотрудники</Link>
                </>
            )
        })
    }

    if (location.pathname === '/directory/clients/detail') {
        bread = Array.from({length: 1}, (_, index) => {
            return (
                <>
                &nbsp;
                <span style={link}>Справочники</span>
                &nbsp;
                &gt;
                &nbsp;
                <Link to='/directory/clients' style={link}>Клиенты</Link>
                &nbsp;
                &gt;
                &nbsp;
                <span style={link}>{props.groupName}</span>
                &nbsp;
                &gt;
                &nbsp;
                <Link to='/directory/clients/detail' style={link}>{props.name}</Link>
                </>
            )
        })
    }

    return (
        <div className={props?.isClient ? 'bread_none' : 'bread_container'}>
        {bread}
        </div>
    )
}

export default BreadCrumbs