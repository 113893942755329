import "./styles.css";
import "./../Finances.css";
import NavBar from "../../components/NavBar.js";
import { PlanFactChart } from "./components/PlanFactChart.jsx";

const PlanFactPage = () => {
  return (
    <div
      className="helper_container_page"
      style={{ minHeight: "46vmax", width: "fit-content" }}
    >
      <NavBar />
      <div className="planfact_container">
        <div>
          <div className="first_row" id="planfact_row">
            <div className="operations" id="plan_operations">
              Платежный календарь
            </div>
          </div>
          <div>
            <PlanFactChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanFactPage;
