import { makeAutoObservable } from "mobx";
import { TransactionsList } from "../components/TransactionsList";

class PlanFactStore {
  data = [];
  isLoading = true;
  uniqueDates = [];
  uniqueDatesWithTypes = [];
  rowsData = [];
  isOpen = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  setData(data) {
    this.data = data;
    this.uniqueDates = this.extractUniqueDates();
    this.uniqueDatesWithTypes = this.extractUniqueDatesWithTypes();
    this.rowsData = this.transformData(data);
    this.isLoading = false;
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  extractUniqueDates() {
    return this.data.map(({ date: dateString }) => {
      const date = new Date(dateString);
      const month = date
        .toLocaleString("default", { month: "short" })
        .replace(".", "");
      const year = date.toLocaleString("default", { year: "2-digit" });
      return `${date.getDate()} ${month} ${year}`;
    });
  }

  extractUniqueDatesWithTypes() {
    return this.data.map(({ date: dateString, type }) => {
      const date = new Date(dateString);
      const month = date
        .toLocaleString("default", { month: "short" })
        .replace(".", "");
      const year = date.toLocaleString("default", { year: "2-digit" });
      return {
        date: `${date.getDate()} ${month} ${year}`,
        type,
      };
    });
  }

  transformData(data) {
    const results = {
      incomes: {
        key: "incomes",
        title: "Доходы",
        summaryData: Array.from({ length: data.length }, () => "-"),
        detailedData: [],
        leafNodes: [],
      },
      expenses: {
        key: "expenses",
        title: "Расходы",
        summaryData: Array.from({ length: data.length }, () => "-"),
        detailedData: [],
        leafNodes: [],
      },
    };

    function mergeCategoryGroups(groups, resultGroups, index) {
      groups.forEach((group) => {
        let resultGroup = resultGroups.find((g) => g.title === group.name);

        if (!resultGroup) {
          resultGroup = {
            title: group.name,
            key: group.id,
            detailedData: [],
            summaryData: Array.from({ length: data.length }, () => "-"),
            leafNodes: [],
          };
          resultGroups.push(resultGroup);
        }

        group.categories.forEach((category) => {
          let resultCategory = resultGroup.detailedData.find(
            (c) => c.title === category.name
          );

          if (!resultCategory) {
            resultCategory = {
              title: category.name,
              summaryData: Array(data.length)
                .fill()
                .map(() => []),
              leafNodes: [],
            };
            resultGroup.detailedData.push(resultCategory);
          }

          resultCategory.summaryData[index] = category.transactions.length ? (
            <TransactionsList transactions={category.transactions} />
          ) : (
            "-"
          );

          if (resultGroup.leafNodes[index]) {
            resultGroup.leafNodes[index].push(...category.transactions);
          } else {
            resultGroup.leafNodes[index] = category.transactions;
          }
        });
      });
    }

    data.forEach((entry, index) => {
      mergeCategoryGroups(
        entry.incomes.category_groups,
        results.incomes.detailedData,
        index
      );
      mergeCategoryGroups(
        entry.expenses.category_groups,
        results.expenses.detailedData,
        index
      );

      if (!results.incomes.leafNodes[index]) {
        results.incomes.leafNodes[index] = [];
      }

      results.incomes.detailedData.forEach((detailedItem) => {
        if (detailedItem.leafNodes[index]) {
          results.incomes.leafNodes[index].push(
            ...detailedItem.leafNodes[index]
          );
        }
      });

      if (!results.expenses.leafNodes[index]) {
        results.expenses.leafNodes[index] = [];
      }

      results.expenses.detailedData.forEach((detailedItem) => {
        if (detailedItem.leafNodes[index]) {
          results.expenses.leafNodes[index].push(
            ...detailedItem.leafNodes[index]
          );
        }
      });
    });

    return [
      {
        title: "Общий баланс",
        key: "balances",
        detailedData: [],
        summaryData: data.map((entry) => entry.balances.total),
      },
      results.incomes,
      results.expenses,
    ];
  }

  toggleRow(key) {
    this.isOpen.set(key, !this.isOpen.get(key));
  }

  getRowState(key) {
    return this.isOpen.get(key) || false;
  }
}

export const planFactStore = new PlanFactStore();
