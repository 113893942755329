import './Error.css'
import { useState, useEffect } from 'react'

const Error = (props) => {

    const [errors, setErrors] = useState(props?.errors)
    const [message, setMessage] = useState(props?.message)
    const [showNotification, setShowNotification] = useState(false)

    useEffect(() => {
        if (props?.errors === true) {
            setErrors(props?.errors)
            setTimeout(() => {
                setErrors(false)
            }, 5000)
        } else {
            setShowNotification(true)
            setTimeout(() => {
                setShowNotification(false)
            }, 5000)
        }
    }, [props?.errors])

    useEffect(() => {
        if (typeof sessionStorage.getItem('notification') === 'string') {
            setShowNotification(true)
            setTimeout(() => {
                setShowNotification(false)
            }, 5000)
        }
    }, [sessionStorage.getItem('notification')])

    if (props?.errors === true){

    return (
        <div className={errors ? 'error_container_active' : 'error_container'}>
            <div className='left_error'>
                <div className='error_title'>Ошибка</div>
                    <div className='error_message_' >{JSON?.stringify(props?.message, null, '\t')}</div>
            </div>
            <div classsName='right_error'>
                <div className='close_error' onClick={() => {setErrors(false)}}>X</div>
            </div>
        </div>
    )
    } else {
        if (typeof sessionStorage.getItem('notification') === 'string' && sessionStorage.getItem('type') === 'create_operation') {
            setTimeout(() => {
                sessionStorage.setItem('notification', null)
                sessionStorage.setItem('type', null)
            }, 5000)
            return (
                <div className={showNotification ? 'notification_active' : 'notification'}>
                <div className='left_error'>
                    <div className='error_title'>Уведомление</div>
                    <div className='error_message_' style={{color: 'green'}}>{sessionStorage.getItem('notification')}</div>
                </div>
                <div className='right_error'>
                    <div className='close_error' onClick={() => {setShowNotification(false)}}>X</div>
                </div>
                </div>
            )
        }
    }
}

export default Error