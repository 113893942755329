import { STORE } from "../../../STORE";

const apiUrl = "https://eternity-tracker.ru/api/finances/payment-calendar/";

export async function getPlanFact() {
  const response = await fetch(apiUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Token ${STORE.readToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      exclude_debets: [],
    }),
  });
  return await response.json();
}
