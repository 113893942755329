import './Helper.css'
import NavBar from '../components/NavBar.js'
import { useState, useEffect } from 'react'
import { STORE } from '../STORE.js'
import NewService from '../components/NewService.js'
import Error from '../components/Error.js'
import BreadCrumbs from '../Logic/BreadCrumbs.js'
import { useNavigate } from 'react-router-dom'
import MediaQuery from 'react-responsive'

const HelperScores = () => {
    const navigate = useNavigate()
    const [success, setSuccess] = useState(true)
    const handleNewService = (event) => {
        console.log(event.target)
        if (event.target.id === 'make_category') {
            setType('service')
            setShowCreate(true)
            setStatus('create')
        }
        if (event.target.id === 'make_group') {
            setType('group')
            setShowCreate(true)
            setStatus('create')
        } else {
            setShowModal(!showModal)
        }
    }


    const makeService = (event) => {
        setShowCreate(true)
        setType('service')
        setStatus('create')
        setPreselectedGroup(event.currentTarget.id)
    }

    const handleCloseModal = (event) => {
        if (event.target.id !== 'create_button_helper__' && event.target.id !== 'svg') {
            setShowModal(false)
        } else if (event.target.id === 'create_button_helper__' || event.target.id === 'svg') {
            setShowModal(!showModal)
        }
    }

    const closeModal = (event) => {
        if (event.target.className === 'modal_overlay') {
            setShowCreate(false)
            setStatus()
            setType()
        }
    }

    const updateData = (value) => {
        setShowCreate(!showCreate)
        setUpdate(!update)
    }

    const openService = (event) => {
        setStatus('edit')
        setType('service')
        setID(event.currentTarget.id?.split('_')[0])
        setShowCreate(true)
    }

    const openGroup = (event) => {
        setStatus('edit')
        setType('group')
        setGroupID(event.currentTarget.id?.split('_')[0])
        setShowCreate(true)
    }

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [total, setTotal] = useState()
    const [update, setUpdate] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState('service')
    const [status, setStatus] = useState('create')
    const [showCreate, setShowCreate] = useState(false)
    const [groupID, setGroupID] = useState('1')
    const [preselectedGroup, setPreselectedGroup] = useState()
    const [id, setID] = useState('1')

    useEffect(() => {
        if (status === 'create') {
            setGroupID('1')
            setPreselectedGroup()
            setID('1')
        }
    }, [status, type])

    const API = 'https://eternity-tracker.ru/api/finances/directory/products/'
    const [errors, setErrors] = useState()
    useEffect(() => {
        setLoading(true)
        fetch(API, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setErrors(data?.errors)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            setData(data?.data)
            setLoading(false)
            console.log(data?.data, '+++++++++++++')
            if (data.detail === 'Invalid token') {
                navigate('/login')
            }
        })
        .catch(error => console.error(error))
    }, [update])

    useEffect(() => {
        console.log(showModal)
    }, [showModal])

    const CARDS = Array.from({length: data?.length}, (_, index) => {

        const CARDS_GROUPED = Array.from({length: data?.[index]?.products?.length}, (_, ind) => {
            return (
                <div className='table_card_grouped' id={`${data?.[index]?.products?.[ind]?.id}_service_id`} onClick={openService}>
                    <div className='table_row1_grouped'>
                        <div className='table_row1_type'>
                            <span style={{fontWeight: '600', color: 'white'}}>{data?.[index]?.products?.[ind]?.name}</span>
                        </div>
                    </div>
                    <div className='table_row2'>
                        <div className='table_row2_debet'>
                            <span style={{fontWeight: '600'}}>Кол-во продаж: </span><span>{data?.[index]?.products?.[ind]?.sales_count}</span>
                        </div>
                    </div>
                    <div className='table_row3'>
                        <div className='table_row3_category'>
                            <span style={{fontWeight: '600'}}>Продолж.: </span><span>{data?.[index]?.products?.[ind]?.duration === 'monthly' ? 'Ежемесячная' : 'Разовая'}</span>
                        </div>
                    </div>
                    <div className='table_row4'>
                        <div className='table_row4_comment_'>
                            <span style={{fontWeight: '600'}}>Комментарий:&nbsp;</span><span>{data?.[index]?.products?.[ind]?.comment}</span>
                        </div>
                    </div>
                    <div className='table_row5'>
                        <div className='table_row5_sum_gp'>
                            <span style={{fontWeight: '600'}}>Цена:&nbsp;</span><span>{data?.[index]?.products?.[ind]?.price?.toLocaleString()}₽</span>
                        </div>
                    </div>
                </div>
            )
        })

        if (data?.[index]?.products?.length === 0) {
            return (
                            <div className='group_box' >
                            <span className='gp_box_title' id={`${data?.[index]?.group_id}_group_id`} onClick={openGroup} style={{fontSize: '5vw', color: 'white', marginLeft: '3vw'}}>{data?.[index]?.group_name}</span>
                            <div className='table_card_grouped'>
                    <div className='table_row1_grouped'>
                        <div className='table_row1_type' style={{color: 'white', width: '40vw'}}>Нет категорий</div>
                    </div>
                    <div className='create_new' id={data?.[index]?.group_name} onClick={makeService}>
                        Создать
                    </div>
                </div>
                            <span className='gp_box_comment'>{data?.[index]?.group_comment}</span>
                        </div>
            )
        } else {

        return (
            <div className='group_box'>
            <span className='gp_box_title' id={`${data?.[index]?.group_id}_group_id`} onClick={openGroup} style={{fontSize: '5vw', color: 'white', marginLeft: '3vw'}}>{data?.[index]?.group_name}</span>
            {CARDS_GROUPED}
            <span className='gp_box_comment'>{data?.[index]?.group_comment}</span>
        </div>
        )}
    })

    const Grouped = Array.from({length: data?.length}, (_, ind) => {

        const makeCellsName = Array.from({length: data?.[ind]?.products?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' id={`${data?.[ind]?.products?.[index]?.id}_service_id`} onClick={openService}>{data?.[ind]?.products?.[index]?.name}</div>
            )
        })
    
        const makeCellsSales = Array.from({length: data?.[ind]?.products?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' id={`${data?.[ind]?.products?.[index]?.id}_service_id`} onClick={openService}>{data?.[ind]?.products?.[index]?.sales_count}</div>
            )
        })
    
        const makeCellsDuration = Array.from({length: data?.[ind]?.products?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' id={`${data?.[ind]?.products?.[index]?.id}_service_id`} onClick={openService}>{data?.[ind]?.products?.[index]?.duration === 'monthly' ? 'Ежемесячная' : 'Разовая'}</div>
            )
        })
    
        const makeCellsPrice = Array.from({length: data?.[ind]?.products?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' id={`${data?.[ind]?.products?.[index]?.id}_service_id`} onClick={openService}>{data?.[ind]?.products?.[index]?.price?.toLocaleString()}₽</div>
            )
        })
    
        const makeCellsComment = Array.from({length: data?.[ind]?.products?.length}, (_, index) => {
            let comment = data?.[ind]?.products?.[index]?.comment
            if (comment === null) {
                comment = '—'
            } else {
                if (comment.length > 50) {
                    comment = comment.split('')
                    comment = comment.slice(0, 50)
                    comment = comment.join('')
                    comment = comment + '...'
                }
            }
            return (
                <div className='column_helper_cell' id={`${data?.[ind]?.products?.[index]?.id}_service_id`} onClick={openService}>{comment}</div>
            )
        })

        if (data?.[ind]?.products?.length === 0) {
            return (
                <><div className='group_title' id={`${data?.[ind]?.group_id}_group_id`}  onClick={openGroup}>
                <div className='gp_title'>{data?.[ind]?.group_name}</div>
            </div><div className='table_helper_container'>
                    <div className='table_column' id='name_column'>
                        <div className='column_helper_cell'></div>
                    </div>
                    <div className='table_column' id='group_column'>
                        <div className='column_helper_cell'></div>
                    </div>
                    <div className='table_column' id='type_column'>
                    <div className='column_helper_cell' id='make_new'> Нет услуг
                    <div className='create_tooltip' id={data?.[ind]?.group_name} style={{color: 'white'}} onClick={makeService}>Создать услугу</div>
                    </div>
                    </div>
                    <div className='table_column' id='price_column'>
                    <div className='column_helper_cell'></div>
                    </div>
                    <div className='table_column' id='comment_column'>
                    <div className='column_helper_cell'></div>
                    </div>
                </div></>
            )
        } else {

        return (
            <><div className='group_title' id={`${data?.[ind]?.group_id}_group_id`} onClick={openGroup}>
                <div className='gp_title'>{data?.[ind]?.group_name}</div>
            </div><div className='table_helper_container'>
                    <div className='table_column' id='name_column'>
                        {makeCellsName}
                    </div>
                    <div className='table_column' id='group_column'>
                        {makeCellsDuration}
                    </div>
                    <div className='table_column' id='type_column'>
                        {makeCellsSales}
                    </div>
                    <div className='table_column' id='price_column'>
                        {makeCellsPrice}
                    </div>
                    <div className='table_column' id='comment_column'>
                        {makeCellsComment}
                    </div>
                </div></>
        )}
    })

    useEffect(() => {

        if (showCreate) {
            document.body.style.overflowY = "hidden";
        }

        if (!showCreate) {
            document.body.style.overflowY = "visible"
        };
    }, [showCreate]);

    if (!STORE.readToken()) {
        navigate('/login')
    } else {

    return (
        <body><div className='helper_container_page'  onClick={handleCloseModal}>
                                                                    <Error errors={!success}/>
            <NavBar />
            <div className='main_helper' id='service_helper_'>
                <BreadCrumbs />
                <MediaQuery minWidth={340} maxWidth={900}>
                <div className='small_search_box' id='search_helper' style={{marginTop: '20vw'}}>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search_helper' />
                </div>
                </MediaQuery>
                <div className='helper_row'>
                <div className='create_button' id='create_button_helper__' style={showModal ? {transform: 'scale(1.1)'} : {}} onClick={handleNewService}>
                    Создать
                    <div className='svg_arrow' id='svg' style={showModal ? {transform: 'rotate(90deg)'} : {transform: 'rotate(0)'}}>
                        &gt;
                    </div>
                </div>
                <div className='options__' style={showModal ? {visibility: 'visible', opacity: '1'} : {visibility: 'hidden', opacity: '0'}}>
                    <div className='optionList' id='make_category' onClick={handleNewService}>
                        Услуга
                    </div>
                    <div className='optionList' id='make_group' onClick={handleNewService}>
                        Группа
                    </div>
                </div>
                <MediaQuery minWidth={900}>
                <div className='small_search_box' id='search_helper'>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search_helper' />
                </div>
                </MediaQuery>
                </div>
                <MediaQuery minWidth={900}>
                <div className='table_helper_container'>
                    <div className='table_column top_column' id='name_column'>
                        <div className='table_column_title column_helper_cell'>Название</div>
                    </div>
                    <div className='table_column top_column' id='group_column'>
                        <div className='table_column_title column_helper_cell'>Продолжительность</div>
                    </div>
                    <div className='table_column top_column' id='type_column'>
                        <div className='table_column_title column_helper_cell'>Кол-во продаж</div>
                    </div>
                    <div className='table_column top_column' id='price_column'>
                        <div className='table_column_title column_helper_cell'>Цена</div>
                    </div>
                    <div className='table_column top_column' id='comment_column'>
                        <div className='table_column_title column_helper_cell'>Комментарий</div>
                    </div>
                </div>
                {Grouped}
                </MediaQuery>
                <MediaQuery minWidth={340} maxWidth={900}>
                    <div className='card_box' style={{marginTop: '1vh', marginLeft: '0'}}>
                        {CARDS}
                    </div>
                </MediaQuery>
            </div>
        </div>
        <div className={(showCreate) ? 'modal_overlay' : 'modal_inactive'} onClick={closeModal}>
            <NewService groupID={groupID} showCreate={showCreate} preselected={preselectedGroup} id={id} status={status} update={updateData} type={type}/>
        </div></body>
    )}
}

export default HelperScores