import './Registration.css'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { STORE } from '../STORE.js'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import Error from '../components/Error.js'

const Registration = observer(() => {
    const [success, setSuccess] = useState(true)
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        password: '',
    })

    const correctPhone = (phone) => {
        phone = phone.split('')
        let newPhone = phone.map(item => +item)
        newPhone = newPhone.filter(item => !isNaN(item))
        newPhone.shift()
        newPhone.unshift(8)
        newPhone = newPhone.join('')
        return newPhone
    }

    const validate = (data) => {
        for (let key in data) {
            if (data[key] === '') {
                if (key === 'first_name') {
                    setEFirst_name('Это поле не может быть пустым')
                }
                if (key === 'last_name') {
                    setELast_name('Это поле не может быть пустым')
                }
                if (key === 'password') {
                    setEPassword('Это поле не может быть пустым')
                }
            } else if (data[key] === '8') {
                if (key === 'phone') {
                    setEPhone('Это поле не может быть пустым')
                }
            } else {
                if (key === 'first_name') {
                    setEFirst_name(null)
                }
                if (key === 'last_name') {
                    setELast_name(null)
                }
                if (key === 'password') {
                    setEPassword(null)
                }
                if (key === 'phone') {
                    setEPhone(null)
                }
            }
        }
    }

    const [errors, setErrors] = useState({})

    const [Ephone, setEPhone] = useState(null)
    const [Efirst_name, setEFirst_name] = useState(null)
    const [Elast_name, setELast_name] = useState(null)
    const [Epassword, setEPassword] = useState(null)
    
    useEffect(() => {
        console.log(errors, 'errros_____')
    }, [errors])

    const handleSubmit = (event) => {
        formData.phone = correctPhone(formData.phone)
        console.log(formData)
        
        validate(formData)

        if (STORE.token === '' || STORE.token === undefined || STORE.token === null) {
            if (formData.last_name !== '' && formData.first_name !== '' && formData.phone !== '8' && formData.password !== '') {
        fetch('https://eternity-tracker.ru/api/auth/registration/', {
            method: 'POST',
            body: JSON.stringify({
                first_name: formData.first_name,
                last_name: formData.last_name,
                phone: formData.phone,
                password: formData.password,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
        })
        .then(response => response.json())
        .then(data => {
                            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            STORE.writeToken(data.data)
            setErrors(data.errors)
            if (data?.success) {
                navigate('/')
            }
        })
        .catch((error) => {
            console.log(error)
        })}

        } else {
            navigate('/tracker')
        }

        
    
    }



    const handleChange = (event) => {
        if (event.target.id === 'name') {
            formData.first_name = event.target.value
        } else if (event.target.id === 'surname') {
            formData.last_name = event.target.value
        } else if (event.target.id === 'phone') {
            formData.phone = event.target.value
            console.log(formData.phone)
            console.log(correctPhone(formData.phone))
        } else if (event.target.id === 'password') {
            formData.password = event.target.value
        }
    }


    return (
        <div className='registration_container'>
                                                                    <Error errors={!success}/>
            <div className='left_column'>
                <div className='blue_circle'></div>
                <div className='blur_box'></div>
            </div>
            <div className='right_column'>
                <div className='registration_form'>
                    <div className='registration_title'>Создать учетную запись</div>
                    <div className={( Efirst_name === null || Efirst_name === undefined) ? 'form_input' : 'form_input_error'}>
                        <label htmlFor='input' className='text' id='name_text'>Имя</label>
                        <input type='text' name='input' className='input_login' id='name' onChange={handleChange} />
                    </div>
                    <div className='error_message_active'>{Efirst_name}</div>
                    <div className={( Elast_name === null || Elast_name === undefined) ? 'form_input' : 'form_input_error'}>
                        <label htmlFor='input' className='text'>Фамилия</label>
                        <input type='text' name='input' className='input_login' id='surname' onChange={handleChange} />
                    </div>
                    <div className='error_message_active'>{Elast_name}</div>
                    <div className={( Ephone === null || Ephone === undefined) ? 'form_input' : 'form_input_error'}>
                        <label htmlFor='input' className='text'>Телефон</label>
                        <InputMask mask='9(999)-999-99-99' type='text' name='input' className='input_login' id='phone' onChange={handleChange}  />
                    </div>
                    <div className='error_message_active'>{Ephone}</div>
                    <div className={( Epassword === null || Epassword === undefined) ? 'form_input' : 'form_input_error'}>
                        <label htmlFor='input' className='text' id='password_text'>Пароль</label>
                        <input type='text' name='input' className='input_login' id='password' onChange={handleChange} />
                    </div>
                    <div className='error_message_active'>{Epassword}</div>
                    <input type='submit' value='Продолжить' className='submit_button' onClick={handleSubmit}/>
                    <div className='to_login'>Уже есть аккаунт? 
                        <Link to='/login' style={{textDecoration: 'none'}}>
                        <div className='login_link'>Войти</div>
                        </Link>
                        </div>
                </div>
            </div>
        </div>
    )
    })

export default Registration