import './Helper.css'
import NavBar from '../components/NavBar.js';
import BreadCrumbs from '../Logic/BreadCrumbs.js'
import { STORE } from '../STORE.js'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import NewEmploye from '../components/NewEmploye.js'
import MediaQuery from 'react-responsive'
import Error from '../components/Error.js'

const HelperEmployes = () => {
    const navigate = useNavigate()

    const [type, setType] = useState('employe')
    const [status, setStatus] = useState('create')

    const handleNewOperation = (event) => {
        if (event.target.id === 'make_category') {
            setType('employe')
            setShowCreate(true)
            setStatus('create')
            setShowModal(false)
        }
        if (event.target.id === 'make_group') {
            setType('group')
            setShowCreate(true)
            setStatus('create')
            setShowModal(false)
        } else {
            setShowModal(!showModal)
        }
    }

    const closeModal = (event) => {
        if (event.target.className === 'modal_overlay') {
            setShowCreate(false)
            setStatus()
            setType()
        }
    }

    const updateData = () => {
        setUpdate(!update)
        setShowCreate(false)
        setShowModal(false)
        setType('employe')
        setStatus('create')
    }

    const [update, setUpdate] = useState(false)
    const [showCreate, setShowCreate] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [preselectedGroup, setPreselectedGroup] = useState()
    const [id, setID] = useState()
    const [groupID, setGroupID] = useState()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()

    const API = 'https://eternity-tracker.ru/api/finances/directory/employees/'

    useEffect(() => {
        setLoading(true)
        fetch(API, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data?.data)
            setLoading(false)
            if (data.detail === 'Invalid token') {
                navigate('/login')
            }
        })
        .catch(error => console.error(error))
    }, [update])

    const makeEmploye = (event) => {
        setShowCreate(true)
        setType('employe')
        setStatus('create')
        setPreselectedGroup(event.currentTarget.id)
    }

    const openGroup = (event) => {
        setStatus('edit')
        setType('group')
        setGroupID(event.currentTarget.id?.split('_')[0])
        setShowCreate(true)
    }

    const openEmploye = (event) => {
        setStatus('edit')
        setType('employe')
        setID(event.currentTarget.id?.split('_')[0])
        setShowCreate(true)
    }

    const groups = Array.from({length: data?.length}, (_, index) => {

        const table = Array.from({length: data?.[index]?.employees?.length}, (_, ind) => {
            return (
                <div className='table_string' onClick={openEmploye} id={`${data?.[index]?.employees?.[ind]?.id}_employe_id`}>
                    <div className='string_cell'>
                        {data?.[index]?.employees?.[ind]?.name} {data?.[index]?.employees?.[ind]?.last_name}
                    </div>
                    <div className='string_cell'>
                        {data?.[index]?.employees?.[ind]?.position}
                    </div>
                    <div className='string_cell'>
                        {data?.[index]?.employees?.[ind]?.current_month_salary?.toLocaleString() || 0}₽
                    </div>
                    <div className='string_cell'>
                       {data?.[index]?.employees?.[ind]?.total_salary?.toLocaleString() || 0}₽
                    </div>
                </div>
            )
        })

        if (data?.[index]?.employees?.length === 0) {
            return (
                <><div className='group_title_' id={`${data?.[index]?.department_id}_group_id`}  onClick={openGroup}>
                <div className='gp_title_'>{data?.[index]?.department_name}</div>
            </div><div className='table_helper_container' style={{width: '100%', marginLeft: '0'}}>
                    <div className='table_column' id='name_column'>
                        <div className='column_helper_cell'></div>
                    </div>
                    <div className='table_column' id='group_column'>
                        <div className='column_helper_cell'></div>
                    </div>
                    <div className='table_column' id='type_column'>
                    <div className='column_helper_cell' id='make_new'> Нет сотрудников
                    <div className='create_tooltip' id={data?.[index]?.department_name} style={{color: 'white'}} onClick={makeEmploye}>Создать сотрудника</div>
                    </div>
                    </div>
                    <div className='table_column' id='price_column'>
                    <div className='column_helper_cell'></div>
                    </div>
                    <div className='table_column' id='comment_column'>
                    <div className='column_helper_cell'></div>
                    </div>
                </div></>
            )
        } else {

        return (
            <>
            <div className='group_title_' onClick={openGroup} id={`${data?.[index]?.department_id}_group_id`}>
                <p className='gp_title_'>{data?.[index]?.department_name}</p>
            </div>
            {table}
            </>
        )}

    })

    const CARDS = Array.from({length: data?.length}, (_, index) => {

        const CARDS_GROUPED = Array.from({length: data?.[index]?.employees?.length}, (_, ind) => {
            

            const item = data?.[index]?.employees?.[ind]

            return (
                <div className='table_card_grouped' id={`${data?.[index]?.employees?.[ind]?.id}_client_id`} onClick={openEmploye}>
                    <div className='table_row1_grouped'>
                        <div className='table_row1_type' style={{width: '100%'}}>
                            <span style={{fontWeight: '600', color: 'white'}}>Имя:</span><span style={{color: 'white', marginLeft: '1vw'}}>{data?.[index]?.employees?.[ind]?.name} {data?.[index]?.employees?.[ind]?.last_name}</span>
                        </div>
                    </div>
                    <div className='table_row2'>
                        <div className='table_row2_debet'>
                            <span style={{fontWeight: '600'}}>Должность: </span> <span>{data?.[index]?.employees?.[ind]?.position}</span>
                        </div> 
                    </div>
                    <div className='table_row3'>
                        <div className='table_row3_category'>
                            <span style={{fontWeight: '600'}}>Выплачено /мес.</span> <span> {data?.[index]?.employees?.[ind]?.current_month_salary?.toLocaleString() || '0'}₽</span>
                        </div>
                    </div>
                    <div className='table_row4'>
                        <div className='table_row4_comment_'>
                            <span style={{fontWeight: '600'}}>Выплачено всего: </span> <span style={{marginLeft: '1vw'}}> {data?.[index]?.employees?.[ind]?.total_salary?.toLocaleString() || '0'}₽ </span>
                        </div>
                    </div>
                </div>
            )
        })

        if (data?.[index]?.employees?.length === 0) {
            return (
                            <div className='group_box' >
                            <span className='gp_box_title' id={`${data?.[index]?.department_id}_group_id`} onClick={openGroup} style={{fontSize: '5vw', color: 'white', marginLeft: '3vw'}}>{data?.[index]?.department_name}</span>
                            <div className='table_card_grouped'>
                    <div className='table_row1_grouped'>
                        <div className='table_row1_type' style={{color: 'white', width: '40vw'}}>Нет клиентов</div>
                    </div>
                    <div className='create_new' id={data?.[index]?.department_name} onClick={makeEmploye}>
                        Создать
                    </div>
                </div>
                            <span className='gp_box_comment'>{data?.[index]?.department_comment}</span>
                        </div>
            )
        } else {

        return (
            <div className='group_box'>
            <span className='gp_box_title' id={`${data?.[index]?.department_id}_group_id`} onClick={openGroup} style={{fontSize: '5vw', color: 'white', marginLeft: '3vw'}}>{data?.[index]?.department_name}</span>
            {CARDS_GROUPED}
            <span className='gp_box_comment'>{data?.[index]?.department_comment}</span>
        </div>
        )}
    })

    if (!STORE.readToken()) {
        navigate('/login') 
    } else {

        
    if (loading) {
        return (
            <body style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
  <circle
    class="active"
    pathLength="360"
    fill="transparent"
    stroke-width="32"
    cx="192"
    cy="192"
    r="176"
  ></circle>
  <circle
    class="track"
    pathLength="360"
    fill="transparent"
    stroke-width="32"
    cx="192"
    cy="192"
    r="176"
  ></circle>
</svg>
</body>
        )
    } else {

    return (
    <><div className='helper_container_page'>
        <NavBar />
        <div className='main_helper'>
            <BreadCrumbs />
            <Error />
            <div className='helper_row' id='main_helper_employee'>
                <div className='create_button' id='create_button_helper__' style={showModal ? {transform: 'scale(1.1)'} : {}} onClick={handleNewOperation}>
                    Создать
                    <div className='svg_arrow' id='svg' style={showModal ? {transform: 'rotate(90deg)'} : {transform: 'rotate(0)'}}>
                        &gt;
                    </div>
                </div>
                <div className='options__' style={showModal ? {visibility: 'visible', opacity: '1'} : {visibility: 'hidden', opacity: '0'}}>
                    <div className='optionList' id='make_category' onClick={handleNewOperation}>
                        Сотрудник
                    </div>
                    <div className='optionList' id='make_group' onClick={handleNewOperation}>
                        Отдел
                    </div>
                </div>
                <div className='small_search_box' id='search_helper'>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search_helper' />
                </div>

            </div>
            <MediaQuery minWidth={900}>
            <div className='table_container_string'>
                <div className='table_string' id='table_string_titles'>
                    <div className='string_cell header_string'>Имя</div>
                    <div className='string_cell header_string'>Должность</div>
                    <div className='string_cell header_string'>Выплачено /мес.</div>
                    <div className='string_cell header_string'>Выплачено всего</div>
                </div>
                {groups}
            </div>
            </MediaQuery>
            <MediaQuery minWidth={340} maxWidth={900}>
            <div className='card_box' style={{marginTop: '2vw', marginLeft: '0'}}>
                            {CARDS}
                        </div>
            </MediaQuery>
        </div>
    </div>
    <div className={(showCreate) ? 'modal_overlay' : 'modal_inactive'} onClick={closeModal}>
            <NewEmploye groupID={groupID} showCreate={showCreate} preselected={preselectedGroup} id={id} status={status} update={updateData} type={type}/>
        </div>
    </>
    )}
}}

export default HelperEmployes