import './NewOperation.css'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useState, useEffect } from 'react'
import ru from 'date-fns/locale/ru';
import { STORE } from '../STORE.js'
import InputMask from 'react-input-mask'
import './NewOperation.css'
import CurrencyInput from 'react-currency-input-field'
import { makeDayISO, makeMonthISO, makeYearISO } from '../Logic/DateFormating.js'
import Error from '../components/Error.js'

const NewDebet = (props) => {

    const [startDate, setStartDate] = useState()
    const [currentModal, setCurrentModal] = useState('income_button')
    const [endDate, setEndDate] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleModal = () => {
        props.update()
        setErrors(null)
    }

    useEffect(() => {
        setErrors(null)
    }, [props.updateErrors])

    const handleChangeModal = (event) => {
        if (event.target.className.length < 14) {
            setCurrentModal(event.target.className)
            setValue('')
            setRemain(false)
        }
    }


    const [dataDetail, setDataDetail] = useState()
    const [selectedBank, setSelectedBank] = useState()
    const [debetName, setDebetName] = useState()
    const [success, setSuccess] = useState(true)

    const APIdetail = `https://eternity-tracker.ru/api/finances/directory/debets/detail/${props.id}`
    useEffect(() => {

        if (props.id !== undefined){
            setLoading(true)
        fetch(APIdetail, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            setDataDetail(data?.data)
        })
        .catch(error => console.error(error))
    }
    }, [props.id])

    const [comment, setComment] = useState(null)

    useEffect(() => {
        //(props.status)
        if (props.status === 'edit') {
            setSelectedBank(dataDetail?.bank)
            setDebetName(dataDetail?.name)
            setRekvisit(dataDetail?.requisites)
            setStartDate(dataDetail?.initial_balance_date === null || dataDetail?.initial_balance_date === undefined ? new Date() : new Date(dataDetail?.initial_balance_date))
            setBalance(dataDetail?.initial_balance)
            setComment(dataDetail?.comment)
            setFiz(dataDetail?.private_person)
            //(dataDetail)
        } if (props.status === 'create') {
            setSelectedBank('Тинькофф')
            setDebetName('')
            setRekvisit('')
            setStartDate()
            setBalance('')
            setComment('')
            setFiz(false)
        }

        setErrors(null)

    }, [dataDetail, props.status])

    const [day, setDay] = useState()
    const [month, setMonth] = useState()
    const [year, setYear] = useState()

    useEffect(() => {
        if (dataDetail?.created_at !== undefined) {
            setDay(makeDayISO(dataDetail?.created_at))
            setMonth(makeMonthISO(dataDetail?.created_at))
            setYear(makeYearISO(dataDetail?.created_at))
        }
    }, [dataDetail])

    const [dataSend, setDataSend] = useState({

    })

    const [type, setType] = useState('income')
    const [date, setDate] = useState(new Date().toLocaleDateString()?.split('.')?.reverse()?.join('-'))
    const [confirmed, setConfirm] = useState(false)
    const [sum, setSum] = useState(null)
    const [debet_id, setDebet_id] = useState(1)
    const [debet2_id, setDebet2_id] = useState(null)
    const [category_id, setCategory_id] = useState(1)
    const [client_id, setClient_id] = useState(1)
    const [invoice, setInvoice] = useState(null)
    const [inputValue, setInputValue] = useState(0)
    const [name, setName] = useState('')
    const [bank, setBank] = useState('Тинькофф')
    const [rekvisit, setRekvisit] = useState()
    const [balance, setBalance] = useState()
    const [fiz, setFiz] = useState(false)
    const [update, setUpdate] = useState(false)

    const [errors, setErrors] = useState()
    
    const handleSelect = (event) => {
        if (event.currentTarget.options[event.currentTarget.selectedIndex].innerHTML !== '—') {
            setBank(event.currentTarget.options[event.currentTarget.selectedIndex].innerHTML)
            setSelectedBank(event.currentTarget.options[event.currentTarget.selectedIndex].innerHTML)
        } else {
            setBank(null)
        }
    }

    useEffect(() => {
        setDate(startDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'))
    }, [startDate])

    const handleConfirm = (event) => {
        setConfirm(event.target.checked)
        //(event.target.checked)
    }
    const [value, setValue] = useState();

    const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

    const validateSum = (sum) => {
        sum = sum?.toString()
        sum = sum?.replace(/,/g, '.')
        return +sum
    }

    const handleSum = (event) => {
        let sum = event.target.value.split('')
        sum = sum.join('')
        sum = sum.replace(/\s/g, '')
        sum = sum.replace(/[^\d.,-]/g, '')
        //(validateSum(balance))
        setBalance(sum === '' ? null : sum)
    }

    useEffect(() => {
        setBalance(value?.split(',')?.join(''))
    }, [value])

    const handleComment = (event) => {
        setComment(event.target.value)
    }

    const handleUpload = (event) => {
        setInvoice(event.target.file[0])
    }

    const APIsend = 'https://eternity-tracker.ru/api/finances/directory/debets/create/'

    const [remain, setRemain] = useState(false)

    const handleRemainModal = (event) => {
        setRemain(event.target.checked)
    }

    const handleRekvisit = (event) => {
        setRekvisit(event.target.value)
    }

    const handleFiz = () => {
        setFiz(!fiz)
    }

    const handleName = (event) => {
        setName(event.target.value)
        setDebetName(event.target.value)
    }


    const APIdelete = 'https://eternity-tracker.ru/api/finances/directory/debets/delete/'

    const deleteDebet = () => {
        fetch(APIdelete, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                debet_id: props.id
            })
        })
        .then(() => {
            props.update()
            handleModal()
        })
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
        })
    }


    const [nameError, setNameError] = useState(null)
    const [rekvisitError, setRekvisitError] = useState(null)
    const [commentError, setCommentError] = useState(null)
    const [dateError, setDateError] = useState(null)
    const [balanceError, setBalanceError] = useState(null)


    const handleSubmit = () => {
        if (props.status === 'create') {
        if (name !== '' && name !== undefined && rekvisit !== '' && rekvisit !== undefined && date !== '' && date !== null && date !== undefined) {
        fetch(APIsend, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                bank: bank,
                requisites: rekvisit,
                initial_balance: validateSum(balance),
                initial_balance_date: date,
                private_person: fiz,
                comment: comment === '' || comment === undefined ? null : comment,
            })
        })
        .then(response => response.json())
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            if (data.success) {
                sessionStorage.setItem('notification', data?.notification === undefined ? 'Счет был успешно создан' : data?.notification)
                sessionStorage.setItem('type', 'create_operation')
                if (remain) {

                } else if (!remain) {
                    handleModal()
                    props.update()
                }
            }
            setErrors(data?.errors)
        })
        .then(() => {
            setName('')
            setBank('')
            setRekvisit('')
            setBalance('')
            setDate(null)
            setFiz(false)
            setComment('')
        })
        .catch(error => console.error(error))
    } else {
        if (name === '' || name === undefined) {
            setNameError('Поле не может быть пустым')
        } else {
            setNameError(null)
        }
        if (date === '' || date === undefined || date === null) {
            setDateError('Поле не может быть пустым')
        } else {
            setDateError(null)
        }
        if (rekvisit === '' || rekvisit === undefined) {
            setRekvisitError('Поле не может быть пустым')
        } else {
            setRekvisitError(null)
        }
    }
    } else if (props.status === 'edit') {
        console.log(props.id)
        const APIedit = 'https://eternity-tracker.ru/api/finances/directory/debets/edit/'
        if (debetName !== '' && rekvisit !== '' && date !== '' && validateSum(balance) !== '' && validateSum(balance) !== undefined && validateSum(balance) !== null && date !== null && date !== undefined) {
        fetch(APIedit, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                debet_id: props.id,
                    edit_data: {
                name: debetName,
                bank: selectedBank,
                requisites: rekvisit,
                initial_balance: validateSum(balance),
                initial_balance_date: date,
                private_person: fiz,
                comment: comment === undefined || comment === '' ? null : comment,
            }
            })
        })
        .then(response => response.json())
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            if (data.success) {
                sessionStorage.setItem('notification', data?.notification === undefined ? 'Счет был успешно изменен' : data?.notification)
                sessionStorage.setItem('type', 'create_operation')
                if (remain) {

                } else if (!remain) {
                    handleModal()
                    props.update()
                    //(remain)
                }
            }
            setErrors(data?.errors)
        })
        .catch(error => console.error(error))
    } else {
        if (debetName === '' || debetName === undefined) {
            setNameError('Поле не может быть пустым')
        } else {
            setNameError(null)
        }
        if (date === '' || date === undefined || date === null) {
            setDateError('Поле не может быть пустым')
        } else {
            setDateError(null)
        }
        if (rekvisit === '' || rekvisit === undefined) {
            setRekvisitError('Поле не может быть пустым')
        } else {
            setRekvisitError(null)
        }
        if (validateSum(balance) === '' || validateSum(balance) === undefined || validateSum(null)) {
            setBalanceError('Поле не может быть пустым')
        } else {
            setBalanceError(null)
        }
    }
    }
    }
    //(typeof errors?.name[0])

    useEffect(() => {
        if (props.status === 'edit') {
            setRekvisit(dataDetail?.requisites)
        } else {
            setRekvisit('')
        }
    }, [fiz])

    useEffect(() => {
        if (props?.status === 'edit') {
            setNameError(null)
            setDateError(null)
            setRekvisitError(null)
            setCommentError(null)
        }
    }, [props?.status])

    if (loading) {
        return (
            <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
  <circle
    class="active"
    pathLength="360"
    fill="transparent"
    stroke-width="32"
    cx="192"
    cy="192"
    r="176"
  ></circle>
  <circle
    class="track"
    pathLength="360"
    fill="transparent"
    stroke-width="32"
    cx="192"
    cy="192"
    r="176"
  ></circle>
</svg>
</div>
        )
    } else {

        return (
            <div className='new_operation_container'>
                                        <Error errors={!success} message={errors} />
            <div className='row1'>{props.status === 'edit' ? dataDetail?.name : 'Добавление счета'}
            <div className='creator_box' style={props?.status === 'edit' ? {display: 'flex'} : {display: 'none'}}>
                    <span>Создатель: {dataDetail?.creator?.name}</span>
                    <span>Создана: {day} {month} {year}</span>
                </div>
            </div>
            <div className='data_row modal_row'>
                Название
                <input type='text' id='name_input' onChange={handleName} value={debetName} placeholder='Название счета' style={(typeof nameError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}}/>
                <label htmlFor='name_input' className='error_message_'>{nameError}</label>
            </div>
            <div className='score_row modal_row'>
                Банк
                <select type='text' id='bank_input' placeholder='Выберите счет...' value={selectedBank} onChange={handleSelect}>
                    <option value='null'>—</option>
                    <option value='Тинькофф' selected>Тинькофф</option>
                    <option value='Сбербанк'>Сбербанк</option>
                    <option value='Альфа Банк'>Альфа Банк</option>
                    <option value='ВТБ'>ВТБ</option>
                    <option value='Газпромбанк'>Газпромбанк</option>
                    <option value='OZON банк'>OZON банк</option>
                    <option value='Россельхоз банк'>Россельхоз банк</option>
                    <option value='Совкомбанк'>Совкомбанк</option>
                    <option value='Райффайзен Банк'>Райффайзен Банк</option>
                    <option value='Уралсиб'>Уралсиб</option>
                </select>
            </div>
            <div className='sum_row modal_row' onChange={handleRekvisit}>
                Реквизиты
                <InputMask type='text' value={rekvisit} id='rekvisit_input' placeholder={fiz ? '0000 0000 0000 0000' : '00000 000 0 0000 0000000'} style={(typeof rekvisitError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}}/>
                <label for='rekvisit_input' className='error_message_'>{rekvisitError}</label>
            </div>
            <div className='category_row modal_row'>
                Начальный<br/> остаток
                <CurrencyInput decimalSeparator='.' type='text' prefix='₽'  id='balance_input' value={balance} placeholder='0' onChange={handleSum} style={(typeof balanceError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}} />
                <label htmlFor='balance_input' className='error_message_'>{balanceError}</label>
                <DatePicker  id='date_picker___' dateFormat='dd/MM/YYYY' className={typeof dateError === 'string' ? 'data_pick_error' : 'data_pick'} selected={startDate} onChange={(date) => setStartDate(date)} placeholderText={'Укажите дату'} locale={ru} style={(typeof dateError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vmax solid rgb(255, 255, 255, 0.15);'}}/>
            </div>
            <div className='comment_row modal_row' onChange={handleComment}>
                Комментарий
                <textarea id='comment_input__' value={comment} style={(typeof commentError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}} />
                <label for='comment_input__' className='error_message_'>{commentError}</label>
            </div>
            <div className='file_row modal_row'>
                <div class="checkbox-wrapper-46" id='checkbox12_'>
                            <input type="checkbox" id="cbx-50" class="inp-cbx"  onClick={handleFiz} checked={fiz} />
                            <label for="cbx-50" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1' id='label_10'>
                                    Физическое лицо
                                </span>
                            </label>
                </div>
            </div>
            <div className='footer_row'>
                <div className='delete_button' style={props.status === 'edit' ? {display: 'flex'} : {display: 'none'}} onClick={deleteDebet} >Удалить</div>
                <div className='cancel' onClick={handleModal}>Отменить</div>
                <div className='create' id='create_income' onClick={handleSubmit}>
                    {props.status === 'edit' ? 'Сохранить' : 'Создать'}
                </div>
            </div>
        </div>
    )}
}

export default NewDebet