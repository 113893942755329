import { useState, useEffect } from 'react'
import './NewOperation.css'
import { STORE } from '../STORE.js'
import { makeDayISO, makeMonthISO, makeYearISO } from '../Logic/DateFormating.js'
import Error from '../components/Error.js'
import { useNavigate } from 'react-router-dom'

const NewClient = (props) => {

    
    const [groupName, setGroupName] = useState()
    const [errors, setErrors] = useState()
    const [groupComment, setGroupComment] = useState()
    const [groups, setGroups] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const [selectedGroup, setSelectedGroup] = useState('1')
    const [remain, setRemain] = useState(false)
    const [categoryName, setCategoryName] = useState()
    const [categoryComment, setCategoryComment] = useState()
    const [dataDetail, setDataDetail] = useState()
    const [type, setType] = useState(props.type)
    const [price, setPrice] = useState()
    const [duration, setDuration] = useState()
    const [selectedPrice, setSelectedPrice] = useState()
    const [selectedDuration, setSelectedDuration] = useState()
    const [day, setDay] = useState()
    const [month, setMonth] = useState()
    const [year, setYear] = useState()
    const [success, setSuccess] = useState(true)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)

//__________________________________________ STATES ^^^^^
    
    useEffect(() => {
        setType(props.type)
    }, [props.type])

    const APIgroups = 'https://eternity-tracker.ru/api/finances/directory/customers/detail/create/'
    useEffect(() => {
        fetch(APIgroups, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setGroups(data?.data?.groups)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
        })
        .catch(error => console.error(error))
    }, [updateData, props.status, props.type])

    useEffect(() => {
        setErrors(null)
    }, [props.status, type])

    useEffect(() => {
        console.log(groups)
    }, [groups])

    useEffect(() => {
        let correctID = groups?.find(item => item.name == props.preselected)
        console.log(correctID, '+++++++++++++++')
        setSelectedGroup(correctID?.id)
        setSelectedCategory(props.preselected)
    }, [props.preselected])

    useEffect(() => {
        if (props.status === 'create') {
            if (props.preselected !== undefined && props.preselected !== null && props.preselected !== '') {
                let correctID = groups?.find(item => item.name == props.preselected)
                setSelectedGroup(correctID?.id)
                setSelectedCategory(props.preselected)
            }
        }
    }, [props.status])

    useEffect(() => {

        const APIdetail = `https://eternity-tracker.ru/api/finances/directory/customers/detail/${props.id}`        
            if (props.status === 'edit' && props.type === 'service') {
        fetch(APIdetail, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            if (props.status === 'edit' && props.type === 'service') {
                setCategoryName(data?.data?.name)
                setCategoryComment(data?.data?.comment === null ? '' : data?.data?.comment)
                setSelectedGroup(data?.data?.group?.id)
                setSelectedCategory(data?.data?.group?.name)
                setPrice(data?.data?.price)
                setDuration(data?.data?.duration === '' ? 'one_time' : data?.data?.duration)
                setDataDetail(data?.data)
        }
        })
        .catch(error => console.error(error))
    }
    }, [props.type, props.status, props.id])
    
    useEffect(() => {
        console.log('gfdjgfdhdfjghdfjghfdjghjfdghdfjghdfjghdjghdfjghdfjhgjdfhgfdkghfdgkfdgjhdfkgfdhgkdfghdfgkfdh')
        console.log(props.status, '=========')
        console.log(type, '______________')
        const APIdetailGroup = `https://eternity-tracker.ru/api/finances/directory/customers/group/detail/${props.groupID}`        
        if (props.status === 'edit' && props.type === 'group') {
            setLoading(true)
        fetch(APIdetailGroup, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            if (props.status === 'edit' && props.type === 'group') {
                setGroupName(data?.data?.name)
                setGroupComment(data?.data?.comment === null ? '' : data?.data?.comment)
                setDataDetail(data?.data)
        }
        })
        .catch(error => console.error(error))
    }
    }, [props.type, props.status, props.groupID])

//____________________________________ USE EFFECTS ^^^^^^

    const handleName = (event) => {
        if (props?.type === 'group') {
            setGroupName(event.target.value)
        }
        if (props?.type === 'service') {
            setCategoryName(event.target.value)
        }
    }

    const handleComment = (event) => {
        if (props?.type === 'group') {
            setGroupComment(event.target.value)
        }
        if (props?.type === 'service') {
            setCategoryComment(event.target.value)
        }
    }

    const handleDuration = (event) => {
        setDuration(event.target.value)
        console.log(event.target.value)
    }

    const deleteGroup = () => {
        const APIdeleteGroup = 'https://eternity-tracker.ru/api/finances/directory/customers/group/delete/'
        fetch(APIdeleteGroup, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                group_id: props.groupID,               
            })
        })
        .then(() => {
            props.update()
        })
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
        })
    }

    const deleteCategory = () => {
        const APIdelete = 'https://eternity-tracker.ru/api/finances/directory/customers/delete/'
        fetch(APIdelete, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                category_id: props.id,               
            })
        })
        .then(() => {
            props.update()
        })
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
        })
    }

    const handlePrice = (event) => {
        let sum = event.target.value.split('')
        sum = sum.join('')
        sum = sum.replace(/\s/g, '')
        sum = sum.replace(/[^\d.,-]/g, '')
        setPrice(sum === '' ? null : sum)
    }

    const update = () => {
        setUpdateData(!update)
    }

    const handleModal = () => {
        props.update()
    }

    const [nameError, setNameError] = useState(null)
    const [groupNameError, setGroupNameError] = useState(null)
    const [groupCommentError, setGroupCommentError] = useState(null)

    const navigate = useNavigate()
    const handleSubmit = () => {
        if (props.status === 'create' && props.type === 'service') {
            const APIcreate = 'https://eternity-tracker.ru/api/finances/directory/customers/detail/create/'
            if (categoryName !== '' && categoryName !== undefined) {
            fetch(APIcreate, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: categoryName,
                    group: selectedGroup === undefined ? groups?.[0]?.id : selectedGroup,
                    comment: null,
                    requisites: null,
                    contacts: null,
                })
            })
            .then(response => response.json())
            .then(data => {
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                    sessionStorage.setItem('notification', data?.notification === undefined ? 'Клиент был успешно создан' : data?.notification)
                    sessionStorage.setItem('type', 'create_operation')
                    navigate('/directory/clients/detail', {state: data?.data?.customer_id})
                    if (remain) {
    
                    } else if (!remain) {
                        handleModal()
                        props.update()
                    }
                }
                setErrors(data?.errors)
            })
            .catch(error => console.error(error))
        } else {
            if (categoryName === '' || categoryName === undefined) {
                setNameError('Поле не может быть пустым')
            } else {
                setNameError(null)
            }
        }
        }
        if (props.status === 'create' && props.type === 'group') {
            const APIcreateGroup = 'https://eternity-tracker.ru/api/finances/directory/customers/group/create/'
            if (groupName !== '' && groupName !== undefined) {
            fetch(APIcreateGroup, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: groupName,
                    comment: !groupComment ? null : groupComment,            
                })
            })
            .then(response => response.json())
            .then(data => {
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                    sessionStorage.setItem('notification', data?.notification === undefined ? 'Группа была успешно создана' : data?.notification)
                    sessionStorage.setItem('type', 'create_operation')
                        handleModal()
                        props.update()
                }
                setErrors(data?.errors)
                update()
            })
            .then(() => {
                setGroupName('')
                setGroupComment('')
            })
            .catch(error => console.error(error))
        } else {
            if (groupName === '' || groupName === undefined) {
                setGroupNameError('Поле не может быть пустым')
            } else {
                setGroupNameError(null)
            }
        }
        } 
        if (props.status === 'edit' && props.type === 'service') {
            const APIedit = 'https://eternity-tracker.ru/api/finances/directory/customers/edit/'
            fetch(APIedit, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    product_id: props.id,
                    edit_data: {
                    name: categoryName,
                    comment: categoryComment,
                    group_id: selectedGroup,
                    price: price,
                    duration: duration, 
                    }              
                })
            })
            .then(response => response.json())
            .then(data => {
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                    if (remain) {
    
                    } else if (!remain) {
                        handleModal()
                        props.update()
                    }
                }
                setErrors(data?.errors)
            })
            .catch(error => console.error(error))
        }
        if (props.status === 'edit' && props.type === 'group') {
            const APIeditGroup = 'https://eternity-tracker.ru/api/finances/directory/customers/group/edit/'
            fetch(APIeditGroup, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    group_id: props.groupID,
                    edit_data: {
                    name: groupName,
                    comment: !groupComment ? null : groupComment, 
                    }              
                })
            })
            .then(response => response.json())
            .then(data => {
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                    sessionStorage.setItem('notification', data?.notification === undefined ? 'Группа была успешно изменена' : data?.notification)
                    sessionStorage.setItem('type', 'create_operation')
                    if (remain) {
    
                    } else if (!remain) {
                        handleModal()
                        props.update()
                    }
                }
                setErrors(data?.errors)
            })
            .catch(error => console.error(error))
        }
    }

    const handleRemainModal = () => {
        setRemain(!remain)
    }

    const handleSelect = (event) => {
        setSelectedGroup(event.currentTarget.options[event.currentTarget.selectedIndex].id?.split('_')[0])
        setSelectedCategory(event.currentTarget.options[event.currentTarget.selectedIndex].innerHTML)
    }

//___________________________ HANDLERS ^^^^^^^

    const makeCategoryOptions = Array.from({length: groups?.length}, (_, index) => {
        return (
            <option value={groups?.[index]?.name} id={`${groups?.[index]?.id}_category_group`}>{groups?.[index]?.name}</option>
        )
    })

    useEffect(() => {
        if (dataDetail?.created_at !== undefined) {
            setDay(makeDayISO(dataDetail?.created_at))
            setMonth(makeMonthISO(dataDetail?.created_at))
            setYear(makeYearISO(dataDetail?.created_at))
        }
    }, [dataDetail])

    useEffect(() => {
        console.log(groups?.[0]?.id)
        console.log(groups?.[0]?.name)
        if (!props.showCreate && props.status === 'create') {
            setCategoryName()
            setCategoryComment()
            setSelectedGroup(groups?.[0]?.id)
            setSelectedCategory(groups?.[0]?.name)
            setPrice()
            setDuration()
            setGroupName()
            setGroupComment()
        }
    }, [props.showCreate])
    
//________________________________ BUILDER FUNCTIONS ^^^^

if (loading) {
    return (
        <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
<circle
class="active"
pathLength="360"
fill="transparent"
stroke-width="32"
cx="192"
cy="192"
r="176"
></circle>
<circle
class="track"
pathLength="360"
fill="transparent"
stroke-width="32"
cx="192"
cy="192"
r="176"
></circle>
</svg>
</div>
    )
} else {

    if (props?.type === 'group') {
        return (
            <div className='new_operation_container' id='category_create'>
                        <Error errors={!success} message={errors} />
                <div className='row1'>{props.status === 'edit' ? `Редактирование группы` : 'Создание группы'}
                <div className='creator_box' style={props?.status === 'edit' ? {display: 'flex'} : {display: 'none'}}>
                    <span>Создатель: {dataDetail?.creator?.name}</span>
                    <span>Создана: {day} {month} {year}</span>
                </div>
                </div>
                <div className='data_row modal_row'>
                    Название
                    <input type='text' id='name_input' onChange={handleName} value={groupName} placeholder='Название группы' style={(typeof groupNameError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}}/>
                    <label htmlFor='name_input' className='error_message_'>{groupNameError}</label>
                </div>
                <div className='comment_row modal_row' onChange={handleComment}>
                Комментарий
                <textarea id='comment_input__'  value={groupComment} style={(typeof groupCommentError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}} />
                <label htmlFor='comment_input__' className='error_message_'>{groupCommentError}</label>
            </div>
            <div className='footer_row'>
            <div className='delete_button' style={props.status === 'edit' ? {display: 'flex'} : {display: 'none'}} onClick={deleteGroup} >Удалить</div>
                <div className='cancel' onClick={handleModal}>
                    Отменить
                </div>
                <div className='create' id='create_income' onClick={handleSubmit}>
                    {props.status === 'edit' ? 'Сохранить' : 'Создать'}
                </div>
            </div>
        </div>
        )
    }
    if (props?.type === 'service') {
        return (
            <div className='new_operation_container' id='category_create2'>
                        <Error errors={!success} message={errors} />
            <div className='row1'>{props.status === 'edit' ? `Редактирование клиента №${dataDetail?.id}` : 'Создание клиента'}
            <div className='creator_box' style={props?.status === 'edit' ? {display: 'flex'} : {display: 'none'}}>
                    <span>Создатель: {dataDetail?.creator?.name}</span>
                    <span>Создана: {day} {month} {year}</span>
                </div>
            </div>
            <div className='data_row modal_row'>
                Название
                <input type='text' id='name_input' onChange={handleName} value={categoryName} placeholder='Название клиента' style={(typeof nameError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}}/>
                <label htmlFor='name_input' className='error_message_'>{nameError}</label>
            </div>
        <div className='category_row modal_row' id='group_row_' style={{marginTop: '0vw'}}>
                Группа
                <select type='text' id='category_input_' className='category_select__' value={selectedCategory} placeholder='Выберите группу...' onChange={handleSelect} style={(typeof errors?.group_id?.[0] === 'string') ? {border: '0.1vw solid rgb(255, 0, 0'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15'}} >
                    {makeCategoryOptions}
                </select>
            </div>
        <div className='footer_row'>
        <div className='delete_button' style={props.status === 'edit' ? {display: 'flex'} : {display: 'none'}} onClick={deleteCategory} >Удалить</div>
            <div className='cancel' onClick={handleModal}>
                Отменить
            </div>
            <div className='create' id='create_income' onClick={handleSubmit}>
                {props.status === 'edit' ? 'Сохранить' : 'Создать'}
            </div>
        </div>
    </div>
        )
    }}
}

export default NewClient