import './ProjectCards.css'
import NavBar from '../components/NavBar.js';
import { useEffect, useState } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import ADD from '../images/add.png'
import GRID from '../images/grid.png'
import LIST from '../images/list.png'
import PERSON from '../images/person.png'
import { observer } from 'mobx-react-lite'
import { STORE } from '../STORE.js'
import Error from '../components/Error.js'

const ProjectCards = observer((props) => {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [activeTask, setActiveTask] = useState(true)
    const [activeStat, setActiveStat] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const [success, setSuccess] = useState(true)


    const changeActive = () => {
        setActiveTask(!activeTask)
        setActiveStat(!activeStat)
    }


    useEffect(() => {
        
        if (STORE.token === '' || STORE.token === undefined || STORE.token === null) {
            navigate('/login')
        }

        setLoading(true)
        const { id } = location.state
        fetch(`https://eternity-tracker.ru/api/project/${id}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': 'Token 2a3da43702f3978e5ad49311f2ec084b2ce958f6'
            }
        })
        .then((response) => response.json())
        .then(data => {
                            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            setData(data)
            setLoading(false)
        })
        if (!loading) {

        }
    }, [])

    if (loading) {
        return             <div className='loading_screen'>
        <div className='loading_box'>
            Eternity Digital
        </div>
    </div>
    } else {

    const roles = Array.from({length: data.data.employees.length}, (_, index) => {
        return <div className='employee_role' key={index} id={`id${index}`}>
            <div className='_role'>Авитолог</div>
            <div className='_role_name'>{data.data.employees[index].name}</div>
        </div>
    })
    const tasks = Array.from({length: data?.data?.tasks.length}, (_, index) => {
        
        let deadline = data.data.tasks[index].deadline

        const makeMonth = (date) => {
            if (date[0] === '0' && date[1] === '1') {
                return 'янв'
            } else if (date[5] === '0' && date[6] === '2') {
                return 'фев'
            } else if (date[5] === '0' && date[6] === '3') {
                return 'март'
            } else if (date[6] === '4') {
                return 'апр' 
            } else if (date[6] === '5') {
                return 'май' 
            } else if (date[6] === '6') {
                return 'июнь'
            } else if (date[6] === '7') {
                return 'июль'
            } else if (date[6] === '8') {
                return 'ивг'
            } else if (date[6] === '9') {
                return 'сент'
            } else if (date[5] === '1' && date[6] === '0') {
                return 'окт'
            } else if (date[5] === '1' && date[6] === '1') {
                return 'нояб'
            } else if (date[5] === '1' && date[6] === '2') {
                return 'дек'
            }
        }
    
        const makeDay = (date) => {
            let day
            if (date[8] === '0') {
                day = date[9]
            } else {
                day = date[8] + date[9]
            }
    
            return day
        }
    
        const makeHours = (date) => {
            let hours = date[11] + date[12] + date[13] + date[14] + date[15]
            return hours
        }

        let month = makeMonth(deadline)
        let day = makeDay(deadline)
        let hours = makeHours(deadline)



        let today = new Date()
        today = today.toISOString()
        let dif = new Date(deadline) - new Date(today)
        dif = parseFloat(dif)
        dif = dif / 3600 / 1000
        dif = dif - 3
        dif = dif.toFixed(2)
        dif = +dif 

        let difString
        if (dif < 1) {
            dif = dif * 60
            dif = dif.toFixed(0)
            if (dif < 0) {
                difString = ''
            } else {
                difString = dif.concat(' Минут')
            }
        } else if (dif >= 24) {
            let difHours = dif % 24
            dif = dif/24
            if (difHours % 24 === 0) {
                dif = dif.toFixed(0)
                difString = dif.concat(' Д.')
            } else {
                dif = dif.toFixed(0)
                difHours = difHours.toFixed(0)
                difString = dif.concat(' Д.',' ', difHours, 'Ч.')
            }
        } else if (dif >= 1) {
            dif = dif.toFixed(0)
            difString = dif.concat(' Ч.')
        } else if (dif < 0) {
            difString = ''
        }


        return <div className='task_card' key={index} id={`task_id${index}`}>
            <div className='top_task'>

                <div className='left_task'>
                    <img className='card_top_icon' src={PERSON} />
                    <div className='top_task_name'>{data?.data?.tasks[index].creator.name}</div>
                    <MediaQuery minWidth={370}> 
                    <div className={(data?.data?.tasks[index].status === 'Просрочено') ? 'circle_red' :
                     (data.data.tasks[index].status === 'Завершено') ? 'circle_green' :
                      null}></div>

                </MediaQuery>
                </div>
                <div className='right_task'>
                    <div className='deadline_date'>
                        До {hours} {day} {month}
                    </div>
                    <div className='deadline_left'>
                        {difString}
                    </div>
                </div>
            </div>
            <div className='middle_task'>
                <div className={(data?.data?.tasks[index].status === 'Завершена' ? 'task_name out' : 'task_name')}>{data?.data?.tasks[index].name}</div>  
            </div>
            <div className='bottom_task'>
                <div className='completed_task'><p className='completed_text'>Выполнено</p> <p className='completed_number'>{data?.data?.tasks[index]?.actions?.completed_count} из {data?.data?.tasks[index]?.actions?.all_count}</p></div>
                <div className={(data?.data?.tasks[index].status === 'Просрочено') ? 'task_expired' :
                     (data.data.tasks[index].status === 'Завершено') ? 'task_completed' :
                      'task_in_work'}>
                    <div className='circle_icon'></div>
                    <div className='_task_text'>{data?.data?.tasks[index].status}</div>
                </div>
            </div>
        </div>
    } )
    return (
        <div className='project_card_container'>
                                                                    <Error errors={!success}/>
            <NavBar />
            <div className='project_main_container'>
            <MediaQuery minWidth={370}>
            <div className='top_task_name_m'>{data?.data?.name}</div>
            <Link to='/'>
            <p className='back_button'> &lt; </p>
            </Link>
            </MediaQuery>
                <div className='project_bar'>
                <div className='left_bar'>
                <p>Проект</p>
                <div className='icon'></div>
                    <div className='project_name'>
                        {data?.data?.name}
                    </div>
                    </div>
                <div className='right_bar'>
                    <div className='manager'><p className='manager_title'>Ответственный</p><p className='manager_content'>{data?.data?.manager?.name}</p></div>
                    {roles}
                </div>
                </div>
                <div className='top_container'>
                    <div className='top_help'>
                    <div className='top_content_container'>
                        <div className={(activeTask) ? 'top_content_tasks_active' : 'top_content_tasks'} onClick={changeActive}>Задачи</div>
                        <div className={(activeStat) ? 'top_content_statistics_active' : 'top_content_statistics'} onClick={changeActive}>Статистика</div>
                    </div>
                    <div className='top_content_search'>
                    <div className='search_icon'></div>
                    <input type='text' className='top_content_search_input' placeholder='Поиск' />
                    </div>
                    </div>
                    <div className='bottom_content_container'>
                        <div className='create_task'>
                            <img src={ADD} className='create_task_icon' />
                            <div className='create_task_content'>Создать задачу</div>
                        </div>
                        <img className='grid' src={GRID} />
                        <img className='list' src={LIST} />
                    </div>
                </div>
                <div className='tasks_container'>
                    {tasks}
                </div>
            </div>
        </div>
    )

}
})

export default ProjectCards