import './NewOperation.css'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useState, useEffect } from 'react'
import ru from 'date-fns/locale/ru';
import { STORE } from '../STORE.js'
import Error from '../components/Error.js'

const MergeOperations = (props) => {
    
    const [startDate, setStartDate] = useState()
    const [dateError, setDateError] = useState(null)
    const [showToolTip, setShowToolTip] = useState(false)
    const [canConfirm, setCanConfirm] = useState(true)
    const [date, setDate] = useState()
    const [confirmed, setConfirm] = useState(false)
    const [categoryError, setCategoryError] = useState(null)
    const [categoryName, setCategoryName] = useState()
    const [dataSet, setDataSet] = useState()
    const [success, setSuccess] = useState(true)
    const [errors, setErrors] = useState()
    const [data, setData] = useState()
    const [categoryID, setCategoryID] = useState(null)

    
    useEffect(() => {
        const API = `https://eternity-tracker.ru/api/finances/transactions/unifications/create/?transactions=${props.operations.join(',')}`
        if (props.showMerge) {
        fetch(API, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            setErrors(data?.errors)
            setSuccess(data?.success)
                    setTimeout(() => {
                        setSuccess(true)
                    }, 10)
            if (data.success) {
            setData(data?.data)
            } else {
                console.log('error', data?.errors, '_________')
                props.callBackError(data?.errors?.[0])
                props.preventMerge()
            }

        })
        .catch(error => console.error(error))
    }
    }, [props.operations, props.showMerge])

    const API = 'https://eternity-tracker.ru/api/finances/transactions/create/'
    useEffect(() => {
        fetch(API, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setDataSet(data.data)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
        })
        .catch(error => console.error(error))
    }, [])

    useEffect(() => {
        setDate(startDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'))
        const today = new Date()
        if (startDate > today) {
            setConfirm(false)
            setShowToolTip(true)
            setCanConfirm(false)
        } else {
            setShowToolTip(false)
            setCanConfirm(true)
        }
    }, [startDate])

    const handleConfirm = () => {
        const today = new Date()
        if (startDate > today) {
            setConfirm(false)
        } else {
            setConfirm(!confirmed)
            setCanConfirm(true)
        }
    }

    const handleCategory = (event) => {

    }

    const handleMerge = () => {
        if (props.mergeType === 'create') {
    const API = 'https://eternity-tracker.ru/api/finances/transactions/unifications/create/'
    if (date) {
        setDateError(null)
        fetch(API, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                transactions: props.operations,
                date: date,
            })
        })
        .then(response => response.json())
        .then(data => {
            setSuccess(data?.success)
                    setTimeout(() => {
                        setSuccess(true)
                    }, 10)
            if (data.success) {
                sessionStorage.setItem('notification', data?.notification === undefined ? 'Платежи были успешно объединены' : data?.notification)
                sessionStorage.setItem('type', 'create_operation')
                props.update()
            }
            setErrors(data?.errors)
        })
        .catch(error => console.error(error))
    } else {
        if (!date) {
            setDateError('Поле не может быть пустым')
        } else {
            setDateError(null)
        }
    }

    } else if (props.mergeType === 'edit') {

    }

}

    useEffect(() => {
        console.log(props.showMerge, '__________')
        if (!props.showMerge) {
            setStartDate(null)
            setConfirm(false)
            setCategoryID(null)
            setCategoryName('—')
        }
    }, [props.showMerge])

    const makeCategoryOptions = Array.from({length: dataSet?.categories?.length}, (_, index) => {
        const makeCategoryOptionsInside = Array.from({length: dataSet?.categories[index]?.categories?.length}, (_, ind) => {
            return (
                <option id={`${dataSet?.categories[index]?.categories[ind]?.category_id}`} value={dataSet?.categories[index]?.categories[ind]?.category_name}>{dataSet?.categories[index]?.categories[ind]?.category_name === null ? '—' : dataSet?.categories[index]?.categories[ind]?.category_name}</option>
            )
        })  
        return (
            <optgroup label={dataSet?.categories[index]?.group_name}> {makeCategoryOptionsInside} </optgroup>
        )
    })

    const makeProducts = Array.from({length: data?.products?.length}, (_, index) => {
        return (
            <div className='project_input__' style={index === data?.products?.length - 1 ? {border: 'none', justifyContent: 'space-between', paddingLeft: '0'} : {justifyContent: 'space-between', paddingLeft: '0'}}>
                <span>{data?.products?.[index]?.name}</span> <span>{data?.products?.[index]?.price?.toLocaleString()}₽</span>
            </div>
        )
    })

    const handleCategorySelect = (event) => {
        console.log(event.currentTarget.options[event.currentTarget.selectedIndex])
        setCategoryID(event.currentTarget.options[event.currentTarget.selectedIndex].id === 'null' ? null : event.currentTarget.options[event.currentTarget.selectedIndex].id)
        setCategoryName(event.currentTarget.options[event.currentTarget.selectedIndex].name)
    }

    return (
        <>
            <div className='merge_container' style={{heigth: 'fit-content'}}>
                <div className='row1'>
                    {props.mergeType === 'create' ? 'Объединение платежей' : 'Объединенный платеж'}
                </div>
                <div className='data_row modal_row'>
                    <span style={{width: '4.1vw'}}>Дата</span>
                    <DatePicker disabled={props.mergeType === 'edit'} id='datePicker_merge' className={typeof dateError === 'string' ? 'data_pick_error_move' : 'data_pick_move'} selected={startDate} dateFormat='dd/MM/YYYY' onChange={(date) => setStartDate(date)} placeholderText={'Укажите дату'} locale={ru} minDate={new Date(data?.date_range_start)} maxDate={new Date(data?.date_range_end)} />
                    <label htmlFor='datePicker_merge' className='error_message_'>{dateError}</label>
                </div>
                <div className='modal_row'> Сумма
                <div className='project_input__'>
                    {data?.cost?.toLocaleString()}₽
                </div>
                </div>
                <div className='modal_row'> <span style={{width: '3.1vw'}}>Клиент</span>
                <div className='project_input__'>
                    {data?.customer?.name}
                </div>
                </div>
                <div className='modal_row' id='merge_products'> <span style={{width: '3.1vw', marginTop: '-2vw'}}>Услуги</span>
                    <div className='merge_products'>
                        {makeProducts}
                    </div>
                </div>
                <div className='footer_row'>
                    <div className='cancel' onClick={props.closeMerge}>Отмена</div>
                    <div className='create' style={{paddingLeft: '0.5vw', paddingRight: '0.5vw'}} onClick={handleMerge}>Объединить</div>
                </div>
            </div>
        </>
    )
}

export default MergeOperations