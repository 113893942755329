import {React, useState, useEffect } from 'react'
import NavBar from '../components/NavBar.js'
import './inBox.css'
import Cards from '../components/Cards.js'
import { observer } from 'mobx-react-lite'
import { STORE } from '../STORE.js'
import { useNavigate } from 'react-router-dom'
import Error from '../components/Error.js'


const InBox = () => {

const navigate = useNavigate()
const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
const API = 'https://eternity-tracker.ru/api/tracker/projects/'
const [count, setCount] = useState(STORE.InBoxLoader)

    const [obj, setObj] = useState()
    const [loading, setLoading] = useState()
    const [success, setSuccess] = useState(true)

    useEffect(() => {
        console.log('TRACKER PAGE LOADING')
        setTimeout(() => {
            STORE.setInBoxLoader()
            setCount(STORE.InBoxLoader)
        }, 1500)
        console.log(STORE.readToken(), '- this token  in INBOX')
        if (STORE.token === '' || STORE.token === undefined || STORE.token === null) {
            navigate('/login')
        } else {
            setLoading(true)
            fetch(API, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`
                }
            })
            .then(response => response.json())
            .then(data => {
                                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                setObj(data)
                setLoading(false)
                if (data.detail === 'Invalid token') {
                    navigate('/login')
                }
            })
            .catch(error => console.error(error))
            const handleResize = () => setIsMobile(window.innerWidth < 768)
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        }

        
    }, [])

        if (!STORE.readToken()) {
            navigate('/login')
        }

    if (obj?.data === undefined) {
        if (STORE.readToken() === '' || STORE.readToken() === undefined || STORE.readToken() === null) {
            navigate('/login')
        }
    } else {

    return (
        <>
                    <Error errors={!success}/>
        <div style={(count > 0) ? { display: 'none' } : { display: 'flex' }}>
        <body style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
  <circle
    class="active"
    pathLength="360"
    fill="transparent"
    stroke-width="32"
    cx="192"
    cy="192"
    r="176"
  ></circle>
  <circle
    class="track"
    pathLength="360"
    fill="transparent"
    stroke-width="32"
    cx="192"
    cy="192"
    r="176"
  ></circle>
</svg>
</body>
        </div><div className='container'>
                <NavBar />
                <div className='main_container'>
                    <div className='head'>
                        <div className='logo'>
                            Проекты
                        </div>
                        <div className='search'>
                            <div className='search_icon'></div>
                            <input type='text' className='search_input' placeholder='Поиск' />
                        </div>
                    </div>
                    <Cards number={obj} loading={loading} />
                </div>
            </div></>
    )
}
}

export default InBox