import { useState, useEffect } from 'react'
import { STORE } from '../STORE.js'
import DatePicker from "react-datepicker"
import ru from 'date-fns/locale/ru';
import './NewOperation.css'
import CurrencyInput from 'react-currency-input-field'
import Error from '../components/Error.js'

const AddService = (props) => {

    const [services, setServices] = useState()
    const [success, setSuccess] = useState(true)
    const [startDate, setStartDate] = useState()
    const [secondDate, setSecondDate] = useState()
    const [fullSum, setFullSum] = useState()
    const [firstDate, setFirstDate] = useState()
    const [dataSet, setDataSet] = useState()
    const [serviceName, setServiceName] = useState()
    const [serviceID, setServiceID] = useState()
    const [plan, setPlan] = useState('Нет')
    const [addTracker, setAddTracker] = useState(false)
    const [showSplit, setShowSplit] = useState(false)
    const [sum1, setSum1] = useState()
    const [sum2, setSum2] = useState()
    const [errors, setErrors] = useState()

    const [detailData, setDetailData] = useState()

    const API_DETAIL = `https://eternity-tracker.ru/api/finances/directory/customers/detail/product/detail/${props?.productID}`

    useEffect(() => {
        if (props.productID !== undefined && props.status === 'edit') {
        fetch(API_DETAIL, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setServices(data?.data?.groups)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            if (data.success) {
                if (data?.data?.paydates?.length === 1) {
                setShowSplit(false)
                setProductName(data?.data?.name)
                setServiceID(data?.data?.product?.id)
                setServiceName(data?.data?.product?.name)
                setFullSum(data?.data?.total_price)
                setStartDate(new Date(data?.data?.work_start_date))
                setFirstDate(new Date(data?.data?.paydates?.[0]?.date))
                setSum1(data?.data?.paydates?.[0]?.amount)
                setPlan(data?.data?.planning === 0 ? 'Нет' : data?.data?.planning + ' мес. вперед')
                } else {
                    setServiceID(data?.data?.product?.id)
                    setServiceName(data?.data?.product?.name)
                    setFullSum(data?.data?.total_price)
                    setProductName(data?.data?.name)
                    setStartDate(new Date(data?.data?.work_start_date))
                    setFirstDate(new Date(data?.data?.paydates?.[0]?.date))
                    setSum1(data?.data?.paydates?.[0]?.amount)
                    setSecondDate(new Date(data?.data?.paydates?.[1]?.date))
                    setSum2(data?.data?.paydates?.[1]?.amount)
                    setPlan(data?.data?.planning === 0 ? 'Нет' : data?.data?.planning + ' мес. вперед')
                    setShowSplit(true)
                }
            }
        })
        .catch(error => console.error(error))}
    }, [props.productID, props.status])

    const API_SERVICES = 'https://eternity-tracker.ru/api/finances/directory/customers/detail/product/add/'
    useEffect(() => {
        fetch(API_SERVICES, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setServices(data?.data?.groups)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            setDataSet(data?.data)
            setServiceID(data?.data?.[0]?.products?.[0]?.product_id)
            setServiceName(data?.data?.[0]?.products?.[0]?.product_name)
            setFullSum(data?.data?.[0]?.products?.[0]?.product_price)
        })
        .catch(error => console.error(error))
    }, [])

    useEffect(() => {
        if (firstDate === undefined) {
            setFirstDate(startDate)
        }
    }, [startDate])

    useEffect(() => {
        if (!props.showCreate) {
            setStartDate()
            setFirstDate()
            setFullSum()
            setServiceName(dataSet?.[0]?.products?.[0]?.product_name)
            setServiceID(dataSet?.[0]?.products?.[0]?.product_id)
            setFullSum(dataSet?.[0]?.products?.[0]?.product_price)
            setPlan('Нет')
            setAddTracker(false)
            setSum1()
            setShowSplit()
            setSum2()
            setSecondDate()
            setStartDateError(null)
            setFullSumError(null)
            setFirstDateError(null)
            setSecondDateError(null)
            setSum1Error(null)
            setSum2Error(null)
            setProductName('')
        }
    }, [props.showCreate])

    const handlePlan = (event) => {
        setPlan(event.currentTarget.options[event.currentTarget.selectedIndex].innerHTML)
    }

    const handleSum = (event) => {
        let sum = event.target.value.split('')
        sum = sum.join('')
        sum = sum.replace(/\s/g, '')
        sum = sum.replace(/[^\d.,-]/g, '')
        setFullSum(sum === '' ? null : sum)
    }

    const handleSum1 = (event) => {
        let sum = event.target.value.split('')
        sum = sum.join('')
        sum = sum.replace(/\s/g, '')
        sum = sum.replace(/[^\d.,-]/g, '')
        setSum1(sum === '' ? null : sum)
    }

    const handleSum2 = (event) => {
        let sum = event.target.value.split('')
        sum = sum.join('')
        sum = sum.replace(/\s/g, '')
        sum = sum.replace(/[^\d.,-]/g, '')
        setSum2(sum === '' ? null : sum)
    }

    const handleSelect = (event) => {
        setServiceName(event.currentTarget.options[event.currentTarget.selectedIndex].innerHTML)
        setServiceID(event.currentTarget.options[event.currentTarget.selectedIndex].id.split('_')[0])
        const groupID = (dataSet?.find(item => item.group_id === +event.currentTarget.options[event.currentTarget.selectedIndex].id.split('_')[1])).products
        setFullSum(groupID?.find(item => item.product_id === +event.currentTarget.options[event.currentTarget.selectedIndex].id.split('_')[0]).product_price)
    }

    const handleTracker = () => {
        setAddTracker(!addTracker)
    }

    useEffect(() => {
        if (!showSplit) {
            setSum2()
            setSecondDate()
            setSum1(+fullSum)
        } else {
            setSum1(+fullSum / 2)
            setSum2(+fullSum / 2)
        }
        setStartDateError(null)
        setFullSumError(null)
        setFirstDateError(null)
        setSecondDateError(null)
        setSum1Error(null)
        setSum2Error(null)

    }, [showSplit])

    const makeServices = Array.from({length: dataSet?.length}, (_, index) => {
        const makeServicesInside = Array.from({length: dataSet?.[index]?.products?.length}, (_, ind) => {
            return (
                <option id={`${dataSet?.[index]?.products[ind]?.product_id}_${dataSet?.[index]?.group_id}`} value={dataSet?.[index]?.products[ind]?.product_name}>{dataSet?.[index]?.products[ind]?.product_name}</option>
            )
        })
        return (
            <optgroup label={dataSet?.[index]?.group_name}> {makeServicesInside} </optgroup>
        )

    })

    const [fullSumError, setFullSumError] = useState(null)
    const [firstDateError, setFirstDateError] = useState(null)
    const [sum1Error, setSum1Error] = useState(null)
    const [startDateError, setStartDateError] = useState(null)
    const [secondDateError, setSecondDateError] = useState(null)
    const [sum2Error, setSum2Error] = useState(null)
    const [nameError, setNameError] = useState(null)



    useEffect(() => {
        if (props.status === 'edit') {

        }
    }, [props.status])

    const handleNew = () => {

        const API = 'https://eternity-tracker.ru/api/finances/directory/customers/detail/product/add/'
        const APIedit = 'https://eternity-tracker.ru/api/finances/directory/customers/detail/product/edit/'
    if (props.status === 'create') {
        if (!showSplit) {
            if (fullSum !== undefined && fullSum !== '' && firstDate !== undefined && sum1 !== undefined && sum1 !== '' && startDate !== undefined && +sum1 === +fullSum && productName) {
                console.log(startDate)
                console.log(startDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'))
                fetch(API, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Token ${STORE.readToken()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        product: serviceID,
                        name: productName,
                        customer: props?.id,
                        total_price: fullSum,
                        work_start_date: startDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'),
                        planning: plan === 'Нет' ? '0' : plan.split(' ')[0],
                        create_project: addTracker,
                        paydates: [
                            {
                                date: firstDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'),
                                amount: Number(sum1),
                            },
                        ]
                    })
                })
                .then(response => response.json())
                .then(data => {
                    setSuccess(data?.success)
                            setTimeout(() => {
                                setSuccess(true)
                            }, 10)
                    if (data.success) {
                        sessionStorage.setItem('notification', data?.notification === undefined ? 'Услуга была успешно добавлена' : data?.notification)
                        sessionStorage.setItem('type', 'create_operation')
                    }
                    props?.update(true)
                    setErrors(data?.errors)
                })
                .catch(error => console.error(error))
            } else {
                console.log(sum1, fullSum, sum1 === fullSum)
                if (fullSum === undefined || fullSum === '') {
                    setFullSumError('Поле не может быть пустым')
                } else {
                    setFullSumError(null)
                }
                if (startDate === undefined) {
                    setStartDateError('Поле не может быть пустым')
                } else {
                    setStartDateError(null)
                }
                if (firstDate === undefined) {
                    setFirstDateError('Поле не может быть пустым')
                } else {
                    setFirstDateError(null)
                }
                if (sum1 === undefined || sum1 === '') {
                    setSum1Error('Поле не может быть пустым')
                } else {
                    setSum1Error(null)
                }
                if (+sum1 !== +fullSum) {
                    setSum1Error('Сумма платежей должна совпадать с общей стоимостью')
                } else {
                    setSum1Error(null)
                }
                if (!productName) {
                    setNameError('Поле не может быть пустым')
                } else {
                    setNameError(null)
                }
            }

        } else if (showSplit) {
            if (fullSum !== undefined && fullSum !== '' && firstDate !== undefined && sum1 !== undefined && sum1 !== '' && startDate !== undefined && secondDate !== undefined && sum2 !== undefined && sum2 !== '' && +sum1 + +sum2 === +fullSum && productName) {
                fetch(API, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Token ${STORE.readToken()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        product: serviceID,
                        customer: props?.id,
                        name: productName,
                        total_price: fullSum,
                        work_start_date: startDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'),
                        planning: plan === 'Нет' ? '0' : plan.split(' ')[0],
                        create_project: addTracker,
                        paydates: [
                            {
                                date: firstDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'),
                                amount: Number(sum1),
                            },
                            {
                                date: secondDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'),
                                amount: Number(sum2),
                            }
                        ]
                    })
                })
                .then(response => response.json())
                .then(data => {
                    setSuccess(data?.success)
                            setTimeout(() => {
                                setSuccess(true)
                            }, 10)
                    setSuccess(data?.success)
                    if (data.success) {
                        sessionStorage.setItem('notification', data?.notification === undefined ? 'Услуга была успешно добавлена' : data?.notification)
                        sessionStorage.setItem('type', 'create_operation')
                    }
                    props?.update(true)
                    setErrors(data?.errors)
                })
                .catch(error => console.error(error))
            } else {
                if (fullSum === undefined || fullSum === '') {
                    setFullSumError('Поле не может быть пустым')
                } else {
                    setFullSumError(null)
                }
                if (startDate === undefined) {
                    setStartDateError('Поле не может быть пустым')
                } else {
                    setStartDateError(null)
                }
                if (firstDate === undefined) {
                    setFirstDateError('Поле не может быть пустым')
                } else {
                    setFirstDateError(null)
                }
                if (sum1 === undefined || sum1 === '') {
                    setSum1Error('Поле не может быть пустым')
                } else {
                    setSum1Error(null)
                }
                if (sum2 === undefined || sum2 === '') {
                    setSum2Error('Поле не может быть пустым')
                } else {
                    setSum2Error(null)
                }
                if (secondDate === undefined) {
                    setSecondDateError('Поле не может быть пустым')
                } else {
                    setSecondDateError(null)
                }
                if (+sum1 + +sum2 !== +fullSum && sum1 !== undefined && sum1 !== '' && sum2 !== undefined && sum2 !== '') {
                    setSum1Error('Сумма платежей должна совпадать с общей стоимостью')
                    setSum2Error('Сумма платежей должна совпадать с общей стоимостью')
                } else if (sum1 === undefined || sum1 === '') {
                    setSum1Error('Поле не может быть пустым')
                } else if (sum2 === undefined || sum2 === '') {
                    setSum2Error('Поле не может быть пустым')
                }
                if (!productName) {
                    setNameError('Поле не может быть пустым')
                } else {
                    setNameError(null)
                }
            }}
        } else if (props.status === 'edit') {
            if (!showSplit) {
                if (fullSum !== undefined && fullSum !== '' && firstDate !== undefined && sum1 !== undefined && sum1 !== '' && startDate !== undefined && productName) {
                    fetch(APIedit, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Authorization': `Token ${STORE.readToken()}`,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            customer_product: props.productID,
                            total_price: fullSum,
                            name: productName,
                            work_start_date: startDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'),
                            planning: plan === 'Нет' ? '0' : plan.split(' ')[0],
                            create_project: addTracker,
                            paydates: [
                                {
                                    date: firstDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'),
                                    amount: Number(sum1),
                                },
                            ]
                        })
                    })
                    .then(response => response.json())
                    .then(data => {
                        setSuccess(data?.success)
                                setTimeout(() => {
                                    setSuccess(true)
                                }, 10)
                        if (data.success) {
                            sessionStorage.setItem('notification', data?.notification === undefined ? 'Услуга была успешно изменена' : data?.notification)
                            sessionStorage.setItem('type', 'create_operation')
                            props?.update(true)
                        }
                        setErrors(data?.errors)
                    })
                    .catch(error => console.error(error))
                } else {
                    if (fullSum === undefined || fullSum === '') {
                        setFullSumError('Поле не может быть пустым')
                    } else {
                        setFullSumError(null)
                    }
                    if (startDate === undefined) {
                        setStartDateError('Поле не может быть пустым')
                    } else {
                        setStartDateError(null)
                    }
                    if (firstDate === undefined) {
                        setFirstDateError('Поле не может быть пустым')
                    } else {
                        setFirstDateError(null)
                    }
                    if (sum1 === undefined || sum1 === '') {
                        setSum1Error('Поле не может быть пустым')
                    } else {
                        setSum1Error(null)
                    }
                    if (!productName) {
                        setNameError('Поле не может быть пустым')
                    } else {
                        setNameError(null)
                    }
                }
    
            } else if (showSplit) {
                if (fullSum !== undefined && fullSum !== '' && firstDate !== undefined && sum1 !== undefined && sum1 !== '' && startDate !== undefined && secondDate !== undefined && sum2 !== undefined && sum2 !== '' && productName) {
                    fetch(APIedit, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Authorization': `Token ${STORE.readToken()}`,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            customer_product: props.productID,
                            total_price: fullSum,
                            name: productName,
                            work_start_date: startDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'),
                            planning: plan === 'Нет' ? '0' : plan.split(' ')[0],
                            create_project: addTracker,
                            paydates: [
                                {
                                    date: firstDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'),
                                    amount: Number(sum1),
                                },
                                {
                                    date: secondDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'),
                                    amount: Number(sum2),
                                }
                            ]
                        })
                    })
                    .then(response => response.json())
                    .then(data => {
                        setSuccess(data?.success)
                                setTimeout(() => {
                                    setSuccess(true)
                                }, 10)
                        if (data.success) {
                            sessionStorage.setItem('notification', data?.notification === undefined ? 'Услуга была успешно изменена' : data?.notification)
                            sessionStorage.setItem('type', 'create_operation')
                            props?.update(true)
                        }
                        setErrors(data?.errors)
                    })
                    .catch(error => console.error(error))
                } else {
                    if (fullSum === undefined || fullSum === '') {
                        setFullSumError('Поле не может быть пустым')
                    } else {
                        setFullSumError(null)
                    }
                    if (startDate === undefined) {
                        setStartDateError('Поле не может быть пустым')
                    } else {
                        setStartDateError(null)
                    }
                    if (firstDate === undefined) {
                        setFirstDateError('Поле не может быть пустым')
                    } else {
                        setFirstDateError(null)
                    }
                    if (sum1 === undefined || sum1 === '') {
                        setSum1Error('Поле не может быть пустым')
                    } else {
                        setSum1Error(null)
                    }
                    if (sum2 === undefined || sum2 === '') {
                        setSum2Error('Поле не может быть пустым')
                    } else {
                        setSum2Error(null)
                    }
                    if (secondDate === undefined) {
                        setSecondDateError('Поле не может быть пустым')
                    } else {
                        setSecondDateError(null)
                    }
                    if (!productName) {
                        setNameError('Поле не может быть пустым')
                    } else {
                        setNameError(null)
                    }
                }}
        }
    }
    const API_DELETE = 'https://eternity-tracker.ru/api/finances/directory/customers/detail/product/delete/'
    const deleteProduct = () => {
        fetch(API_DELETE, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customer_product: props.productID
            })
        })
        .then(response => response.json())
        .then(data => {
            props.update(true)
            setSuccess(data?.success)
                    setTimeout(() => {
                        setSuccess(true)
                    }, 10)
            setErrors(data?.errors)
        })
        .catch(error => console.error(error))
    }

    const handleModal = () => {
        props.update(true)
    }

    const [productName, setProductName] = useState()

    const handleProductName = (event) => {
        setProductName(event.target.value)
    }

    useEffect(() => {
        if (props.status === 'create') {
            setProductName(serviceName)
        }
    }, [serviceName])

    useEffect(() => {
        if (showSplit) {
            setSum1(+fullSum / 2)
            setSum2(+fullSum / 2)
        } else {
            setSum1(+fullSum)
        }
    }, [fullSum])

    return (
        <>
                    <Error errors={!success} message={errors} />
            <div className='row1'>{props.status === 'create' ? 'Добавление услуги' : 'Редактирование услуги'}</div>
            <div className='data_row modal_row'> Услуга
                <select disabled={props.status === 'edit'} style={props.status === 'edit' ? {cursor: 'not-allowed', marginLeft: 'auto'} : {cursor: 'default', marginLeft: 'auto'}} className='category_select_' value={serviceName} onChange={handleSelect} id='category_input'>
                    {makeServices}
                </select>
            </div>
            <div className='modal_row'><span style={{width: '7.4vw'}}>Название</span>
                <input type='text' value={productName} onChange={handleProductName} style={(typeof nameError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}} className='category_select_' id='category_input'></input>
                <label htmlFor='category_input' className='error_message_'>{nameError}</label>
            </div>
            <div className='modal_row'> <span style={{width: '8.7vw'}}>Дата начала работ</span>
            <DatePicker  id='date_picker2' dateFormat='dd/MM/YYYY' className={typeof startDateError === 'string' ? 'data_pick_error' : 'data_pick'} selected={startDate} onChange={(date) => setStartDate(date)} placeholderText={'Укажите дату'} locale={ru} style={(typeof startDateError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vmax solid rgb(255, 255, 255, 0.15)'}}/>
            <label htmlFor='date_picker2' className='error_message_'>{startDateError}</label>
            </div>
            <div className='modal_row'> <span style={{width: '8.4vw'}}>Общая стоимость</span>
            <CurrencyInput  type='text' prefix='₽'  id='balance_input_' value={fullSum} placeholder='0' onChange={handleSum} style={(typeof fullSumError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}} />
            <label htmlFor='balance_input_' className='error_message_'>{fullSumError}</label>
            </div>
            <div className='modal_row data_row' style={firstDateError ? {width: '95%'} : {}}> <span style={{width: '8.7vw'}}>Дата первого платежа</span>
            <DatePicker  id='date_picker2' dateFormat='dd/MM/YYYY' className={typeof firstDateError === 'string' ? 'data_pick_error' : 'data_pick'} selected={firstDate} onChange={(date) => setFirstDate(date)} placeholderText={'Укажите дату'} locale={ru}/>
            <label htmlFor='date_picker2' style={firstDateError ? {width: '7vw'} : {}} className='error_message_'>{firstDateError}</label>
            <div className='split' style={props.status === 'edit' ? {display: 'none'} : showSplit ? {display: 'none'} : firstDateError ? {display: 'block', width: '5vw'} : {display: 'block'}} onClick={() => {setShowSplit(true)}}>Разбить платежи</div>
            </div>
            <div className='modal_row'><span style={{width: '8.4vw'}}>Сумма</span>
            <CurrencyInput  type='text' prefix='₽'  id='balance_input_' value={sum1} placeholder='0' onChange={handleSum1} style={(typeof sum1Error === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}} />
            <label htmlFor='balance_input_' className='error_message_' style={{width: '7vw'}}>{sum1Error}</label>
            </div>
            <div className='modal_row' style={!showSplit ? {display: 'flex'} : {display: 'none'}}>
                Планирование
                <select className='category_select_' style={{marginLeft: 'auto'}} value={plan} onChange={handlePlan} id='category_input'>
                    <option value='Нет'>Нет</option>
                    <option value='1 мес. вперед'>1 мес. вперед</option>
                    <option value='2 мес. вперед'>2 мес. вперед</option>
                    <option value='3 мес. вперед'>3 мес. вперед</option>
                </select>
            </div>
            <div className='data_row modal_row' style={showSplit ? {display: 'flex'} : {display: 'none'}}> <span style={{width: '8.8vw'}}> Дата второго платежа </span>
            <DatePicker  id='date_picker2' dateFormat='dd/MM/YYYY' className={typeof secondDateError === 'string' ? 'data_pick_error' : 'data_pick'} selected={secondDate} onChange={(date) => setSecondDate(date)} placeholderText={'Укажите дату'} locale={ru} style={(typeof secondDateError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vmax solid rgb(255, 255, 255, 0.15)'}}/>
            <div className='split' style={props.status === 'edit' ? {display: 'none'} : {display: 'flex'}} onClick={() => {setShowSplit(false)}}>Отменить разбиение</div>
            </div>
            <div className='modal_row' style={showSplit ? {display: 'flex'} : {display: 'none'}}><span style={{width: '8.4vw'}}>Сумма</span>
            <CurrencyInput  type='text' prefix='₽'  id='balance_input_' value={sum2} placeholder='0' onChange={handleSum2} style={(typeof sum2Error === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15)'}} />
            <label htmlFor='balance_input_' className='error_message_' style={{width: '7vw'}}>{sum2Error}</label>
            </div>
            <div className='modal_row' style={showSplit ? {display: 'flex'} : {display: 'none'}}>
                Планирование
                <select className='category_select_' style={{marginLeft: 'auto'}} value={plan} onChange={handlePlan} id='category_input'>
                    <option value='Нет'>Нет</option>
                    <option value='1 мес. вперед'>1 мес. вперед</option>
                    <option value='2 мес. вперед'>2 мес. вперед</option>
                    <option value='3 мес. вперед'>3 мес. вперед</option>
                </select>
            </div>
            <div className='footer_row'>
            <div class="checkbox-wrapper-46" id='checkbox11_' style={props.status === 'create' ? {display: 'flex'} : {display: 'none'}} onClick={handleTracker}>
                            <input type="checkbox" id="cbx-41" class="inp-cbx" checked={addTracker}/>
                            <label for="cbx-41" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1' id='label_10'>
                                    Создать проект в трекере
                                </span>
                            </label>
                </div>
                <div className='delete_button' style={props.status === 'edit' ? {display: 'flex'} : {display: 'none'}} onClick={deleteProduct}>Удалить</div>
                <div className='cancel' onClick={handleModal} >Отмена</div>
                <div className='create' onClick={handleNew}>{props.status === 'create' ? 'Создать' : 'Сохранить'}</div>
            </div>
        </>
    )

}

export default AddService