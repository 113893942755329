import './Cards.css'
import { Link } from 'react-router-dom'
import { makeDay, makeHours, makeMonth } from '../Logic/DateFormating.js'
import PERSON from '../images/person.png'

const Cards = (props) => {
 

    let today = new Date()
    today = today.toISOString()

    

    const threeDivs = Array.from({length: props.number.data.length}, (_, index) => {
        const employes = Array.from({length: props.number.data[index].employees.length}, (_, ind) => {
            return <div className='employe_card' key={ind} id={`empId${ind}`}>{props.number.data[index].employees[ind]}</div>
        })
        let deadlineDate = []
        if (props.number.data[index].nearby_task_deadline === null) {
            deadlineDate = '...'
        } else {
            for (let i = 5; i < 10; i++) {
                deadlineDate.push(props.number.data[index].nearby_task_deadline[i])
            }
    
            for (let i = 10; i < 11; i++) {
                deadlineDate.push(' ')
            }
    
            for (let i = 11; i < 16; i++) {
                deadlineDate.push(props.number.data[index].nearby_task_deadline[i])
            }
        }



        let deadline = props.number.data[index].nearby_task_deadline
        let month = makeMonth(deadlineDate)
        let day = makeDay(deadlineDate)
        let hours = makeHours(deadlineDate)
      
        let dif = new Date(deadline) - new Date(today)
        dif = parseFloat(dif)
        dif = dif / 3600 / 1000
        dif = dif - 3
        dif = dif.toFixed(2)
        dif = +dif 
        
        let difString
        if (dif < 1) {
            dif = dif * 60
            dif = dif.toFixed(0)
            if (dif < 0) {
                difString = 'Время истекло'
            } else {
                difString = dif.concat(' Минут')
            }
        } else if (dif >= 24) {
            let difHours = dif % 24
            dif = dif/24
            if (difHours % 24 === 0) {
                dif = dif.toFixed(0)
                difString = dif.concat(' Д.')
            } else {
                dif = dif.toFixed(0)
                difHours = difHours.toFixed(0)
                difString = dif.concat(' Д.',' ', difHours, 'Ч.')
            }
        } else if (dif >= 1) {
            dif = dif.toFixed(0)
            difString = dif.concat(' Ч.')
        } else if (dif < 0) {
            difString = 'Время истекло'
        }
        return (
            <Link to='/project' state={{ id: props.number.data[index].id}} style={{ textDecoration: 'none' }} >
        <div className='card' key={props.number.data[index].id}>
            <div className='card_top'>
                <div className='left_section'>
                <img className='card_top_icon' src={PERSON} />
                <div className='card_top_content'>
                    <p className='card_top_content_name'>{props.number.data[index].project_manager.name}</p>
                    <p className='card_top_content_description'>{props.number.data[index].project_manager.role}</p>
                    </div>
                </div>
                <div className='right_section'>
                        <div className='deadline_container'>
                            <div className='deadline_time'>До {hours} {day} {month}</div>
                            <div className='deadline_time_out'>Осталось {difString}</div>
                        </div>
                    </div>
            </div>
            <div className='card_main'>
                <div className='card_main_top'>
                    <div className='circle'></div>
                    <div className='card_main_top_content'>{props.number.data[index].name}</div>
                </div>
                <div className='tasks'>
                    <div className='tasks_all'>
                        <div className='tasks_all_icon'></div>
                        <p>&nbsp;Всего задач&nbsp;&nbsp;</p> <p className='task_number'>{props.number.data[index].all_tasks}</p></div>
                    <div className='tasks_get'>
                        <div className='tasks_get_icon'></div>
                        <p>&nbsp;Выполнено&nbsp;&nbsp;</p> <p className='task_number'>{props.number.data[index].completed_tasks}</p> </div>
                    <div className='tasks_left'>
                        <div className='tasks_left_icon'></div>
                        <p>&nbsp;Осталось</p>&nbsp;&nbsp; <p className='task_number'>{props.number.data[index].all_tasks - props.number.data[index].completed_tasks}</p></div>
                </div>
            </div>
            <div className='card_footer'>
                {employes}
            </div>
        </div>
        </Link>
    )
    })

    return <div className='main_container_cards'>
        {threeDivs}
    </div>
    
}

export default Cards