import './App.css';
import InBox from './pages/InBox.js'
import ProjectCards from './pages/ProjectCards.js'
import { Routes, Route, HashRouter } from 'react-router-dom'
import Registration from './pages/Registration.js'
import Login from './pages/Login.js'
import Finances from './pages/Finances.js'
import Navigate from './components/Navigate.js'
import HelperClients from './pages/HelperClients.js'
import HelperScores from './pages/HelperScores.js';
import HelperServices from './pages/HelperServices.js'
import HelperOutlays from './pages/HelperOutlays.js'
import HelperEmployes from './pages/HelperEmployes.js'
import DetailClient from './pages/DetailClient.js'
import PlanFactPage from './pages/plan-fact'

function App() {
  return (
  <HashRouter>
    <Routes>
      <Route path='/' element={<Navigate />} />
      <Route path='/tracker' element={<InBox />} />
      <Route path='/project' element={<ProjectCards />} />
      <Route path='/registration' element={<Registration />} />
      <Route path='/login' element={<Login />} />
      <Route path='/finances' element={<Finances />} />
      <Route path='/directory/clients' element={<HelperClients />} />
      <Route path='/directory/debets' element={<HelperScores />} />
      <Route path='/directory/services' element={<HelperServices />} />
      <Route path='/directory/categories' element={<HelperOutlays />} />
      <Route path='/directory/employes' element={<HelperEmployes />} />
      <Route path='/directory/clients/detail' element={<DetailClient />} />
      <Route path='/planfact' element={<PlanFactPage />} />
    </Routes>
  </HashRouter>
  );
}

export default App;
