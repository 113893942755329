import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { planFactStore } from "../stores/plan-fact";
import { RecursiveRow } from "./RecursiveRow";
import { getPlanFact } from "../api";

export const PlanFactChart = observer(() => {
  useEffect(() => {
    const fetchData = async () => {
      planFactStore.setLoading(true);
      try {
        const { data } = await getPlanFact();
        planFactStore.setData(data);
      } catch (error) {
        console.error(error);
      } finally {
        planFactStore.setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (planFactStore.isLoading) {
    return (
      <div style={{ textAlign: "center", color: "white" }}>Loading...</div>
    );
  }

  return (
    <div className="plan-fact-chart" tabIndex="0">
      <table>
        <thead>
          <tr>
            <th>Категории</th>
            {planFactStore.uniqueDatesWithTypes.map(({ date, type }, index) => (
              <th key={index}>
                <div>{date}</div>
                <div>{type === "plan" ? "план" : "факт"}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {planFactStore.rowsData.map((row, index) => (
            <RecursiveRow
              key={index}
              dates={planFactStore.uniqueDates}
              title={row.title}
              summaryData={row.summaryData}
              detailedData={row.detailedData}
              leafNodes={row.leafNodes}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
});
