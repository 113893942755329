import './Login.css'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { STORE } from '../STORE.js'
import InputMask from 'react-input-mask'
import Error from '../components/Error.js'

const Login = observer(() => {
    const [success, setSuccess] = useState(true)
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false)
    const [errors, setErrors] = useState()
    const [stringError, setStringError] = useState(null)
    const [formData, setFormData] = useState({
        phone: '',
        password: '',
    })

    const correctPhone = (phone) => {
        phone = phone.split('')
        let newPhone = phone.map(item => +item)
        newPhone = newPhone.filter(item => !isNaN(item))
        newPhone.shift()
        newPhone.unshift(8)
        newPhone = newPhone.join('')
        return newPhone
    }



    const handleCheck = (event) => {
        setChecked(event.target.checked)
    }

    const handleChange = (event) => {
        if (event.target.id === 'phone') {
            formData.phone = event.target.value
        } else if (event.target.id === 'password') {
            formData.password = event.target.value
        }
    }

    const handleSubmit = () => {
        formData.phone = correctPhone(formData.phone)
        if (formData.phone === '8' && formData.password === '') {
            setErrors({
                phone: 'Не корректный номер телефона',
                password: 'Это поле не может быть пустым'
            })
        } else if (formData.phone === '8') {
            setErrors({
                phone: 'Это поле не может быть пустым'
            })
        } else if (formData.password === '') {
            setErrors({
                password: 'Это поле не может быть пустым'
            })
        } else { 
        fetch('https://eternity-tracker.ru/api/auth/login/', {
            method: 'POST',
            body: JSON.stringify({
                phone: formData.phone,
                password: formData.password,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
        })
        .then(response => response.json())
        .then(data => {

                            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)

            if (typeof data.errors === 'string') {
                setStringError(null)
                setErrors({
                    phone: ' ',
                    password: 'Логин или пароль неверный'
                })
            } else if (typeof data.errors === 'object' && data.errors !== null) {
                setErrors(data.errors)
                setStringError(null)
            } else {
                if (checked) {
                    STORE.writeToken(data?.data?.token)
                    navigate('/tracker')
                } else {
                    STORE.writeTokenTimely(data?.data?.token)
                    navigate('/tracker')
                }
            }

        })} 
    }


    return (
        <div className='login_container'>
                                                                    <Error errors={!success} message={errors}/>
            <div className='left_part'>
                <div className='login_container_left'>
                    <div className='login_title'>Войти</div>
                    <div className='link_to_registration'>
                        Новый пользователь? <Link to='/registration' style={{textDecoration: 'none'}}>
                            <div className='_link_'>Создать учетную запись</div>
                        </Link>
                    </div>
                    <div className={( errors?.phone === null || errors?.phone === undefined ) ? 'form_input' : 'form_input_error'}>
                        <label htmlFor='input' className='text'>Телефон</label>
                        <InputMask mask='9(999)-999-99-99' type='text' name='input' className='input_login' id='phone' onChange={handleChange}  />
                    </div>
                    <div className='error_message_active'>{errors?.phone}</div>
                    <div className={( errors?.password === null || errors?.password === undefined ) ? 'form_input' : 'form_input_error'}>
                        <label htmlFor='input' className='text'>Пароль</label>
                        <input type='text' name='input' className='input_login' id='password' onChange={handleChange}  />
                    </div>
                    <div className='error_message_active'>{errors?.password}</div>
                    <div className='error_message_active'>{stringError}</div>
                    <div className='footer_form'>
                        <div class="checkbox-wrapper-46">
                            <input type="checkbox" id="cbx-46" class="inp-cbx" onClick={handleCheck}/>
                            <label for="cbx-46" class="cbx"
                            ><span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span
                    ><span>Оставаться в системе</span>
                        </label>
                    </div>
                    <div className='login_button' onClick={handleSubmit}>Войти</div>
                    </div>
                </div>
            </div>
            <div className='right_part'>
                <div className='blue_circle'></div>
                <div className='blur_box_right'></div>
            </div>
        </div>
    )
})

export default Login