import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const Navigate = () => {

        const navigate = useNavigate()
        console.log('REDIRECT FUNCTION')
        useEffect(() => {
            setTimeout(() => {
                navigate('/tracker')
            }, 1500)
        }, [])

    return (
        <div className='loading_screen'>
                <div className='loading_box'>
                    E-Tracker
                </div>
                </div>
    )
}

export default Navigate