import './Helper.css'
import NavBar from '../components/NavBar.js';
import NewDebet from '../components/NewDebet.js';
import { useState, useEffect} from 'react'
import { STORE } from '../STORE.js'
import Error from '../components/Error.js'
import BreadCrumbs from '../Logic/BreadCrumbs.js'
import { useNavigate } from 'react-router-dom'
import MediaQuery from 'react-responsive'

const HelperScores = () => {
    const navigate = useNavigate()
    const [success, setSuccess] = useState(true)
    const updateData = (value) => {
        setShowModal(value)
        setUpdateTable(!updateTable)
        setUpdate(!update)
    }

    const handleNewOperation = () => {
        setShowModal(!showModal)
        setUpdateTable(!updateTable)
        setStatus('create')
    }

    const [updateErrors, setUpdateErrors] = useState(false)

    const closeModal = (event) => {
        if (event.target.className === 'modal_overlay') {
            setShowModal(false)
            setUpdate(!update)
            setUpdateErrors(!updateErrors)
        }
    }

    const [status, setStatus] = useState('create')
    const [showModal, setShowModal] = useState(false)
    const [updateTable, setUpdateTable] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const API = `https://eternity-tracker.ru/api/finances/directory/debets/?page=${currentPage}&page_size=20`
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [update, setUpdate] = useState(false)
    const [id, setId] = useState()

    const openDebet = (event) => {
        if (event.target.className === 'mark' || event.target.className === 'tooltip_expired') {

        } else {
            setId(event.currentTarget.id.split('_')[0])
            setStatus('edit')
            setShowModal(true)
        }
    }

    const [fullData, setFullData] = useState()

    useEffect(() => {
        setLoading(true)
        fetch(API, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            setData(data?.data?.items)
            setFullData(data?.data)
            setLoading(false)
            if (data.detail === 'Invalid token') {
                navigate('/login')
            }
        })
        .catch(error => console.error(error))
    }, [update, currentPage])

    useEffect(() => {

        if (showModal) {
            document.body.style.overflowY = "hidden";
        }

        if (!showModal) {
            document.body.style.overflowY = "visible"
        };
    }, [showModal]);

    const makeCellsName = Array.from({length: data?.length}, (_, index) => {
        return (
            <div className='column_helper_cell cell_name' key={index} id={`${data?.[index]?.id}_name`} onClick={openDebet}>
                    <p>{(data[index]?.name === undefined) ? '—' : data[index]?.name}</p>
                    <p className='small_text_cell'>{(data[index]?.bank === undefined) ? '' : data[index]?.bank}</p>
            </div>
        )
    })

    const makeCellsBalance = Array.from({length: data?.length}, (_, index) => {
        return (
            <div className='column_helper_cell' key={index} id={`${data?.[index]?.id}_balance`} onClick={openDebet}>
                    {(data[index]?.balance === undefined) ? '—' : `${data[index]?.balance?.toLocaleString()}₽.`}
            </div>
        )
    })

    const makeCellsRequisites = Array.from({length: data?.length}, (_, index) => {
        return (
            <div className='column_helper_cell' key={index} id={`${data?.[index]?.id}_requisites`} onClick={openDebet}>
                    {(data[index]?.requisites === undefined) ? '—' : data[index]?.requisites}
            </div>
        )
    })

    const makeCellsCount = Array.from({length: data?.length}, (_, index) => {
        return (
            <div className='column_helper_cell' key={index} id={`${data?.[index]?.id}_count`} onClick={openDebet}>
                  <span id='transaction_count'>  {(data[index]?.transactions_count === undefined) ? '—' : data[index]?.transactions_count} </span>
                  <div className='mark' id='fiz_lico' style={data?.[index]?.private_person ? {display: 'block'} : {display: 'none'}}></div>
                  <div className='tooltip_expired'>
                Физ. лицо
            </div>
            </div>
        )
    })

    const CARDS = Array.from({length: data?.length}, (_, index) => {
        return (
            <div className='table_card' onClick={openDebet} id={`${data?.[index]?.id}_id_table_card`}>
                <div className='table_row1'>
                    <div className='table_row1_type' style={{color: 'white', width: '100%'}}>
                        <span style={{fontWeight: '600'}}>{data?.[index]?.name}</span>
                    </div>
                </div>
                <div className='table_row2'>
                    <div className='table_row2_debet'>
                        <span style={{color: 'rgb(255, 255, 255, 0.4'}}>{data?.[index]?.bank}</span>
                    </div>
                </div>
                <div className='table_row4'>
                    <div className='table_row4_comment_'>
                        <span style={{fontWeight: '600', textDecoration: 'underline'}}>Баланс:&nbsp;</span> <span style={{textDecoration: 'underline'}}> {!data?.[index]?.balance ? '0₽' : `${data?.[index]?.balance?.toLocaleString()}₽`}</span>
                    </div>
                </div>
            </div>
        )
    })

    const handleChangePage = (event) => {
        setCurrentPage(+event.target.id.split('_')[0])
    }

    const makePages = Array.from({length: fullData?.navigate?.pages_count}, (_, index) => {
        if (fullData?.navigate?.pages_count < 3) {
        return (
            <div id={`${index + 1}_page`} className='page_link' style={currentPage === index + 1 ? {color: 'white'} : {color: 'color: rgb(99, 99, 99)'}} onClick={handleChangePage}>
                {index + 1}
            </div>
        ) } else {
                if (index < currentPage + 2 && index > currentPage - 4 || index === fullData?.navigate?.pages_count - 1) {
                    if (currentPage === index + 1) {
                        console.log('_____________')
                        console.log(currentPage, index + 1, '=========')
                    }
                    return (
                <div id={`${index + 1}_page`} className='page_link' style={currentPage === index + 1 ? {color: 'white', display: 'block'} : {color: 'rgb(99, 99, 99)'}} onClick={handleChangePage}>
                {index + 1}
            </div>
            )}
            if (index === currentPage + 2) {
                return (
                   <div style={{color: 'rgb(99, 99, 99)', marginRight: '1vw', fontSize: '1.2vw'}}>...</div> 
                )
            }
        }
    })

    if (!STORE.readToken()) {
        navigate('/login')
    } else {

    return (
        <body>
                                                                    <Error errors={!success}/>
        <div className='helper_container_page'>
            <NavBar />
            <div className='main_helper' id='debet_container'>
            <div className='statistic_row' id='statistics'>
        <div className='statistic_cell' id='cell__'>Всего счетов: {fullData?.summary?.debets_count}</div>
        <div className='statistic_cell' id='cell__'>Общий баланс: {fullData?.summary?.sum_balance?.toLocaleString()}₽.</div>
    </div>
                    <MediaQuery minWidth={340} maxWidth={900}>
                    <div className='small_search_box__'>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search' />
                </div>
                    </MediaQuery>
    <BreadCrumbs />
                <div className='helper_row'>
                    <div className='create_button' id='create_button_helper_scores' onClick={handleNewOperation}>
                        Создать
                    </div>
                    <MediaQuery minWidth={900}>
                        
                    <div className='small_search_box_'>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search' />
                </div>
                    </MediaQuery>

                </div>
                <MediaQuery minWidth={900}>
                <div className='table_helper_container'>
                    <div className='table_column' id='name_column'>
                        <div className='table_column_title top_column column_helper_cell'>Название</div>
                        {makeCellsName}
                    </div>
                    <div className='table_column' id='balance_column' style={{width: '17vw'}}>
                        <div className='table_column_title top_column column_helper_cell'>Баланс</div>
                        {makeCellsBalance}
                    </div>
                    <div className='table_column' id='rekvisit_column'>
                        <div className='table_column_title top_column column_helper_cell'>Реквизиты</div>
                        {makeCellsRequisites}
                    </div>
                    <div className='table_column' id='number_column' style={{width: '12vw'}}>
                        <div className='table_column_title top_column column_helper_cell'>Кол-во операций</div>
                        {makeCellsCount}
                    </div>
                </div>
                </MediaQuery>
                <MediaQuery minWidth={340} maxWidth={900}>
                    <div className='card_box' style={{marginLeft: '0'}}>
                        {CARDS}
                    </div>
                </MediaQuery>
            </div>
        </div>
        <div className={(showModal) ? 'modal_overlay' : 'modal_inactive'} onClick={closeModal}>
        <NewDebet update={updateData} updateErrors={updateErrors} status={status} id={id} />
        </div>
        <div className='page_navigation' style={fullData?.navigate?.pages_count === 1 ? {display: 'none'} : {display: 'flex'}}>
                            <div className='page_container'>
                                <div className='page_link' onClick={() => {setCurrentPage(1)}}>&lt;</div>
                                {makePages}
                                <div className='page_link' onClick={() => {setCurrentPage(fullData?.navigate?.pages_count)}}>&gt;</div>
                            </div>
                        </div>
    </body>
    )}
}

export default HelperScores