import { useState, useEffect } from 'react'
import './NewOperation.css'
import { STORE } from '../STORE.js'
import Error from '../components/Error.js'
import { makeDayISO, makeMonthISO, makeYearISO } from '../Logic/DateFormating.js'

const NewCategory = (props) => {

    const [groupName, setGroupName] = useState()
    const [errors, setErrors] = useState()
    const [groupComment, setGroupComment] = useState()
    const [groups, setGroups] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const [selectedGroup, setSelectedGroup] = useState('1')
    const [remain, setRemain] = useState(false)
    const [categoryName, setCategoryName] = useState()
    const [categoryComment, setCategoryComment] = useState()
    const [dataDetail, setDataDetail] = useState()
    const [type, setType] = useState(props.type)
    const [success, setSuccess] = useState(true)

    const [day, setDay] = useState()
    const [month, setMonth] = useState()
    const [year, setYear] = useState()
    const [update, setUpdate] = useState(false)
    const [loading, setLoading] = useState(false)

//__________________________________________ STATES ^^^^^
    
    useEffect(() => {
        setType(props.type)
    }, [props.type])

    const APIgroups = 'https://eternity-tracker.ru/api/finances/directory/categories/create/'
    useEffect(() => {
        fetch(APIgroups, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            setGroups(data?.data?.groups)
        })
        .catch(error => console.error(error))
    }, [update])

    useEffect(() => {
        setErrors(null)
    }, [props.status, type])

    useEffect(() => {
        if (props.preselected !== undefined) {
        let correctID = groups?.find(item => item.name == props.preselected)
        console.log(correctID, '+++++++++++++++')
        setSelectedGroup(correctID?.id)
        setSelectedCategory(props.preselected)
        }
    }, [props.preselected])

    useEffect(() => {

        const APIdetail = `https://eternity-tracker.ru/api/finances/directory/categories/detail/${props.id}`        
            if (props.status === 'edit' && type === 'category') {
                setLoading(true)
        fetch(APIdetail, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            if (props.status === 'edit' && type === 'category') {
                setCategoryName(data?.data?.name)
                setCategoryComment(data?.data?.comment === null ? '' : data?.data?.comment)
                setSelectedGroup(data?.data?.group?.id)
                setSelectedCategory(data?.data?.group?.name)
                setDataDetail(data?.data)
        }
        })
        .then(() => {
            setType('category')
        })
        .catch(error => console.error(error))
    }
    }, [type, props.status, props.id])

    useEffect(() => {
        console.log('bbfigjgj')
        const APIdetailGroup = `https://eternity-tracker.ru/api/finances/directory/categories/group/detail/${props.groupID}`        
        if (props.status === 'edit' && type === 'group') {
            setLoading(true)
        fetch(APIdetailGroup, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            if (props.status === 'edit' && type === 'group') {
                setGroupName(data?.data?.name)
                setGroupComment(data?.data?.comment === null ? '' : data?.data?.comment)
                setDataDetail(data?.data)
        }
        })
        .then(() => {
            setType('group')
            setLoading(false)
        })
        .catch(error => console.error(error))
    }
    }, [type, props.status, props.groupID])

    useEffect(() => {
        if (props.status === 'edit') {
            setNameError(null)
            setCommentError(null)
            setGroupNameError(null)
            setGroupCommentError(null)
        }
    }, [props.status])

    useEffect(() => {
        if (dataDetail?.created_at !== undefined) {
            setDay(makeDayISO(dataDetail?.created_at))
            setMonth(makeMonthISO(dataDetail?.created_at))
            setYear(makeYearISO(dataDetail?.created_at))
        }
    }, [dataDetail])

//____________________________________ USE EFFECTS ^^^^^^

    const handleName = (event) => {
        if (props?.type === 'group') {
            setGroupName(event.target.value)
        }
        if (props?.type === 'category') {
            setCategoryName(event.target.value)
        }
    }

    const handleComment = (event) => {
        if (props?.type === 'group') {
            setGroupComment(event.target.value)
        }
        if (props?.type === 'category') {
            setCategoryComment(event.target.value)
        }
    }

    const deleteGroup = () => {
        const APIdeleteGroup = 'https://eternity-tracker.ru/api/finances/directory/categories/group/delete/'
        fetch(APIdeleteGroup, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                group_id: props.groupID,               
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data?.success) {
                props.update()
            }
        })
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
        })
    }

    const deleteCategory = () => {
        const APIdelete = 'https://eternity-tracker.ru/api/finances/directory/categories/delete/'
        fetch(APIdelete, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                category_id: props.id,               
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data?.success) {
                props.update()
            }
        })
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
        })
    }

    const handleModal = () => {
        props.callback()
    }

    useEffect(() => {
        if (props.status === 'create') {
            setGroupName('')
            setGroupComment('')
            setCategoryName('')
            setCategoryComment('')
            setSelectedGroup(groups?.[0]?.id)
            setSelectedCategory(groups?.[0]?.name)
        }
    }, [props.showCreate, props.status])

    const [nameError, setNameError] = useState(null)
    const [commentError, setCommentError] = useState(null)
    const [groupNameError, setGroupNameError] = useState(null)
    const [groupCommentError, setGroupCommentError] = useState(null)

    const handleSubmit = () => {
        if (props.status === 'create' && type === 'category') {
            const APIcreate = 'https://eternity-tracker.ru/api/finances/directory/categories/create/'
            if (categoryName !== '' && categoryName !== null && categoryName !== undefined) {
            fetch(APIcreate, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: categoryName,
                    comment: !categoryComment ? null : categoryComment,
                    group_id: selectedGroup,               
                })
            })
            .then(response => response.json())
            .then(data => {
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                    sessionStorage.setItem('notification', data?.notification === undefined ? 'Категория была успешно создана' : data?.notification)
                    sessionStorage.setItem('type', 'create_operation')
                    if (remain) {
    
                    } else if (!remain) {
                        handleModal()
                        props.update()
                    }
                }
                setErrors(data?.errors)
                setUpdate(!update)
            })
            .then(() => {
                setCategoryName('')
                setCategoryComment('')
                setSelectedCategory(groups?.[0]?.name)
                setSelectedGroup(groups?.[0]?.id)
            })
            .catch(error => console.error(error))
        } else {
            if (categoryName === '' || categoryName === null || categoryName === undefined) {
                setNameError('Поле не может быть пустым')
            } else {
                setNameError(null)
            }
        }
        }
        if (props.status === 'create' && type === 'group') {
            const APIcreateGroup = 'https://eternity-tracker.ru/api/finances/directory/categories/group/create/'
            if (groupName !== '' && groupName !== null && groupName !== undefined) {
            fetch(APIcreateGroup, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: groupName,
                    comment: !groupComment ? null : groupComment,            
                })
            })
            .then(response => response.json())
            .then(data => {
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                    sessionStorage.setItem('notification', data?.notification === undefined ? 'Группа была успешно создана' : data?.notification)
                    sessionStorage.setItem('type', 'create_operation')
                    if (remain) {
    
                    } else if (!remain) {
                        handleModal()
                        props.update()
                    }
                }
                setErrors(data?.errors)
                setUpdate(!update)
            })
            .then(() => {
                setGroupName('')
                setGroupComment('')
            })
            .catch(error => console.error(error))
        } else {
            if (groupName === '' || groupName === null || groupName === undefined) {
                setGroupNameError('Поле не может быть пустым')
            } else {
                setGroupNameError(null)
            }
        }

        } 
        if (props.status === 'edit' && type === 'category') {
            const APIedit = 'https://eternity-tracker.ru/api/finances/directory/categories/edit/'
            if (categoryName !== '' && categoryName !== null && categoryName !== undefined) {
            fetch(APIedit, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    category_id: props.id,
                    edit_data: {
                    name: categoryName,
                    comment: !categoryComment ? null : categoryComment,
                    group_id: selectedGroup, 
                    }              
                })
            })
            .then(response => response.json())
            .then(data => {
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                    sessionStorage.setItem('notification', data?.notification === undefined ? 'Категория была успешно изменена' : data?.notification)
                    sessionStorage.setItem('type', 'create_operation')
                    if (remain) {
    
                    } else if (!remain) {
                        handleModal()
                        props.update()
                        setUpdate(!update)
                    }
                    
                }
                setErrors(data?.errors)
            })
            .catch(error => console.error(error))
        } else {
            if (categoryName === '' || categoryName === null || categoryName === undefined) {
                setNameError('Поле не может быть пустым')
            } else {
                setNameError(null)
            }
        }
        }
        if (props.status === 'edit' && type === 'group') {
            const APIeditGroup = 'https://eternity-tracker.ru/api/finances/directory/categories/group/edit/'
            if (groupName !== '' && groupName !== null && groupName !== undefined) {
            fetch(APIeditGroup, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    group_id: props.groupID,
                    edit_data: {
                    name: groupName,
                    comment: !groupComment ? null : groupComment, 
                    }              
                })
            })
            .then(response => response.json())
            .then(data => {
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                    sessionStorage.setItem('notification', data?.notification === undefined ? 'Группа была успешно изменена' : data?.notification)
                    sessionStorage.setItem('type', 'create_operation')
                    if (remain) {
                    } else if (!remain) {
                        handleModal()
                        props.update()
                        setUpdate(!update)
                    }
                }
                setErrors(data?.errors)
            })
            .catch(error => console.error(error))
        } else {
            if (groupName === '' || groupName === null || groupName === undefined) {
                setGroupNameError('Поле не может быть пустым')
            } else {
                setGroupNameError(null)
            }
        }
        }
    }

    const handleRemainModal = () => {
        setRemain(!remain)
    }

    const handleSelect = (event) => {
        setSelectedGroup(event.currentTarget.options[event.currentTarget.selectedIndex].id?.split('_')[0])
        setSelectedCategory(event.currentTarget.options[event.currentTarget.selectedIndex].innerHTML)
    }

    useEffect(() => {
        console.log(loading, '_)________')
    }, [loading])

//___________________________ HANDLERS ^^^^^^^

    const makeCategoryOptions = Array.from({length: groups?.length}, (_, index) => {
        return (
            <option value={groups?.[index]?.name} id={`${groups?.[index]?.id}_category_group`}>{groups?.[index]?.name}</option>
        )
    })

//________________________________ BUILDER FUNCTIONS ^^^^

    if (props?.type === 'group') {
        if (loading) {
            return (
                <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', zIndex: '50'}}>
                            <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
        <circle
        class="active"
        pathLength="360"
        fill="transparent"
        stroke-width="32"
        cx="192"
        cy="192"
        r="176"
        ></circle>
        <circle
        class="track"
        pathLength="360"
        fill="transparent"
        stroke-width="32"
        cx="192"
        cy="192"
        r="176"
        ></circle>
        </svg>
        </div>
            )
        }
        return (
            <><Error errors={!success} message={errors} /><div className='new_operation_container' id='category_create'>
                <div className='row1'>{props.status === 'edit' ? `Редактирование группы` : 'Создание группы'}
                    <div className='creator_box' style={props?.status === 'edit' ? { display: 'flex' } : { display: 'none' }}>
                        <span>Создатель: {dataDetail?.creator?.name}</span>
                        <span>Создана: {day} {month} {year}</span>
                    </div>
                </div>
                <div className='data_row modal_row'>
                    Название
                    <input type='text' id='name_input' onChange={handleName} value={groupName} placeholder='Название группы' style={(typeof groupNameError === 'string') ? { border: '0.1vw solid rgb(255, 0, 0)' } : { border: '0.1vw solid rgb(255, 255, 255, 0.15)' }} />
                    <label htmlFor='name_input' className='error_message_'>{groupNameError}</label>
                </div>
                <div className='comment_row modal_row' onChange={handleComment}>
                    Комментарий
                    <textarea id='comment_input__' value={groupComment} style={(typeof groupCommentError === 'string') ? { border: '0.1vw solid rgb(255, 0, 0)' } : { border: '0.1vw solid rgb(255, 255, 255, 0.15)' }} />
                    <label htmlFor='comment_input__' className='error_message_'>{groupCommentError}</label>
                </div>
                <div className='footer_row'>
                    <div className='delete_button' style={props.status === 'edit' ? { display: 'flex' } : { display: 'none' }} onClick={deleteGroup}>Удалить</div>
                    <div className='cancel' onClick={handleModal}>
                        Отменить
                    </div>
                    <div className='create' id='create_income' onClick={handleSubmit}>
                        {props.status === 'edit' ? 'Сохранить' : 'Создать'}
                    </div>
                </div>
            </div></>
        )
    }
    if (props?.type === 'category') {
        if (loading) {
            return (
                <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', zIndex: '50', width: '100vw', height: '100vh'}}>
                            <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
        <circle
        class="active"
        pathLength="360"
        fill="transparent"
        stroke-width="32"
        cx="192"
        cy="192"
        r="176"
        ></circle>
        <circle
        class="track"
        pathLength="360"
        fill="transparent"
        stroke-width="32"
        cx="192"
        cy="192"
        r="176"
        ></circle>
        </svg>
        </div>
            )
        }
        return (
            <><Error errors={!success} message={errors} /><div className='new_operation_container' id='category_create2'>
                <div className='row1'>{props.status === 'edit' ? `Редактирование категории` : 'Создание категории'}
                    <div className='creator_box' style={props?.status === 'edit' ? { display: 'flex' } : { display: 'none' }}>
                        <span>Создатель: {dataDetail?.creator?.name}</span>
                        <span>Создана: {day} {month} {year}</span>
                    </div>
                </div>
                <div className='data_row modal_row'>
                    Название
                    <input type='text' id='name_input' onChange={handleName} value={categoryName} placeholder='Название категории' style={(typeof nameError === 'string') ? { border: '0.1vw solid rgb(255, 0, 0)' } : { border: '0.1vw solid rgb(255, 255, 255, 0.15)' }} />
                    <label htmlFor='name_input' className='error_message_'>{nameError}</label>
                </div>
                <div className='comment_row modal_row' onChange={handleComment}>
                    Комментарий
                    <textarea id='comment_input__' value={categoryComment} style={(typeof commentError === 'string') ? { border: '0.1vw solid rgb(255, 0, 0)' } : { border: '0.1vw solid rgb(255, 255, 255, 0.15)' }} />
                    <label htmlFor='comment_input__' className='error_message_'>{commentError}</label>
                </div>
                <div className='category_row modal_row' id='group_row_'>
                    Группа
                    <select id='category_input' className='category_select_' value={selectedCategory} placeholder='Выберите группу...' onChange={handleSelect} style={(typeof errors?.group_id?.[0] === 'string') ? { border: '0.1vw solid rgb(255, 0, 0' } : { border: '0.1vw solid rgb(255, 255, 255, 0.15' }}>
                        {makeCategoryOptions}
                    </select>
                </div>
                <div className='footer_row'>
                    <div className='delete_button' style={props.status === 'edit' ? { display: 'flex' } : { display: 'none' }} onClick={deleteCategory}>Удалить</div>
                    <div className='cancel' onClick={handleModal}>
                        Отменить
                    </div>
                    <div className='create' id='create_income' onClick={handleSubmit}>
                        {props.status === 'edit' ? 'Сохранить' : 'Создать'}
                    </div>
                </div>
            </div></>
        )
    }
}

export default NewCategory