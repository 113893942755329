
const months = ['янв', 'фев', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сент', 'окт', 'нояб', 'дек']
export const makeMonth = (date) => {
    return months[+(date[0] + date[1]) - 1]
}

export const makeDay = (date) => {
    let day
    if (date[3] === '0') {
        day = date[4]
    } else {
        day = date[3] + date[4]
    }

    return day
}

export const makeHours = (date) => {
    let hours = date[6] + date[7] + date[8] + date[9] + date[10]
    return hours
}

export const makeYear = (date) => {
    let year = date?.[0] + date?.[1] + date?.[2] + date?.[3]
    return year
}



export const makeMonthISO = (date) => {
    return months[+(date[5] + date[6]) - 1]
}

export const makeDayISO = (date) => {
    let day
    if (date[8] === '0') {
        day = date[9]
    } else {
        day = date[8] + date[9]
    }
    return day
}

export const makeHoursISO = (date) => {
    let hours = date[6] + date[7] + date[8] + date[9] + date[10]
    return hours
}

export const makeYearISO = (date) => {
    let year = date[0] + date[1] + date[2] + date[3]
    return year
}