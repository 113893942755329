import NavBar from '../components/NavBar.js';
import './Finances.css'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useState, useEffect } from 'react'
import ru from 'date-fns/locale/ru';
import Table from '../components/Table.js'
import { useNavigate } from 'react-router-dom'
import { STORE } from '../STORE.js'
import { endOfDay } from 'date-fns';
import Multiselect from 'multiselect-react-dropdown'
import CurrencyInput from 'react-currency-input-field'
import Error from '../components/Error.js'
import BreadCrumbs from '../Logic/BreadCrumbs.js'
import MediaQuery from 'react-responsive'


const Finances = () => {
    const navigate = useNavigate()
    if (STORE.token === '' || STORE.token === undefined || STORE.token === null) {
        navigate('/login') 
    } else {

    }

    const [count, setCount] = useState(STORE.FinancesLoader)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState(null)
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }


    useEffect(() => {
        setTimeout(() => {
            STORE.setFinancesLoader()
            setCount(STORE.FinancesLoader)
        }, 1500)
    }, [])

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [success, setSuccess] = useState(true)
    const isClient = false

    const API = `https://eternity-tracker.ru/api/finances/transactions/?page=${currentPage}&page_size=50`

    const [update, setUpdate] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [sort, setSort] = useState('-date')

    const updateData = (value) => {
        setUpdate(!update)
        setShowModal(value)
    }

    const updateSort = (value) => {
        if (sort === value) {
            setSort(`-${value}`)
        } else {
            setSort(value)
        }
    }

    useEffect(() => {
        if (sort === '--id' || sort === '--date' || sort === '--date' || sort === '--cost') {
            setSort('-date')
        } 
    }, [sort])

    const [dataSet, setDataSet] = useState()
    const APIfilters = 'https://eternity-tracker.ru/api/finances/transactions/create/'
    useEffect(() => {
        fetch(APIfilters, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setDataSet(data.data)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
        if (data.detail === 'Invalid token') {
            navigate('/login')
        }
        })
        .catch(error => console.error(error))
    }, [])
    
    const [categories, setCategories] = useState()
    const [clients, setClients] = useState()
    const [employes, setEmployes] = useState()

    useEffect(() => {
        console.log(sort, '___________')
    }, [sort])

    useEffect(() => {

        let arr = []
        let x = 0
        for (let i = 0; i < dataSet?.categories?.length; i++) {
            for (let j = 0; j < dataSet?.categories?.[i]?.categories?.length; j++) {
                arr[x] = {
                    group: dataSet?.categories?.[i]?.group_name,
                    name: dataSet?.categories?.[i]?.categories?.[j]?.category_name,
                    id: dataSet?.categories?.[i]?.categories?.[j]?.category_id,
                }
                x++
            }
        }

        setCategories(arr)

        let clients1 = []
        let y = 0
        for (let i = 0; i < dataSet?.customers?.length; i++) {
            for (let j = 0; j < dataSet?.customers?.[i]?.customers?.length; j++) {
                clients1[y] = {
                    group: dataSet?.customers?.[i]?.group_name,
                    name: dataSet?.customers?.[i]?.customers?.[j]?.customer_name,
                    id: dataSet?.customers?.[i]?.customers?.[j]?.customer_id,
                }
                y++
            } 
        }

        setClients(clients1)

        let emp = []
        let z = 0
        for (let i = 0; i < dataSet?.employees?.length; i++) {
            for (let j = 0; j < dataSet?.employees?.[i]?.employees?.length; j++) {
                emp[z] = {
                    group: dataSet?.employees?.[i]?.department_name,
                    name: dataSet?.employees?.[i]?.employees?.[j]?.name,
                    last_name: dataSet?.employees?.[i]?.employees?.[j]?.last_name,
                    id: dataSet?.employees?.[i]?.employees?.[j]?.id,
                }
                z++
            } 
        }

        setEmployes(emp)

    }, [dataSet])

    const [debets, setDebets] = useState()

    const handleDebet = (event) => {
        setDebets(event)
    }

    const handleRemoveDebet = (event) => {
        setDebets(event)
    }

    const [category, setCategory] = useState()

    const handleCategory = (event) => {
        setCategory(event)
    }

    const handleRemoveCategory = (event) => {
        setCategory(event)
    }

    const [customers, setCustomers] = useState()

    const handleClients = (event) => {
        setCustomers(event)
    }

    const handleRemoveClients = (event) => {
        setCustomers(event)
    }

    const [selectedEmployes, setSelectedEmployes] = useState()

    const handleEmployees = (event) => {
        setSelectedEmployes(event)
    }

    const handleRemoveEmployees = (event) => {
        setSelectedEmployes(event)
    }

    const [income, setIncome] = useState(false)
    const [expense, setExpense] = useState(false)
    const [transfer, setTransfer] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const [unconfirmed, setUnconfirmed] = useState(false)

    const [type, setType] = useState([
        '', '', '',
    ])

    const handleFilter = (event) => {
        if (event.currentTarget.id[5] === '6') {
            setIncome(!income)
        } 
        if (event.currentTarget.id[5] === '7') {
            setExpense(!expense)
        } 
        if (event.currentTarget.id[5] === '8') {
            setTransfer(!transfer)
        }
        if (event.currentTarget.id[5] === '9') {
            setConfirmed(!confirmed)
        }
        if (event.currentTarget.id[5] === '0') {
            setUnconfirmed(!unconfirmed)
        }
    }

    useEffect(() => {
        setType([
            (income) ? 'income' : '',
            (expense) ? 'expense' : '',
            (transfer) ? 'transfer' : ''
        ])
    }, [income, expense, transfer])


    const checkConfirm = () => {
        if (confirmed === unconfirmed) {
            return null
        } else if (confirmed) {
            return confirmed
        } else if (unconfirmed) {
            return false
        }
    }

    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')

    const handleSum = (event) => {
        console.log(from, '+++++++++++++++++++++++++')
        let sum = event.target.value.split('')
        sum = sum.join('')
        sum = sum.replace(/\s/g, '')
        sum = sum.replace(/[^\d.,-]/g, '')
        console.log(sum)
        if (event.target.id === 'from') {
            setFrom(sum === '' ? null : sum)
        }
        if (event.target.id === 'to') {
            setTo(sum === '' ? null : sum)
        }
    }

    const handleFilters = () => {
        setUpdate(!update)
        setCurrentPage(1)
    }

    const [notification, setNotification] = useState(null)
    const [errors, setErrors] = useState()

    useEffect(() => {
        setLoading(true)
        fetch(API, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                type: (!income && !expense && !transfer) ? [] : type,
                "is_confirm": checkConfirm(),
                "debets": debets?.map(item => item.id),
                "categories": category?.map(item => item.id),
                "customers": customers?.map(item => item.id),
                employees: selectedEmployes?.map(item => item.id),
                "with_invoice": null, 
                 
                "date_range_after": startDate?.toISOString()?.split('T')?.[0],
                "date_range_before": endDate?.toISOString()?.split('T')?.[0],
            
                "cost_range_min": from,
                "cost_range_max": to,

                sort: sort === '--date' ? '-date' : sort
            })
        })
        .then(response => response.json())
        .then(data => {
            setData(data)
            setLoading(false)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            setErrors(data?.errors)
        })
        .catch(error => console.error(error))
    }, [update, currentPage, sort])

    const handleChangePage = (event) => {
        setCurrentPage(+event.target.id.split('_')[0])
        console.log(event.target.id[0], '++++++++++++++++++')
    }

    const makePages = Array.from({length: data?.data?.navigate?.pages_count}, (_, index) => {
        if (data?.data?.navigate?.pages_count < 3) {
        return (
            <div id={`${index + 1}_page`} className='page_link' style={currentPage === index + 1 ? {color: 'white'} : {color: 'color: rgb(99, 99, 99)'}} onClick={handleChangePage}>
                {index + 1}
            </div>
        ) } else {
                if (index < currentPage + 2 && index > currentPage - 4 || index === data?.data?.navigate?.pages_count - 1) {
            return (
                <div id={`${index + 1}_page`} className='page_link' style={currentPage === index + 1 ? {color: 'white', display: 'block'} : {color: 'color: rgb(99, 99, 99)'}} onClick={handleChangePage}>
                {index + 1}
            </div>
            )}
            if (index === currentPage + 2) {
                return (
                   <div className='page_link' style={{color: 'rgb(99, 99, 99)'}}>...</div> 
                )
            }
        }
    })

    const [showFilters, setShowFilters] = useState(false)

    const callBackFilters = () => {
        setShowFilters(!showFilters)
    }

    const closeFilters = (event) => {
        if (event.target.className === 'modal_overlay') {
            setShowFilters(false)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            const emptyMSG = document.getElementsByClassName('notFound')
            console.log(emptyMSG.length)
            if (emptyMSG.length === 1) {
                emptyMSG[0].innerHTML = ' '
            } 
            if (emptyMSG.length === 2) {
                emptyMSG[0].innerHTML = ' '
                emptyMSG[1].innerHTML = ' '
            } 
            if (emptyMSG.length === 3) {
                emptyMSG[0].innerHTML = ' '
                emptyMSG[1].innerHTML = ' '
                emptyMSG[2].innerHTML = ' '
            }
        }, 10)
    })

    const addCommas = num => num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const removeNonNumeric = num => num?.toString()?.replace(/[^0-9]/g, "");

    if (!STORE.readToken()) {
        navigate('/login')
    } else {

    if (loading) {
        return (
            <body style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
  <circle
    class="active"
    pathLength="360"
    fill="transparent"
    stroke-width="32"
    cx="192"
    cy="192"
    r="176"
  ></circle>
  <circle
    class="track"
    pathLength="360"
    fill="transparent"
    stroke-width="32"
    cx="192"
    cy="192"
    r="176"
  ></circle>
</svg>
</body>
        )
    } else {

    return (
        <>
        <Error errors={!success} message={errors}/>
        <div style={(count > 0) ? { display: 'none' } : { display: 'flex' }}>
            <div className='loading_screen'>
                <div className='loading_box'>
                    E-Tracker
                </div>
            </div>
        </div><div className='finances_container'>
                <NavBar />
                <div className='main_zone'>
                    <div className='main_left'>
                        <div className='operation_type'>Тип операции
                            <div className='type_svg'>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 18.01L12.01 17.9989" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div class="checkbox-wrapper-46" id='checkbox1_'>
                            <input type="checkbox" id="cbx-46" class="inp-cbx" checked={income}  onClick={handleFilter}/>
                            <label for="cbx-46" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1'>
                                    Доход
                                </span>
                            </label>
                        </div>
                        <div class="checkbox-wrapper-46" id='checkbox2_'>
                            <input type="checkbox" id="cbx-47" class="inp-cbx" checked={expense}  onClick={handleFilter}/>
                            <label for="cbx-47" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1'>
                                    Расход
                                </span>
                            </label>
                        </div>
                        <div class="checkbox-wrapper-46" id='checkbox3_'>
                            <input type="checkbox" id="cbx-48" class="inp-cbx" checked={transfer}  onClick={handleFilter}/>
                            <label for="cbx-48" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1'>
                                    Перемещение
                                </span>
                            </label>
                        </div>
                        <div className='operation_type'>
                            Дата оплаты
                            <div className='type_svg'>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 18.01L12.01 17.9989" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div class="checkbox-wrapper-46" id='checkbox3_'>
                            <input type="checkbox" id="cbx-49" class="inp-cbx" checked={confirmed} onClick={handleFilter} />
                            <label for="cbx-49" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1'>
                                    Подтверждена
                                </span>
                            </label>
                        </div>
                        <div class="checkbox-wrapper-46" id='checkbox3_'>
                            <input type="checkbox" id="cbx-50" class="inp-cbx" checked={unconfirmed}  onClick={handleFilter}/>
                            <label for="cbx-50" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1'>
                                    Не подтверждена
                                </span>
                            </label>
                        </div>
                        <div className='date_label'>
                            <div className='date_label_svg'>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 9H21M7 3V5M17 3V5M6 12H10V16H6V12ZM6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#919191" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            Укажите период
                        </div>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            locale={ru}
                            className='date_picker'
                            placeholderText='Укажите период'
                            isClearable={true}
                            dateFormat='dd/MM/YYYY' />
                        <div className='operation_type'>
                            Параметры
                            <div className='type_svg'>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 18.01L12.01 17.9989" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className='input input_debets'>
                           <Multiselect 
                            options={dataSet?.debets}
                            displayValue='name'
                            placeholder='Счета'
                            hidePlaceholder={true}
                            onSelect={handleDebet}
                            onRemove={handleRemoveDebet}
                            selectedValues={debets}
                            overrideString={{
                            
                            }}
                           />
                        </div>
                        <div className='input input_category'>
                            <Multiselect 
                            options={categories}
                            displayValue='name'
                            placeholder='Категории'
                            hidePlaceholder={true}
                            groupBy='group'
                            onSelect={handleCategory}
                            onRemove={handleRemoveCategory}
                            selectedValues={category}
                           />
                        </div>
                        <div className='input input_category'>
                        <Multiselect 
                            options={clients}
                            displayValue='name'
                            placeholder='Клиенты'
                            hidePlaceholder={true}
                            groupBy='group'
                            onSelect={handleClients}
                            onRemove={handleRemoveClients}
                            selectedValues={customers}
                           />
                        </div>
                        <div className='input input_category'>
                        <Multiselect 
                            options={employes}
                            displayValue='name'
                            placeholder='Сотрудники'
                            hidePlaceholder={true}
                            groupBy='group'
                            onSelect={handleEmployees}
                            onRemove={handleRemoveEmployees}
                            selectedValues={selectedEmployes}
                           />
                        </div>
                        <div className='mini_inputs'>
                            <div className='input_mini'>
                                <CurrencyInput decimalSeparator='.'placeholder='Сумма от' prefix='₽' value={from} className='input_input_mini' id='from' onChange={handleSum} />
                            </div>
                            <div className='line_container'>
                                <div className='line'></div>
                            </div>
                            <div className='input_mini'>
                                <CurrencyInput decimalSeparator='.'type='text' placeholder='До' value={to} prefix='₽' className='input_input_mini' id='to' onChange={handleSum} />
                            </div>
                        </div>
                        <div className='confirm_button' onClick={handleFilters}>
                                Применить
                            </div>
                    </div>
                    <div className='main_right'>
                        <Table data={data} isClient={isClient} startDate={startDate} sort={sort} endDate={endDate} updateFilters={callBackFilters} update={updateData} updateSort={updateSort}/>
                        <div className='page_navigation' style={data?.data?.navigate?.pages_count === 1 || data?.data?.navigate?.pages_count === 0 ? {display: 'none'} : {display: 'flex'}}>
                            <div className='page_container'>
                                <div className='page_link' onClick={() => {setCurrentPage(1)}}>&lt;</div>
                                {makePages}
                                <div className='page_link' onClick={() => {setCurrentPage(data?.data?.navigate?.pages_count)}}>&gt;</div>
                            </div>
                        </div>
                    </div>
                    <MediaQuery minWidth={340} maxWidth={900}>
                        <div className={showFilters ? 'modal_overlay' : 'modal_inactive'} onClick={closeFilters}>
                        <div className='close_modal_x' onClick={() => {setShowFilters(false)}}>
                <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00386 9.41816C7.61333 9.02763 7.61334 8.39447 8.00386 8.00395C8.39438 7.61342 9.02755 7.61342 9.41807 8.00395L12.0057 10.5916L14.5907 8.00657C14.9813 7.61605 15.6144 7.61605 16.0049 8.00657C16.3955 8.3971 16.3955 9.03026 16.0049 9.42079L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.0039C15.6133 16.3945 14.9802 16.3945 14.5896 16.0039L12.0057 13.42L9.42097 16.0048C9.03045 16.3953 8.39728 16.3953 8.00676 16.0048C7.61624 15.6142 7.61624 14.9811 8.00676 14.5905L10.5915 12.0058L8.00386 9.41816Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z" fill="white"/>
</svg>
                </div>
                            <div className='new_operation_container'>
                            <div className='row1' style={{fontSize: '5vw'}}>Фильтры</div>
                            <div className='operation_type'>Тип операции
                            <div className='type_svg'>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 18.01L12.01 17.9989" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div class="checkbox-wrapper-46" id='checkbox1_'>
                            <input type="checkbox" id="cbx-46" class="inp-cbx" checked={income}  onClick={handleFilter}/>
                            <label for="cbx-46" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1'>
                                    Доход
                                </span>
                            </label>
                        </div>
                        <div class="checkbox-wrapper-46" id='checkbox2_'>
                            <input type="checkbox" id="cbx-47" class="inp-cbx" checked={expense}  onClick={handleFilter}/>
                            <label for="cbx-47" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1'>
                                    Расход
                                </span>
                            </label>
                        </div>
                        <div class="checkbox-wrapper-46" id='checkbox3_'>
                            <input type="checkbox" id="cbx-48" class="inp-cbx" checked={transfer}  onClick={handleFilter}/>
                            <label for="cbx-48" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1'>
                                    Перемещение
                                </span>
                            </label>
                        </div>
                        <div className='operation_type'>
                            Дата оплаты
                            <div className='type_svg'>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 18.01L12.01 17.9989" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div class="checkbox-wrapper-46" id='checkbox3_'>
                            <input type="checkbox" id="cbx-49" class="inp-cbx" checked={confirmed} onClick={handleFilter} />
                            <label for="cbx-49" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1'>
                                    Подтверждена
                                </span>
                            </label>
                        </div>
                        <div class="checkbox-wrapper-46" id='checkbox3_'>
                            <input type="checkbox" id="cbx-50" class="inp-cbx" checked={unconfirmed}  onClick={handleFilter}/>
                            <label for="cbx-50" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1'>
                                    Не подтверждена
                                </span>
                            </label>
                        </div>
                        <div className='date_label'>
                            <div className='date_label_svg'>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 9H21M7 3V5M17 3V5M6 12H10V16H6V12ZM6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#919191" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            Укажите период
                        </div>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            locale={ru}
                            className='date_picker'
                            placeholderText='Укажите период'
                            isClearable={true}
                            dateFormat='dd/MM/YYYY' />
                        <div className='operation_type'>
                            Параметры
                            <div className='type_svg'>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 18.01L12.01 17.9989" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className='input input_debets'>
                           <Multiselect 
                            options={dataSet?.debets}
                            displayValue='name'
                            placeholder='Счета'
                            hidePlaceholder={true}
                            onSelect={handleDebet}
                            onRemove={handleRemoveDebet}
                            selectedValues={debets}
                            overrideString={{
                            
                            }}
                           />
                        </div>
                        <div className='input input_category'>
                            <Multiselect 
                            options={categories}
                            displayValue='name'
                            placeholder='Категории'
                            hidePlaceholder={true}
                            groupBy='group'
                            onSelect={handleCategory}
                            onRemove={handleRemoveCategory}
                            selectedValues={category}
                           />
                        </div>
                        <div className='input input_category'>
                        <Multiselect 
                            options={clients}
                            displayValue='name'
                            placeholder='Клиенты'
                            hidePlaceholder={true}
                            groupBy='group'
                            onSelect={handleClients}
                            onRemove={handleRemoveClients}
                            selectedValues={customers}
                           />
                        </div>
                        <div className='input input_category'>
                        <Multiselect 
                            options={employes}
                            displayValue='name'
                            placeholder='Сотрудники'
                            hidePlaceholder={true}
                            groupBy='group'
                            onSelect={handleEmployees}
                            onRemove={handleRemoveEmployees}
                            selectedValues={selectedEmployes}
                           />
                        </div>
                        <div className='mini_inputs'>
                            <div className='input_mini'>
                                <CurrencyInput decimalSeparator='.'placeholder='Сумма от' prefix='₽' value={from} className='input_input_mini' id='from' onChange={handleSum} />
                            </div>
                            <div className='line_container'>
                                <div className='line'></div>
                            </div>
                            <div className='input_mini'>
                                <CurrencyInput decimalSeparator='.'type='text' placeholder='До' value={to} prefix='₽' className='input_input_mini' id='to' onChange={handleSum} />
                            </div>
                        </div>
                        <div className='confirm_button' onClick={handleFilters}>
                                Применить
                            </div>
                            </div>
                        </div>
                    </MediaQuery>
                </div>
            </div>
            </>
    )}
}}

export default Finances