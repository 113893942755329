import { makeAutoObservable } from 'mobx'

class Store {
    
    token = ''

    constructor() {
        makeAutoObservable(this)
    }

    writeToken(token) {
        this.token = token
        localStorage.setItem('token', this.token)
        sessionStorage.setItem('token', '') 

    }

    readToken() {
        console.log(localStorage.getItem('token'))
        if (sessionStorage.getItem('token') === '' || sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === undefined) {
            if (localStorage.getItem('token') === '' || localStorage.getItem('token') === null || localStorage.getItem('token') === undefined) {
                this.token = ''
            } else {
                this.token = localStorage.getItem('token')
            }
        } else {
            this.token = sessionStorage.getItem('token')
        }
        
        return this.token
    }

    writeTokenTimely(token) {
        this.token = token 
        sessionStorage.setItem('token', this.token)
        localStorage.setItem('token', '') // --------------------------- DELETE AFTER UPDATE LOGOUT BUTTON
    }

    InBoxLoader = 0

    setInBoxLoader() {
        this.InBoxLoader += 1
    }

    FinancesLoader = 0

    setFinancesLoader() {
        this.FinancesLoader += 1
    }
}

export const STORE = new Store()