import './Helper.css'
import NavBar from '../components/NavBar.js'
import { useState, useEffect } from 'react'
import { STORE } from '../STORE.js'
import NewCategory from '../components/NewCategory'
import Error from '../components/Error.js'
import BreadCrumbs from '../Logic/BreadCrumbs.js'
import { useNavigate } from 'react-router-dom'
import MediaQuery from 'react-responsive'

const HelperOutlays = () => {
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [showCreate, setShowCreate] = useState(false)
    const [status, setStatus] = useState('create')
    const [type, setType] = useState()
    const [success, setSuccess] = useState(true)

    const handleNewOperation = (event) => {
        if (event.target.id === 'make_category') {
            setType('category')
            setShowCreate(true)
            setStatus('create')
        }
        if (event.target.id === 'make_group') {
            setType('group')
            setShowCreate(true)
            setStatus('create')
        } else {
            setShowModal(!showModal)
        }
    }

    const [id, setId] = useState('1')
    const [groupID, setGroupID] = useState('1')

    const openDetail = (event) => {
        setShowCreate(true)
        setId(event.target.id.split('_')[0])
        setStatus('edit')
        setType('category')
        console.log(event.target)
    }

    const openGroup = (event) => {
        setShowCreate(true)
        setGroupID(event.currentTarget.id.split('_')[0])
        setStatus('edit')
        setType('group')
        console.log(event.currentTarget.id.split('_')[0])
    }

    const closeModal = (event) => {
        if (event.target.className === 'modal_overlay') {
            setShowCreate(false)
            setStatus()
            setType()
        }
    }

    const handleCloseModal = (event) => {
        if (event.target.id !== 'create_button_helper' && event.target.id !== 'svg') {
            setShowModal(false)
        } else if (event.target.id === 'create_button_helper' || event.target.id === 'svg') {
            setShowModal(!showModal)
        }
    }

    const [preselectedGroup, setPreselectedGroup] = useState()

    const makeCategory = (event) => {
        setShowCreate(true)
        setType('category')
        setStatus('create')
        setPreselectedGroup(event.currentTarget.id)
        console.log(event.currentTarget.id, '============')
    }

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        if (showCreate) {
            document.body.style.overflowY = "hidden";
        }

        if (!showCreate) {
            document.body.style.overflowY = "visible"
        };
    }, [showCreate]);

    const API = 'https://eternity-tracker.ru/api/finances/directory/categories/'

    const [update, setUpdate] = useState(false)

    const updateData = (value) => {
        setShowCreate(!showCreate)
        setUpdate(!update)
    }

    const callBackClose = (value) => {
        setShowCreate(false)
        setStatus()
        setType()
    }
    
    useEffect(() => {
        setLoading(true)
        fetch(API, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            setData(data?.data)
            setLoading(false)
            if (data.detail === 'Invalid token') {
                navigate('/login')
            }
        })
        .catch(error => console.error(error))
    }, [update])

    const makeGroups = Array.from({length: data?.length}, (_, ind) => {
        const makeCellsName = Array.from({length: data?.[ind]?.categories?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' id={`${data?.[ind]?.categories?.[index]?.id}_category_id`}  onClick={openDetail}>{data?.[ind]?.categories?.[index]?.name}</div>
            )
        })
        const makeCellsOperations = Array.from({length: data?.[ind]?.categories?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' id={`${data?.[ind]?.categories?.[index]?.id}_category_id`} onClick={openDetail}>{data?.[ind]?.categories?.[index]?.transactions_count === undefined ? '0' : data?.[ind]?.categories?.[index]?.transactions_count}</div>
            )
        })

        const makeCellsIncomes = Array.from({length: data?.[ind]?.categories?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' id={`${data?.[ind]?.categories?.[index]?.id}_category_id`} onClick={openDetail} style={{color: 'rgb(111, 255, 111)'}}>+{data?.[ind]?.categories?.[index]?.incomes === null ? '0' : data?.[ind]?.categories?.[index]?.incomes?.toLocaleString()}₽</div>
            )
        })
    
        const makeCellsOutlay = Array.from({length: data?.[ind]?.categories?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' id={`${data?.[ind]?.categories?.[index]?.id}_category_id`} onClick={openDetail} style={{color: 'rgb(255, 104, 104)'}}>-{ data?.[ind]?.categories?.[index]?.expenses === null ? '0' : data?.[ind]?.categories?.[index]?.expenses?.toLocaleString()}₽</div>
            )
        })


        if (data?.[ind]?.categories?.length === 0) {
            return (
                <><div className='group_title' id={`${data?.[ind]?.group_id}_group_id`} onClick={openGroup}>
                <p className='gp_title'>{data?.[ind]?.group_name}</p>
            </div>
            <div className='table_helper_container'>
            <div className='table_column' id='name_column'>
                <div className='column_helper_cell'></div>
            </div>
            <div className='table_column' id='type_column'>
                <div className='column_helper_cell' id='make_new'> Нет категорий
                    <div className='create_tooltip' id={data?.[ind]?.group_name} style={{color: 'white'}} onClick={makeCategory}>Создать категорию</div>
                 </div>
            </div>
            <div className='table_column' id='group_column'>
                <div className='column_helper_cell'></div>
            </div>
            <div className='table_column' id='price_column'>
                <div className='column_helper_cell'></div>
            </div>
        </div></>
            )
        } else {
        
        return (
            
            <><div className='group_title' id={`${data?.[ind]?.group_id}_group_id`} onClick={openGroup}>
                    <p className='gp_title'>{data?.[ind]?.group_name}</p>
                </div>
                <div className='table_helper_container'>
                <div className='table_column' id='name_column'>
                    {makeCellsName}
                </div>
                <div className='table_column' id='type_column'>
                    {makeCellsOperations}
                </div>
                <div className='table_column' id='group_column'>
                    {makeCellsIncomes}
                </div>
                <div className='table_column' id='price_column'>
                    {makeCellsOutlay}
                </div>
            </div></>
        )}
    })

    const CARDS = Array.from({length: data?.length}, (_, index) => {

        const CARDS_GROUPED = Array.from({length: data?.[index]?.categories?.length}, (_, ind) => {
            return (
                <div className='table_card_grouped' id={`${data?.[index]?.categories?.[ind]?.id}_category_id`} onClick={openDetail} style={+data?.[index]?.categories?.[ind]?.incomes - +data?.[index]?.categories?.[ind]?.expenses > 0 ? {borderLeft: '1.5vw solid #36f12b'} : +data?.[index]?.categories?.[ind]?.incomes - +data?.[index]?.categories?.[ind]?.expenses < 0 ? {borderLeft: '1.5vw solid red'} : {}}>
                    <div className='table_row1_grouped'>
                        <div className='table_row1_type'>
                            <span style={{color: 'white'}}>{data?.[index]?.categories?.[ind]?.name}</span>
                        </div>
                    </div>
                    <div className='table_row2'>
                        <div className='table_row2_debet'>
                            <span style={{fontWeight: '600'}}>Кол-во операций: </span> <span>{data?.[index]?.categories?.[ind]?.transactions_count}</span>
                        </div> 
                    </div>
                    <div className='table_row3'>
                        <div className='table_row3_category'>
                            <span style={{fontWeight: '600'}}>Доходы: </span> <span style={{color: '#36f12b'}}>+{!data?.[index]?.categories?.[ind]?.incomes ? 0 : data?.[index]?.categories?.[ind]?.incomes?.toLocaleString()}₽</span>
                        </div>
                    </div>
                    <div className='table_row4'>
                        <div className='table_row4_comment_'>
                            <span style={{fontWeight: '600'}}>Расходы:&nbsp;</span> <span style={{color: 'red'}}>-{!data?.[index]?.categories?.[ind]?.expenses ? 0 : data?.[index]?.categories?.[ind]?.expenses?.toLocaleString()}₽</span>
                        </div>
                    </div>
                </div>
            )
        })

        if (data?.[index]?.categories?.length === 0) {
            return (
                            <div className='group_box' >
                            <span className='gp_box_title' id={`${data?.[index]?.group_id}_group_id`} onClick={openGroup} style={{fontSize: '5vw', color: 'white', marginLeft: '3vw'}}>{data?.[index]?.group_name}</span>
                            <div className='table_card_grouped'>
                    <div className='table_row1_grouped'>
                        <div className='table_row1_type' style={{color: 'white', width: '40vw'}}>Нет категорий</div>
                    </div>
                    <div className='create_new' id={data?.[index]?.group_name} onClick={makeCategory}>
                        Создать
                    </div>
                </div>
                            <span className='gp_box_comment'>{data?.[index]?.group_comment}</span>
                        </div>
            )
        } else {

        return (
            <div className='group_box'>
            <span className='gp_box_title' id={`${data?.[index]?.group_id}_group_id`} onClick={openGroup} style={{fontSize: '5vw', color: 'white', marginLeft: '3vw'}}>{data?.[index]?.group_name}</span>
            {CARDS_GROUPED}
            <span className='gp_box_comment'>{data?.[index]?.group_comment}</span>
        </div>
        )}
    })

    if (!STORE.readToken()) {
        navigate('/login')
    } else {

    return (
                <>
                <body>
        <div className='helper_container_page' onClick={handleCloseModal}>
                <NavBar />
                <Error errors={!success} />
                <div className='main_helper' id='category_main'>
                    <BreadCrumbs />
                    <MediaQuery minWidth={340} maxWidth={900}>
                    <div className='small_search_box___' style={{marginTop: '20vw'}}>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search' />
                </div>
                    </MediaQuery>
                    <div className='helper_row'>
                        <div className='create_button' id='create_button_helper' style={showModal ? { transform: 'scale(1.1)' } : {}} onClick={handleNewOperation}>
                            Создать
                            <div className='svg_arrow' id='svg' style={showModal ? { transform: 'rotate(90deg)' } : { transform: 'rotate(0)' }}>
                                &gt;
                            </div>
                        </div>
                        <div className='options' style={showModal ? { visibility: 'visible', opacity: '1' } : { visibility: 'hidden', opacity: '0' }}>
                            <div className='optionList' id='make_category' onClick={handleNewOperation}>
                                Категория
                            </div>
                            <div className='optionList' id='make_group' onClick={handleNewOperation}>
                                Группа
                            </div>
                        </div>
                        <MediaQuery minWidth={900}>
                    <div className='small_search_box___'>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search' />
                </div>
                    </MediaQuery>
                    </div>
                    <MediaQuery minWidth={900}>
                    <div className='table_helper_container'>
                        <div className='table_column top_column' id='name_column'>
                            <div className='table_column_title column_helper_cell'>Название</div>
                        </div>
                        <div className='table_column top_column' id='type_column'>
                            <div className='table_column_title column_helper_cell'>Кол-во операций</div>
                        </div>
                        <div className='table_column top_column' id='group_column'>
                            <div className='table_column_title column_helper_cell'>Доходы</div>
                        </div>
                        <div className='table_column top_column' id='price_column'>
                            <div className='table_column_title column_helper_cell'>Расходы</div>
                        </div>
                    </div>
                    {makeGroups}
                    </MediaQuery>
                    <MediaQuery minWidth={340} maxWidth={900}>
                        <div className='card_box' style={{marginTop: '2vw'}}>
                            {CARDS}
                        </div>
                    </MediaQuery>
                </div>
                <div className={(showCreate) ? 'modal_overlay' : 'modal_inactive'} onClick={closeModal}>
                    <NewCategory groupID={groupID} showCreate={showCreate} callback={callBackClose} preselected={preselectedGroup} id={id} status={status} update={updateData} type={type} />
                </div>
            </div></body></>
    )}
}

export default HelperOutlays