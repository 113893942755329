import './Helper.css'
import NavBar from '../components/NavBar.js';
import { useState, useEffect } from 'react'
import { STORE } from '../STORE.js'
import Error from '../components/Error.js'
import BreadCrumbs from '../Logic/BreadCrumbs.js'
import { useNavigate } from 'react-router-dom'
import NewClient from '../components/NewClient.js'
import MediaQuery from 'react-responsive'

const HelperClients = () => {

    const openGroup = (event) => {
        setStatus('edit')
        setType('group')
        setGroupID(event.currentTarget.id?.split('_')[0])
        setShowCreate(true)
    }

    const makeService = (event) => {
        setShowCreate(true)
        setType('service')
        setStatus('create')
        setPreselectedGroup(event.currentTarget.id)
    }

    const handleNew = (event) => {
        if (event.currentTarget.id === 'create_button_helper__') {
            setShowModal(!showModal)
        } else if (event.currentTarget.id === 'make_category') {
            setStatus('create')
            setType('service')
            setShowCreate(true)
        } else if (event.currentTarget.id === 'make_group') {
            setStatus('create')
            setType('group')
            setShowCreate(true)
        }
    }

    const navigate = useNavigate()

    const openClient = (event) => {
        if (event.target.className !== 'status_select') {
        navigate('/directory/clients/detail', { state: event.currentTarget.id?.split('_')[0] })
        sessionStorage.setItem('ID', event.currentTarget.id?.split('_')[0])
    }
    }

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [total, setTotal] = useState()
    const [update, setUpdate] = useState(false)
    const [success, setSuccess] = useState(true)

    const API = 'https://eternity-tracker.ru/api/finances/directory/customers/'

    useEffect(() => {
        setLoading(true)
        fetch(API, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            setData(data?.data?.items)
            setLoading(false)
            setTotal(data?.data?.summary)
            console.log(data?.data, '+++++++++++++')
            if (data.detail === 'Invalid token') {
                navigate('/login')
            }
        })
        .catch(error => console.error(error))
    }, [update])

    const API_STATUS = ''

    const handleStatusChange = (event) => {
        fetch(API_STATUS, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customer_id: +event.target.id.split('_')[0],
                status: event.currentTarget.options[event.currentTarget.selectedIndex].value
            })
        })
        .then(() => {
            setUpdate(!update)
        })
    }

    const CARDS = Array.from({length: data?.length}, (_, index) => {

        const CARDS_GROUPED = Array.from({length: data?.[index]?.customers?.length}, (_, ind) => {
            
            const PRODUCTS = Array.from({length: data?.[index]?.customers?.[ind]?.customer_products?.length}, (_, i) => {
                return (
                    <span style={{marginTop: '3.5vw'}}>{data?.[index]?.customers?.[ind]?.customer_products?.[i]?.name} | {data?.[index]?.customers?.[ind]?.customer_products?.[i]?.price?.toLocaleString()}₽</span>
                )
            })

            const item = data?.[index]?.customers?.[ind]

            return (
                <div className='table_card_grouped' id={`${data?.[index]?.customers?.[ind]?.id}_client_id`} onClick={openClient}>
                    <div className='table_row1_grouped'>
                        <div className='table_row1_type' style={{width: '100%'}}>
                            <span style={{fontWeight: '600', color: 'white'}}>{data?.[index]?.customers?.[ind]?.name}</span>
                        </div>
                    </div>
                    <div className='table_row2'>
                        <div className='table_row2_debet'>
                            <span style={{fontWeight: '600'}}>Получено /мес. </span> <span style={!data?.[index]?.customers?.[ind]?.incomes_current_month_amount ? {color: 'white'} : {color: '#96f16b'}}>{!data?.[index]?.customers?.[ind]?.incomes_current_month_amount ? 0 : `+${data?.[index]?.customers?.[ind]?.incomes_current_month_amount?.toLocaleString()}`}₽</span>
                        </div> 
                    </div>
                    <div className='table_row3'>
                        <div className='table_row3_category'>
                            <span style={{fontWeight: '600'}}>Планируется /мес. </span> <span style={!data?.[index]?.customers?.[ind]?.plan_current_month_amount ? {color: 'white'} : {color: '#8c92ff'}}>{!data?.[index]?.customers?.[ind]?.plan_current_month_amount ? 0 : `+${data?.[index]?.customers?.[ind]?.plan_current_month_amount?.toLocaleString()}`}₽</span>
                        </div>
                    </div>
                    <div className='table_row4'>
                        <div className='table_row4_comment_'>
                            <span style={{fontWeight: '600'}}>Просрочено&nbsp;</span> <span style={{color: '#ff8181'}}>{!data?.[index]?.customers?.[ind]?.expired_current_month_amount ? 0 : data?.[index]?.customers?.[ind]?.expired_current_month_amount?.toLocaleString()}₽</span>
                        </div>
                    </div>
                    <div className='table_row5'>
                    <select className='status_select' onChange={handleStatusChange} style={item?.status === 'active' ? {color: 'rgb(129, 255, 129)'} : item?.status === 'left' ? {color: 'rgb(255, 129, 129)'} : item?.status === 'plan' ? {color: 'rgb(129, 129, 255)'} : {color: 'rgb(150, 150, 150'}} id={`${item?.id}_client_id`}>
                        <option value='active' style={{color: 'rgb(129, 255, 129)'}} selected={item?.status === 'active'}>Активно</option>
                        <option value='left' style={{color: 'rgb(255, 129, 129)'}} selected={item?.status === 'left'}>Ушел</option>
                        <option value='plan' style={{color: 'rgb(129, 129, 255)'}} selected={item?.status === 'plan'}>Планируется</option>
                        <option value='pause' style={{color: 'rgb(150, 150, 150)'}} selected={item?.status === 'pause'}>На паузе</option>
                    </select>
                    </div>
                    <div className='table_row6' style={data?.[index]?.customers?.[ind]?.customer_products?.length >= 1 ? {borderTop: '0.1vw solid rgb(255, 255, 255, 0.15)', width: '90%', paddingBottom: '3.5vw'} : {display: 'none'}}>
                        <div className='table_row6_sum services_' style={{paddingTop: '0', display: 'flex', flexDirection: 'column', height: 'fit-content'}}>
                       {PRODUCTS}
                        </div>
                    </div>
                </div>
            )
        })

        if (data?.[index]?.customers?.length === 0) {
            return (
                            <div className='group_box' >
                            <span className='gp_box_title' id={`${data?.[index]?.group_id}_group_id`} onClick={openGroup} style={{fontSize: '5vw', color: 'white', marginLeft: '3vw'}}>{data?.[index]?.group_name}</span>
                            <div className='table_card_grouped'>
                    <div className='table_row1_grouped'>
                        <div className='table_row1_type' style={{color: 'white', width: '40vw'}}>Нет клиентов</div>
                    </div>
                    <div className='create_new' id={data?.[index]?.group_name} onClick={makeService}>
                        Создать
                    </div>
                </div>
                            <span className='gp_box_comment'>{data?.[index]?.group_comment}</span>
                        </div>
            )
        } else {

        return (
            <div className='group_box'>
            <span className='gp_box_title' id={`${data?.[index]?.group_id}_group_id`} onClick={openGroup} style={{fontSize: '5vw', color: 'white', marginLeft: '3vw'}}>{data?.[index]?.group_name}</span>
            {CARDS_GROUPED}
            <span className='gp_box_comment'>{data?.[index]?.group_comment}</span>
        </div>
        )}
    })

    const Groups = Array.from({length: data?.length}, (_, i) => {

        const makeCellsClient = Array.from({length: data?.[i]?.customers?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' id={`${data?.[i]?.customers?.[index]?.id}_client_id`} onClick={openClient}>{data?.[i]?.customers?.[index]?.name}</div>
            )
        })
    
    
        const makeCellsMonthly = Array.from({length: data?.[i]?.customers?.length}, (_, index) => {
            return (
                <div className='column_helper_cell'  id={`${data?.[i]?.customers?.[index]?.id}_client_id`} style={data?.[i]?.customers?.[index]?.incomes_current_month_amount === null ? {color: 'white'} : {color: '#96f16b'}}  onClick={openClient}>{ data?.[i]?.customers?.[index]?.incomes_current_month_amount === null ? '0' : `+${data?.[i]?.customers?.[index]?.incomes_current_month_amount?.toLocaleString()}`}₽</div>
            )
        })
        const makeCellsServices = Array.from({length: data?.[i]?.customers?.length}, (_, index) => {
    
            const makeAllServices = Array.from({length: data?.[i]?.customers?.[index]?.customer_products?.length}, (_, ind) => {
                return (
                    <span>{data?.[i]?.customers?.[index]?.customer_products?.[ind]?.name} | {data?.[i]?.customers?.[index]?.customer_products?.[ind]?.price?.toLocaleString()}₽</span>
                )
            })
    
            return (
                <div className='column_helper_cell services_'  id={`${data?.[i]?.customers?.[index]?.id}_client_id`}  onClick={openClient}> <span style={data?.[i]?.customers?.[index]?.customer_products?.length > 0 ? {display: 'flex'} : {display: 'none'}}>{data?.[i]?.customers?.[index]?.customer_products?.[0]?.name} | {data?.[i]?.customers?.[index]?.customer_products?.[0]?.price?.toLocaleString()}₽</span> <span style={data?.[i]?.customers?.[index]?.customer_products?.length > 1 && data?.[i]?.customers?.[index]?.customer_products?.[1]?.name?.length < 30 ? {display: 'flex'} : {display: 'none'}}>{data?.[i]?.customers?.[index]?.customer_products?.[1]?.name} | {data?.[i]?.customers?.[index]?.customer_products?.[1]?.price?.toLocaleString()}₽</span> <span style={data?.[i]?.customers?.[index]?.customer_products?.length > 2 ? {display: 'flex'} : {display: 'none'}}>{data?.[i]?.customers?.[index]?.customer_products?.length > 2 ? '...' : ''}</span>
                    <div className={data?.[i]?.customers?.[index]?.customer_products?.length > 0 ? 'tooltip_services' : null}>{makeAllServices}</div>
                </div>
            )
        })
    
        const makeCellsPlaned = Array.from({length: data?.[i]?.customers?.length}, (_, index) => {
            return (
                <div className='column_helper_cell'  id={`${data?.[i]?.customers?.[index]?.id}_client_id`}  onClick={openClient} style={data?.[i]?.customers?.[index]?.plan_current_month_amount === null ? {color: 'white'} : {color: '#8c92ff'}}>{ data?.[i]?.customers?.[index]?.plan_current_month_amount === null ? '0' : `+${data?.[i]?.customers?.[index]?.plan_current_month_amount?.toLocaleString()}`}₽</div>
            )
        })
    
        const makeCellsExpired = Array.from({length: data?.[i]?.customers?.length}, (_, index) => {
            return (
                <div className='column_helper_cell'  id={`${data?.[i]?.customers?.[index]?.id}_client_id`} onClick={openClient} style={data?.[i]?.customers?.[index]?.expired_current_month_amount === null ? {color: 'white'} : {color: '#ff8181'}}>{ data?.[i]?.customers?.[index]?.expired_current_month_amount === null ? '0' : `${data?.[i]?.customers?.[index]?.expired_current_month_amount?.toLocaleString()}`}₽</div>
            )
        })

        const makeCellsStatus = data?.[i]?.customers?.map(item => {
            return (
                <div className='column_helper_cell'>
                    <select className='status_select' onChange={handleStatusChange} style={item?.status === 'active' ? {color: 'rgb(129, 255, 129)'} : item?.status === 'left' ? {color: 'rgb(255, 129, 129)'} : item?.status === 'plan' ? {color: 'rgb(129, 129, 255)'} : {color: 'rgb(150, 150, 150'}} id={`${item?.id}_client_id`}>
                        <option value='active' style={{color: 'rgb(129, 255, 129)'}} selected={item?.status === 'active'}>Активно</option>
                        <option value='left' style={{color: 'rgb(255, 129, 129)'}} selected={item?.status === 'left'}>Ушел</option>
                        <option value='plan' style={{color: 'rgb(129, 129, 255)'}} selected={item?.status === 'plan'}>Планируется</option>
                        <option value='pause' style={{color: 'rgb(150, 150, 150)'}} selected={item?.status === 'pause'}>На паузе</option>
                    </select>
                </div>
            )
        })

        if (data?.[i]?.customers?.length === 0) {
            return (
                <><div className='group_title' id={`${data?.[i]?.group_id}_group_id`}  onClick={openGroup}>
                <div className='gp_title'>{data?.[i]?.group_name}</div>
            </div><div className='table_helper_container'>
                    <div className='table_column' id='name_column'>
                        <div className='column_helper_cell'></div>
                    </div>
                    <div className='table_column' id='group_column'>
                        <div className='column_helper_cell'></div>
                    </div>
                    <div className='table_column' id='type_column'>
                    <div className='column_helper_cell' id='make_new'> Нет услуг
                    <div className='create_tooltip' id={data?.[i]?.group_name} style={{color: 'white'}} onClick={makeService}>Создать клиента</div>
                    </div>
                    </div>
                    <div className='table_column' id='price_column'>
                    <div className='column_helper_cell'></div>
                    </div>
                    <div className='table_column' id='comment_column'>
                    <div className='column_helper_cell'></div>
                    </div>
                </div></>
            )
        } else {
        
        return (
            <>
            <div className='group_title' id={`${data?.[i]?.group_id}_group_id`}  onClick={openGroup}>
                <div className='gp_title'>{data?.[i]?.group_name}</div>
            </div>
            <div className='table_helper_container'>
                <div className='table_column' id='client__column'>
                    {makeCellsClient}
                </div>
                <div className='table_column' id='services_column'>
                    {makeCellsServices}
                </div>
                <div className='table_column'>
                    {makeCellsStatus}
                </div>
                <div className='table_column' id='month_column'>
                    {makeCellsMonthly}
                </div>
                <div className='table_column' id='month_column'>
                    {makeCellsPlaned}
                </div>
                <div className='table_column' id='month_column'>
                    {makeCellsExpired}
                </div>
            </div></>
        )}
    })


    const [showModal, setShowModal] = useState(false)
    const [showCreate, setShowCreate] = useState(false)
    

    useEffect(() => {

        if (showCreate) {
            document.body.style.overflowY = "hidden";
        }

        if (!showCreate) {
            document.body.style.overflowY = "visible"
        };
    }, [showCreate]);

    const closeModal = (event) => {
        if (event.target.className === 'modal_overlay') {
            setShowCreate(false)
            setStatus()
            setType()
        }
    }

    const handleCloseModal = (event) => {
        if (event.target.id !== 'create_button_helper__' && event.target.id !== 'svg') {
            setShowModal(false)
        } else if (event.target.id === 'create_button_helper__' || event.target.id === 'svg') {
            setShowModal(!showModal)
        }
    }

    const [groupID, setGroupID] = useState()
    const [preselectedGroup, setPreselectedGroup] = useState()
    const [id, setID] = useState()
    const [status, setStatus] = useState('create')
    const updateData = () => {
        setUpdate(!update)
        setShowCreate(!showCreate)
    }
    const [type, setType] = useState('service')

    if (!STORE.readToken()) {
        navigate('/login')
    } else {

    return (
        <body><div className='helper_container_page' onClick={handleCloseModal}>
                                                                    <Error errors={!success}/>
        <NavBar />
        <div className='main_helper' id='clients_helper_'>
<MediaQuery minWidth={900}>      
<div className='statistic_row' id='statistics'>
        <div className='statistic_cell'>Всего клиентов: {total?.customers_count}</div>
        <div className='statistic_cell'>Активные услуги: {total?.active_products}</div>
    </div>
    </MediaQuery>
    <MediaQuery minWidth={340} maxWidth={900}>
    <div className='statistic_row' id='statistics' style={{marginTop: '13.9vw'}}>
        <div className='statistic_cell' style={{width: '49.4vw'}}>Всего клиентов: {total?.customers_count}</div>
        <div className='statistic_cell' style={{width: '49.4vw'}}>Активные услуги: {total?.active_products}</div>
    </div>
    </MediaQuery>
            <BreadCrumbs />
                <MediaQuery minWidth={340} maxWidth={900}>
                    <div className='small_search_box__'>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search' />
                </div>
                    </MediaQuery>
            <div className='helper_row'>
            <div className='create_button' id='create_button_helper__' style={showModal ? {transform: 'scale(1.1)'} : {}} onClick={handleNew}>
                    Создать
                    <div className='svg_arrow' id='svg' style={showModal ? {transform: 'rotate(90deg)'} : {transform: 'rotate(0)'}}>
                        &gt;
                    </div>
                </div>
                <MediaQuery minWidth={900}>
                <div className='options_clients' style={showModal ? {visibility: 'visible', opacity: '1'} : {visibility: 'hidden', opacity: '0'}}>
                    <div className='optionList' id='make_category' onClick={handleNew}>
                        Клиент
                    </div>
                    <div className='optionList' id='make_group' onClick={handleNew}>
                        Группа
                    </div>
                </div>
                </MediaQuery>
                <MediaQuery minWidth={340} maxWidth={900}>
                <div className='options__' style={showModal ? {visibility: 'visible', opacity: '1', top: '21.5vw'} : {visibility: 'hidden', opacity: '0', top: '21.8vw'}}>
                    <div className='optionList' id='make_category' onClick={handleNew}>
                        Клиент
                    </div>
                    <div className='optionList' id='make_group' onClick={handleNew}>
                        Группа
                    </div>
                </div>
                </MediaQuery>
                <MediaQuery minWidth={900}>
                <div className='small_search_box' id='search_helper'>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search_helper' />
                </div>
                </MediaQuery>
            </div>
            <MediaQuery minWidth={900}>
            <div className='table_helper_container'>
                <div className='table_column' id='client__column'>
                    <div className='table_column_title top_column column_helper_cell'>Клиент</div>
                </div>
                <div className='table_column' id='services_column'>
                    <div className='table_column_title top_column column_helper_cell'>Услуги</div>
                </div>
                <div className='table_column' >
                    <div className='table_column_title top_column column_helper_cell'>Статус</div>
                </div>
                <div className='table_column' id='month_column'>
                    <div className='table_column_title top_column column_helper_cell'>Получено /мес.</div>
                </div>
                <div className='table_column' id='month_column'>
                    <div className='table_column_title top_column column_helper_cell'>Планируется /мес.</div>
                </div>
                <div className='table_column' id='month_column'>
                    <div className='table_column_title top_column column_helper_cell'>Просрочено</div>
                </div>
            </div>
                {Groups}
            </MediaQuery>
            <MediaQuery minWidth={340} maxWidth={900}>
            <div className='card_box' style={{marginTop: '2vw', marginLeft: '0'}}>
                            {CARDS}
                        </div>
            </MediaQuery>
        </div>
    </div>
    <div className={(showCreate) ? 'modal_overlay' : 'modal_inactive'} onClick={closeModal}>
            <NewClient groupID={groupID} showCreate={showCreate} preselected={preselectedGroup} id={id} status={status} update={updateData} type={type}/>
        </div>
    </body>
    )
}}

export default HelperClients