import { useState, useEffect } from 'react'
import './NewOperation.css'
import { STORE } from '../STORE.js'
import CurrencyInput from 'react-currency-input-field'
import { makeDayISO, makeMonthISO, makeYearISO } from '../Logic/DateFormating.js'
import Error from '../components/Error.js'
import MediaQuery from 'react-responsive'

const NewEmploye = (props) => {

    const EMPLOYE = 'https://eternity-tracker.ru/api/finances/directory/employees/create/'
    const DEPARTMENT = 'https://eternity-tracker.ru/api/finances/directory/departments/create/'

    const EMPLOYE_EDIT = 'https://eternity-tracker.ru/api/finances/directory/employees/edit/'
    const DEPARTMENT_EDIT = 'https://eternity-tracker.ru/api/finances/directory/departments/edit/'

    const EMPLOYE_DELETE = 'https://eternity-tracker.ru/api/finances/directory/employees/delete/'
    const DEPARTMENT_DELETE = 'https://eternity-tracker.ru/api/finances/directory/departments/delete/'
    
    const [name, setName] = useState()
    const [lastName, setLastName] = useState()
    const [position, setPosition] = useState()
    const [department, setDepartment] = useState()
    const [comment, setComment] = useState()
    const [update,  setUpdate] = useState(false)
    const [groups, setGroups] = useState()
    const [groupName, setGroupName] = useState()
    const [groupComment, setGroupComment] = useState()
    const [loading, setLoading] = useState(false)
    const [dataDetail, setDataDetail] = useState()
    const [errors, setErrors] = useState()
    const [success, setSuccess] = useState(true)

    const labels = [
        {
            label: 'Имя',
            value: name,
        },
        {
            label: 'Фамилия',
            value: lastName,
        },
        {
            label: 'Должность',
            value: position,
        },
        {
            label: 'Отдел',
            value: department,
        },
        {
            label: 'Комментарий',
            value: comment,
        },
    ]

    const departments = [
        {
            label: 'Название',
            value: groupName,
        },
        {
            label: 'Комментарий',
            value: groupComment,
        }
    ]

    const deleteCategory = () => {
        if (props.type === 'employe') {
            fetch(EMPLOYE_DELETE, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    employee_id: props.id,               
                })
            })
            .then(() => {
                props.update()
            })
        }
        if (props.type === 'group') {
            fetch(DEPARTMENT_DELETE, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    department_id: props.groupID,               
                })
            })
            .then(() => {
                props.update()
            })
        }
    }

    const handleModal = () => {
        props.update()
        setName('')
        setLastName('')
        setPosition('')

        setComment('')
    }

    const handleValueEmploye = (event) => {
        switch (+event.target.className + 1) {
            case 1: 
                setName(event.target.value)
                break
            case 2: 
                setLastName(event.target.value)
                break
            case 3: 
                setPosition(event.target.value)
                break 
            case 4: 
                setDepartment(event.target.value)
                break 
            case 5: 
                setComment(event.target.value)
                break 
            default: 
                alert('Произошла ошибка')
        }
    }

    const handleValueGroup = (event) => {
        switch (+event.target.className + 1) {
            case 1: 
                setGroupName(event.target.value)
                break
            case 2: 
                setGroupComment(event.target.value)
                break
            default: 
                alert('Произошла ошибка')
        }
    }

    useEffect(() => {
        if (props.status === 'create' && props.type === 'group') {
            setGroupName('')
            setGroupComment('')
            setDepartmentErrors(null)
        }
        if (props.status === 'create' && props.type === 'employe') {
            setName('')
            setLastName('')
            setPosition('')
            setComment('')
            setDepartment(groups?.[0]?.id)
            setEmployeErrors(null)
        }
    }, [props.status, props.type])

    useEffect(() => {

        const APIdetail = `https://eternity-tracker.ru/api/finances/directory/employees/detail/${props.id}`        
            if (props.status === 'edit' && props.type === 'employe') {
                setLoading(true)
        fetch(APIdetail, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false)
            if (props.status === 'edit' && props.type === 'employe') {
                setName(data?.data?.name)
                setLastName(data?.data?.last_name)
                setDepartment(data?.data?.department?.id)
                setPosition(data?.data?.position)
                setComment(data?.data?.comment)
                setDataDetail(data?.data)
        }
        })
        .catch(error => console.error(error))
    }
    }, [props.type, props.status, props.id])

    useEffect(() => {
        const APIdetailGroup = `https://eternity-tracker.ru/api/finances/directory/departments/detail/${props.groupID}`        
        if (props.status === 'edit' && props.type === 'group') {
            setLoading(true)
        fetch(APIdetailGroup, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false)
            if (props.status === 'edit' && props.type === 'group') {
                setGroupName(data?.data?.name)
                setGroupComment(data?.data?.comment === null ? '' : data?.data?.comment)
                setDataDetail(data?.data)
        }
        })
        .catch(error => console.error(error))
    }
    }, [props.type, props.status, props.groupID])

    const APIgroups = 'https://eternity-tracker.ru/api/finances/directory/departments/'
    useEffect(() => {
        fetch(APIgroups, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setGroups(data?.data)
        })
        .catch(error => console.error(error))
    }, [update, props.type, props.status])

    useEffect(() => {
        if (props?.preselected !== undefined) {
            console.log(props.preselected)
        let correctID = groups?.find(item => item.name === props.preselected)
        console.log(correctID, '_____________')
        setDepartment(correctID?.id)
        }
    }, [props.preselected])

    const [employeErrors, setEmployeErrors] = useState([])
    const [departmentErrors, setDepartmentErrors] = useState([])

    const handleSubmit = () => {

        if (props.type === 'employe') {

            let required = [{name: name, error: false}, {name: lastName, error: false}, {name: position, error: false}, {name: 'not', error: false}, {name: comment, error: false} ]
            
            required = required.map((item, index) => {
                if (!item.name) {
                    return (
                        {
                            name: item.name,
                            error: true,
                            message: 'Это поле не может быть пустым'
                        }
                    )
                } else {
                    return (
                        {
                            name: item.name,
                            error: false,
                            message: null
                        }
                    )
                }
            })
            setEmployeErrors(required)
            let validation = false

            for (let elem of required) {
                if (elem.error) {
                    validation = true
                }
            }

            if (validation) return

            fetch(props.status === 'create' ? EMPLOYE : EMPLOYE_EDIT, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: props.status === 'create' ?
                JSON.stringify({
                    name: name,
                    last_name: lastName,
                    position: position,
                    department_id: !department ? groups?.[0]?.id : department,
                    comment: comment,
                }) :
                JSON.stringify({
                    employee_id: props.id,
                    edit_data: {
                        name: name,
                        last_name: lastName,
                        position: position,
                        department_id: !department ? groups?.[0]?.id : department,
                        comment: comment,
                    }
                })
            })
            .then(response => response.json())
            .then(data => {
                setName('')
                setLastName('')
                setPosition('')

                setComment('')
                setGroupComment('')
                setGroupName('')
                setErrors(data?.errors)
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                sessionStorage.setItem('notification', data?.notification === undefined ? props.status === 'create' ? 'Сотрудник был успешно создан' : 'Сотрудник был успешно изменен' : data?.notification)
                sessionStorage.setItem('type', 'create_operation')
                props.update()
                }
            })
        } else {

            let required = [{name: groupName, error: false}, {name: groupComment, error: false} ]
            
            required = required.map((item, index) => {
                if (!item.name) {
                    return (
                        {
                            name: item.name,
                            error: true,
                            message: 'Это поле не может быть пустым'
                        }
                    )
                } else {
                    return (
                        {
                            name: item.name,
                            error: false,
                            message: null
                        }
                    )
                }
            })
            setDepartmentErrors(required)
            let validation = false

            for (let elem of required) {
                if (elem.error) {
                    validation = true
                }
            }

            if (validation) return

            fetch(props.status === 'create' ? DEPARTMENT : DEPARTMENT_EDIT, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: props.status === 'create' ? 
                JSON.stringify({
                    name: groupName,
                    comment: groupComment,
                }) :
                JSON.stringify({
                    department_id: props.groupID,
                    edit_data: {
                        name: groupName,
                        comment: groupComment,
                    }
                })
            })
            .then(response => response.json())
            .then(data => {
                props.update()
                setName('')
                setLastName('')
                setPosition('')

                setComment('')
                setGroupComment('')
                setGroupName('')
                setErrors(data?.errors)
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                sessionStorage.setItem('notification', data?.notification === undefined ? props.status === 'create' ? 'Отдел был успешно создан' : 'Отдел был успешно изменен' : data?.notification)
                sessionStorage.setItem('type', 'create_operation')
                props.update()
                }
            })
        }
    }
    
    
    if (loading) {
        return (
            <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
<circle
class="active"
pathLength="360"
fill="transparent"
stroke-width="32"
cx="192"
cy="192"
r="176"
></circle>
<circle
class="track"
pathLength="360"
fill="transparent"
stroke-width="32"
cx="192"
cy="192"
r="176"
></circle>
</svg>
</div>
        )
    } else {

    return (
        <div className='new_operation_container' style={{height: 'fit-content'}}>
            <Error errors={!success} message={errors} />
            <div className='row1'>{props.type === 'employe' ? (props?.status === 'create' ? 'Создание сотрудника' : 'Редактирование сотрудника') : props.type === 'group' ? (props.status === 'create' ? 'Создание отдела' : 'Редактирование отдела') : ''}</div>
                {props.type === 'employe' ? labels.map((item, index) => {
                    return (
                        <div className={index === 0 ? 'data_row modal_row' : item.label === 'Комментарий' ? 'comment_row_ modal_row' : 'modal_row'}>
                            <span className='input_label_' id={index === 0 && employeErrors?.[0]?.error ? 'span_item_name' : ''}>{item.label}</span>
                            <input type='text' style={item.label === 'Отдел' ? {display: 'none'} : employeErrors?.[index]?.error ? {border: '0.1vw solid rgb(255, 50, 50)'} : {display: 'flex'}} className={index} onChange={handleValueEmploye} value={item.value} id={item.label === 'Комментарий' ? 'comment_input___' : 'name_input'} />
                            <label htmlFor={index} className='error_message_'>{employeErrors?.[index]?.message}</label>
                            <select onChange={handleValueEmploye} className={index} value={item.value} style={item.label !== 'Отдел' ? {display: 'none'} : {display: 'flex'}} id='category_input_2'>
                                {groups?.map((item, i) => {
                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    )
                }) : 
                    departments.map((item, index) => {
                        return (
                            <div className={index === 0 ? 'data_row modal_row' : item.label === 'Комментарий' ? 'comment_row_ modal_row' : 'modal_row'}>
                                <span className='input_label_'>{item.label}</span>
                                <input type='text' className={index} onChange={handleValueGroup} style={departmentErrors?.[index]?.error ? {border: '0.1vw solid rgb(255, 50, 50)'} : {}} value={item.value} id={item.label === 'Комментарий' ? 'comment_input___' : 'name_input'} />
                                <label htmlFor={index} className='error_message_'>{departmentErrors?.[index]?.message}</label>
                            </div>
                        )
                    })
                }
                    <div className='footer_row'>
        <div className='delete_button' style={props.status === 'edit' ? {display: 'flex'} : {display: 'none'}} onClick={deleteCategory} >Удалить</div>
            <div className='cancel' onClick={handleModal}>
                Отменить
            </div>
            <div className='create' id='create_income' onClick={handleSubmit}>
                {props.status === 'edit' ? 'Сохранить' : 'Создать'}
            </div>
        </div>
        </div>
    )}
}

export default NewEmploye