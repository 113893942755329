import './Table.css'
import NewOperation from './NewOperation.js'
import { useState, useEffect } from 'react'
import { makeDay, makeHours, makeMonth, makeYear, makeDayISO, makeMonthISO, makeYearISO } from '../Logic/DateFormating.js'
import Error from '../components/Error.js'
import BreadCrumbs from '../Logic/BreadCrumbs.js'
import MergeOperations from '../components/MergeOperations.js'
import MediaQuery from 'react-responsive'

const Table = (propsSet) => {

    const [props, setProps] = useState({
        data: propsSet?.data?.data?.items,
        navigate: propsSet?.data?.data?.navigate,
    })

    const [status, setStatus] = useState()
    const [id, setId] = useState()
    const [updating, setUpdating] = useState(false)
    const [success, setSuccess] = useState(true)
    const [operations, setOperations] = useState([])
    const [operationSet, setOperationSet] = useState()
    const [operationList, setOperationList] = useState()

    const openOperation = (event) => {
        console.log(event.target)
        if (event.target.id.split('_')[0] === 'checkbox' || event.target.className === 'mark' || event.target.className === 'mark2' || event.target.className === 'tooltip_invoice' || event.target.className === 'tooltip_expired') {

        } else {
        setShowModal(!showModal)
        setId(event.currentTarget.id.split('_')[0])
        setStatus('edit')
        setUpdating(!updating)
        }
        console.log(event.currentTarget)
    }

    const handleJoin = (event) => {
        if (event?.currentTarget?.id?.split('_')?.[0] === 'checkbox') {
            if (operations.includes(event.currentTarget.id.split('_')[2])) {
                let array = [...operations]
                let toRemove = event.currentTarget.id.split('_')[2]
                let index = array.indexOf(toRemove)

                if (index !== -1) {
                    array.splice(index, 1)
                    let arr = []
                    for (let str of array) {
                        if (!arr.includes(str)) {
                            arr.push(str)
                        }
                    }
                    setOperations(arr)
                }
            } else {

            let array = [...operations, event.currentTarget.id.split('_')[2]]
            let arr = []
            for (let str of array) {
                if (!arr.includes(str)) {
                    arr.push(str)
                }
            }
            setOperations(arr)
        }
        }
    }

    const makeCellsCheckbox = Array.from({length: props?.data?.length}, (_, index) => {
        return (
            <div className='column_helper_cell' key={index} id={`${props?.data?.[index]?.id}_id`} style={props?.data[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}}>
            <div class="checkbox-wrapper-46" style={{marginLeft: '0.5vw'}} id={`checkbox_be_${props?.data?.[index]?.id}`}>
            <input type="checkbox" id={`checkbox_id_${props?.data?.[index]?.id}`} class="inp-cbx"  onClick={handleJoin} />
            <label for={`checkbox_id_${props?.data?.[index]?.id}`} class="cbx">
                <span>
                    <svg viewBox="0 0 12 10" height="10px" width="12px" id={`checkbox_id_${props?.data?.[index]?.id}`}>
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </svg>
                </span>
                <span className='check1' id='label_10'>
                    
                </span>
            </label>
</div>
</div>
        )
    })

    const makeCellsId = Array.from({length: props?.data?.length}, (_, index) => {
        let comment = props?.data?.[index]?.description
        if (comment === null || comment === undefined) {
            comment = '—'
        } else {
            if (comment.length > 40) {
                comment = comment.split('')
                comment = comment.slice(0, 40)
                comment = comment.join('')
                comment = comment + '...'
            }
        }

        return (
            <div className='column_helper_cell' key={index} id={`${props?.data?.[index]?.id}_id`} style={props?.data[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={openOperation}>
                <span>{comment}</span>
            </div>
        )
    })

    const makeCellsDate = Array.from({length: props?.data?.length}, (_, index) => {

        let formedDate = []
        if (props?.data[index].date === null) {
            formedDate = '...'
        } else {
            for (let i = 5; i < 10; i++) {
                formedDate.push(props?.data[index].date[i])
            }
    
            for (let i = 10; i < 11; i++) {
                formedDate.push(' ')
            }
    
            for (let i = 11; i < 16; i++) {
                formedDate.push(props?.data[index].date[i])
            }
        }

        const Date = {
            day: makeDay(formedDate),
            hours: makeHours(formedDate),
            month: makeMonth(formedDate),
            year: makeYear(props?.data[index].date)
        }


        return (
            <div className='column_helper_cell' key={index} id={`${props?.data?.[index]?.id}_date`}style={props?.data[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={openOperation}>
                {(props?.data[index].date === undefined) ? '—' : `${Date.day} ${Date.month} ${Date.year}`}

            </div>
        )
    })

    const makeCellsType = Array.from({length: props?.data?.length}, (_, index) => {
        return (
            <div className='column_helper_cell' key={index} id={`${props?.data?.[index]?.id}_type`}style={props?.data[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={openOperation}>
                <span style={(props?.data[index].type === 'expense') ? {color: '#ff8181'} : (props?.data[index].type === 'income') ? {color: '#96f16b'} : {color: 'yellow'}}>{props?.data?.[index]?.subtype === 'unification_payment' ? 'Объединенный платеж' : props?.data[index]?.product_payment ? 'Платеж по услуге' : props?.data[index].type === 'expense' ? props?.data?.[index]?.subtype === 'salary' ? 'Зарплата' : 'Расход' : (props?.data[index].type === 'income') ? 'Доход' : 'Перемещение'}</span>
            </div>
        )
    })

    const makeCellsCategory = Array.from({length: props?.data?.length}, (_, index) => {
        return (
            <div className='column_helper_cell' key={index} id={`${props?.data?.[index]?.id}_category`}style={props?.data[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={openOperation}>
                    {(props?.data[index].category === undefined) ? '—' : props?.data[index].category}
            </div>
        )
    })

    const makeCellsDebet1 = Array.from({length: props?.data?.length}, (_, index) => {
        return (
            <div className='column_helper_cell' key={index} id={`${props?.data?.[index]?.id}_debet1`}style={props?.data[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={openOperation}>
                {(props?.data[index].debet1 === undefined) ? '—' : props?.data[index]?.debet1}
            </div>
        )
    })

    const makeCellsDebet2 = Array.from({length: props?.data?.length}, (_, index) => {
        return (
            <div className='column_helper_cell' key={index} id={`${props?.data?.[index]?.id}_debet2`}style={props?.data[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={openOperation}>
                {(props?.data[index].debet2 === undefined) ? '—' : props?.data[index]?.debet2}
            </div>
        )
    })

    const makeCellsSum = Array.from({length: props?.data?.length}, (_, index) => {
        return (
            <div className='column_helper_cell' key={index} id={`${props?.data?.[index]?.id}_sum`}style={props?.data[index]?.status === 'plan' ? {color: '#8c92ff'} :  props?.data[index]?.type === 'expense' || props?.data?.[index]?.status === 'expired' ? {color: '#ff8181'} : props?.data[index]?.type === 'income' ? {color: '#96f16b'} : {color: 'white'}} onClick={openOperation}>
            <span className='sum_cell'>{props?.data[index]?.type === 'expense' ? '-' : props?.data[index]?.type === 'income' ? '+' : ''}{props?.data[index].cost.toLocaleString()}р.</span>
            <div className='mark2' style={props?.data[index]?.invoice ? {display: 'block'} : {display: 'none'}}></div>
            <div className='tooltip_invoice'>
                Инвойс прикреплен
            </div>
                <svg className='mark' style={props?.data[index]?.status === 'expired' ? {display: 'block'} : {display: 'none'}} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlSpace="preserve" fill="#000000">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <g> <path style={{fill:'#ff0000'}} d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M12,19.66 c-0.938,0-1.58-0.723-1.58-1.66c0-0.964,0.669-1.66,1.58-1.66c0.963,0,1.58,0.696,1.58,1.66C13.58,18.938,12.963,19.66,12,19.66z M12.622,13.321c-0.239,0.815-0.992,0.829-1.243,0c-0.289-0.956-1.316-4.585-1.316-6.942c0-3.11,3.891-3.125,3.891,0 C13.953,8.75,12.871,12.473,12.622,13.321z"/> </g> </g>

</svg>
            <div className='tooltip_expired'>
                Платеж просрочен
            </div>

            </div>
        )
    })
/////////////////////////////////////////////// ANCHOR123

    const CARDS = Array.from({length: props?.data?.length}, (_, index) => {

        let formedDate = []
        if (props?.data[index].date === null) {
            formedDate = '...'
        } else {
            for (let i = 5; i < 10; i++) {
                formedDate.push(props?.data[index].date[i])
            }
    
            for (let i = 10; i < 11; i++) {
                formedDate.push(' ')
            }
    
            for (let i = 11; i < 16; i++) {
                formedDate.push(props?.data[index].date[i])
            }
        }

        const Date = {
            day: makeDay(formedDate),
            hours: makeHours(formedDate),
            month: makeMonth(formedDate),
            year: makeYear(props?.data[index].date)
        }

        return (
            <div className='table_card' id={`${props?.data?.[index]?.id}_id`}  onClick={openOperation} style={props?.data?.[index]?.status === 'plan' ? {borderLeft: '1.5vw solid #8c90ff'} : props?.data?.[index]?.type === 'expense' || props?.data?.[index]?.status === 'expired' ? {borderLeft: '1.5vw solid red'} : props?.data?.[index]?.type === 'income' ? {borderLeft: '1.5vw solid #36f12b'} : props?.data?.[index]?.type === 'transfer' ? {borderLeft: '1.5vw solid yellow'} : {borderLeft: 'none'}}>
                <div className='table_row1'>
                    <div className='table_row1_type' id={`${props?.data?.[index]?.id}_type`}style={props?.data[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={openOperation}>
                        <span style={(props?.data[index].type === 'expense') ? {color: '#ff8181'} : (props?.data[index].type === 'income') ? {color: '#96f16b'} : {color: 'yellow'}}>{props?.data?.[index]?.subtype === 'unification_payment' ? 'Объединенный платеж' : props?.data[index]?.product_payment ? 'Платеж по услуге' : props?.data[index].type === 'expense' ? props?.data?.[index]?.subtype === 'salary' ? 'Зарплата' : 'Расход' : (props?.data[index].type === 'income') ? 'Доход' : 'Перемещение'}</span>
                    </div>
                    <div className='table_row1_date'>
                        {Date.day} {Date.month} {Date.year}
                    </div>
                    <div className='table_row1_checkbox'>
                    <div class="checkbox-wrapper-46" style={{marginLeft: '0.5vw'}} id={`checkbox_be_${props?.data?.[index]?.id}`}>
            <input type="checkbox" id={`checkbox_id_${props?.data?.[index]?.id}`} class="inp-cbx"  onClick={handleJoin} />
            <label for={`checkbox_id_${props?.data?.[index]?.id}`} class="cbx">
                <span>
                    <svg viewBox="0 0 12 10" height="10px" width="12px" id={`checkbox_id_${props?.data?.[index]?.id}`}>
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </svg>
                </span>
                <span className='check1' id='label_10'>
                    
                </span>
            </label>
</div>
                    </div>

                

                </div>
                <div className='table_row2'>
                    <div className='table_row2_debet'>
                        <span style={{fontWeight: '600'}}>Счет: </span> <span>{!props?.data?.[index]?.debet1 ? '—' : props?.data?.[index]?.debet1}</span>
                    </div>
                    <div className='table_row2_debet' style={!props?.data?.[index]?.debet2 ? {display: 'none'} : {display: 'flex'}}>
                        <span style={{fontWeight: '600'}}>Счет 2:&nbsp;</span> <span>{!props?.data?.[index]?.debet2 ? '—' : props?.data?.[index]?.debet2}</span>
                    </div>
                </div>
                <div className='table_row3'>
                    <div className='table_row3_category'>
                        <span style={{fontWeight: '600'}}>Категория: </span> <span>{!props?.data?.[index]?.category ? '—' : props?.data?.[index]?.category}</span>
                    </div>
                </div>
                <div className='table_row4'>
                    <div className='table_row4_comment_'>
                        <span style={{fontWeight: '600'}}>Назначение платежа:&nbsp;</span> <span>{!props?.data?.[index]?.description ? '—' : props?.data?.[index]?.description}</span>
                    </div>
                </div>
                <div className='table_row5'>
                    <div className='table_row5_sum' id={`${props?.data?.[index]?.id}_sum`}style={props?.data[index]?.status === 'plan' ? {color: '#8c92ff'} :  props?.data[index]?.type === 'expense' || props?.data?.[index]?.status === 'expired' ? {color: '#ff8181'} : props?.data[index]?.type === 'income' ? {color: '#96f16b'} : {color: 'white'}} onClick={openOperation}>
                   <span style={{color: 'white', fontWeight: '600'}}>Сумма: </span>
            <span className='sum_cell'>{props?.data[index]?.type === 'expense' ? '-' : props?.data[index]?.type === 'income' ? '+' : ''}{props?.data[index].cost.toLocaleString()}р.</span>
            <div className='mark2' style={props?.data[index]?.invoice && props?.data?.[index]?.status === 'expired' ? {display: 'block', marginRight: '-17vw'} : props?.data?.[index]?.invoice ? {display: 'flex'} : {display: 'none'}}></div>
            <div className='tooltip_invoice'>
                Инвойс прикреплен
            </div>
                <svg className='mark' style={props?.data[index]?.status === 'expired' ? {display: 'block'} : {display: 'none'}} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlSpace="preserve" fill="#000000">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <g> <path style={{fill:'#ff0000'}} d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M12,19.66 c-0.938,0-1.58-0.723-1.58-1.66c0-0.964,0.669-1.66,1.58-1.66c0.963,0,1.58,0.696,1.58,1.66C13.58,18.938,12.963,19.66,12,19.66z M12.622,13.321c-0.239,0.815-0.992,0.829-1.243,0c-0.289-0.956-1.316-4.585-1.316-6.942c0-3.11,3.891-3.125,3.891,0 C13.953,8.75,12.871,12.473,12.622,13.321z"/> </g> </g>

</svg>
            <div className='tooltip_expired'>
                Платеж просрочен
            </div>

            </div>
                </div>
            </div>
        )
    })

    const [showModal, setShowModal] = useState(false)
    const [updateTable, setUpdateTable] = useState(false)
    const [showMerge, setShowMerge] = useState(false)
    const [showFilters, setShowFilters] = useState(false)

    const handleNewOperation = () => {
        setUpdateTable(!updateTable)
        setShowModal(!showModal)
        setStatus('create')
    }

    const updateData = (value) => {
        setShowModal(value)
        setUpdateTable(!updateTable)
        propsSet?.update()
    }

    const closeModal = (event) => {
        if (event.target.className === 'modal_overlay') {
            setShowModal(false)
        }
    }

    const closeMerge = (event) => {
        if (event.target.className === 'modal_overlay') {
            setShowMerge(false)
        }
    }

    const handleSort = (event) => {
        if (event.currentTarget.innerHTML.split(' ')[0] === 'Дата') {
            propsSet?.updateSort('date')
        }
        if (event.currentTarget.innerHTML.split(' ')[0] === 'Сумма') {
            propsSet?.updateSort('cost')
        }
    }

    const updateMerge = () => {
        setShowMerge(false)
        setOperations([])
        propsSet?.update()
    }

    const preventMerge = (value) => {
        setShowMerge(false)
    }

    useEffect(() => {
        console.log('overflow')
        if (showModal || showMerge || showFilters) {
            document.body.style.overflowY = "hidden";
        }

        if (!showModal && !showMerge && !showFilters) {
            document.body.style.overflowY = "visible"
        };
    }, [showModal, showMerge, showFilters]);

    const [dateGroup, setDateGroup] = useState(false)
    const [grouping, setGrouping] = useState()

    const handleGroup = (event) => {
        localStorage.setItem('grouping', event.target.value)
        setGrouping(localStorage.getItem('grouping'))
        if (event.target.value === 'group_date') {
            propsSet?.updateSort('-date')
        } else {
            propsSet?.updateSort('-date')
        }
        propsSet.update()
    }


    useEffect(() => {
        console.log('grouping')
        setGrouping(localStorage.getItem('grouping'))
    }, [])

    const callBackMerge = (callback) => {
        if (callback) {
            setMergeType('edit')
        } else {
            setMergeType('create')
        }
    }

    useEffect(() => {
        console.log('date_grouping')
        if (grouping === 'group_date') {
            setDateGroup(true)
        } else {
            setDateGroup(false)
        }
    }, [grouping])

    const [mergeType, setMergeType] = useState('create')

    const handleMerge = () => {
        console.log(operations.length)
        if (operations.length >= 2 && success) {
            setShowMerge(true)
        } else {
            setShowMerge(false)
        }
    }

    const closeMergeModal = () => {
        setShowMerge(false)
    }

    const uniqueDates = [...new Set(props?.data?.map(item => item?.date))]


      const formatDate = (iso) => {
        iso = iso.split('')
        let date = iso.slice(0, 10)
        date = date.join('')
        return date
    }

    const [errorMessage, setErrorMessage] = useState(null)

    const callBackError = (error) => {
        setErrorMessage(error)
    }

    useEffect(() => {
        if (showFilters) {
            propsSet?.updateFilters()
        }
    }, [showFilters])

    useEffect(() => {
        console.log('error_message')
        if (errorMessage) {
            setSuccess(false)
        }
    }, [errorMessage])

    useEffect(() => {
        console.log('success')
        if (!success) {
            setTimeout(() => {
                setSuccess(true)
                setErrorMessage(null)
            }, 3000)
        }
    }, [success])

    const today = formatDate(new Date().toISOString())

    if (!uniqueDates?.includes(today)) {
        uniqueDates.unshift(today)
    }

    uniqueDates.sort(function(a,b){
        return new Date(b) - new Date(a);
      });

      const CARDS_GROUPED = Array.from({length: uniqueDates?.length}, (_, index) => {

        const correctObjects = props?.data?.filter(item => item.date === uniqueDates?.[index])

        console.log(correctObjects)

        const CARDS_SORTED = Array.from({length: correctObjects.length === 0 ? 1 : correctObjects.length}, (_, index) => {

            let formedDate = []
            if (correctObjects?.[index]?.date === null) {
                formedDate = '...'
            } else {
                for (let i = 5; i < 10; i++) {
                    formedDate.push(correctObjects?.[index]?.date?.[i])
                }
        
                for (let i = 10; i < 11; i++) {
                    formedDate.push(' ')
                }
        
                for (let i = 11; i < 16; i++) {
                    formedDate.push(correctObjects?.[index]?.date?.[i])
                }
            }
    
            const Date = {
                day: makeDay(formedDate),
                hours: makeHours(formedDate),
                month: makeMonth(formedDate),
                year: makeYear(correctObjects?.[index]?.date)
            }
            
            if (!correctObjects?.[index]?.id) {
                return (
                    <div className='table_card_grouped'>
                        <div className='table_row1_grouped'>
                            <div className='table_row1_type' style={{color: 'white', width: '40vw'}}>Нет операций</div>
                        </div>
                        <div className='create_new' onClick={handleNewOperation}>
                            Создать
                        </div>
                    </div>
                )
            } else {

            return (
                <div className='table_card_grouped' id={`${correctObjects?.[index]?.id}_id`}  onClick={openOperation} style={correctObjects?.[index]?.status === 'plan' ? {borderLeft: '1.5vw solid #8c90ff'} : correctObjects?.[index]?.type === 'expense' || correctObjects?.[index]?.status === 'expired' ? {borderLeft: '1.5vw solid red'} : correctObjects?.[index]?.type === 'income' ? {borderLeft: '1.5vw solid #36f12b'} : correctObjects?.[index]?.type === 'transfer' ? {borderLeft: '1.5vw solid yellow'} : {borderLeft: 'none'}}>
                    <div className='table_row1_grouped'>
                        <div className='table_row1_type' id={`${correctObjects?.[index]?.id}_type`}style={correctObjects[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={openOperation}>
                            <span style={(correctObjects?.[index]?.type === 'expense') ? {color: '#ff8181'} : (correctObjects?.[index]?.type === 'income') ? {color: '#96f16b'} : {color: 'yellow'}}>{correctObjects?.[index]?.subtype === 'unification_payment' ? 'Объединенный платеж' : correctObjects?.[index]?.product_payment ? 'Платеж по услуге' : correctObjects?.[index]?.type === 'expense' ? correctObjects?.[index]?.subtype === 'salary' ? 'Зарплата' : 'Расход' : (correctObjects?.[index]?.type === 'income') ? 'Доход' : 'Перемещение'}</span>
                        </div>
                        <div className='table_row1_date'>
                            {!Date.day ? '' : Date.day} {!Date.month ? '' : Date.month} {!Date.year ? '' : Date.year}
                        </div>
                        <div className='table_row1_checkbox'>
                        <div class="checkbox-wrapper-46" style={{marginLeft: '0.5vw'}} id={`checkbox_be_${correctObjects?.[index]?.id}`}>
                <input type="checkbox" id={`checkbox_id_${correctObjects?.[index]?.id}`} class="inp-cbx"  onClick={handleJoin} />
                <label for={`checkbox_id_${correctObjects?.[index]?.id}`} class="cbx">
                    <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px" id={`checkbox_id_${correctObjects?.[index]?.id}`}>
                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                    </span>
                    <span className='check1' id='label_10'>
                        
                    </span>
                </label>
    </div>
                        </div>
                    </div>
                    <div className='table_row2'>
                        <div className='table_row2_debet'>
                            <span style={{fontWeight: '600'}}>Счет: </span> <span>{!correctObjects?.[index]?.debet1 ? '—' : correctObjects?.[index]?.debet1}</span>
                        </div>
                        <div className='table_row2_debet' style={!correctObjects?.[index]?.debet2 ? {display: 'none'} : {display: 'flex'}}>
                            <span style={{fontWeight: '600'}}>Счет 2:&nbsp;</span> <span>{!correctObjects?.[index]?.debet2 ? '—' : correctObjects?.[index]?.debet2}</span>
                        </div>
                    </div>
                    <div className='table_row3'>
                        <div className='table_row3_category'>
                            <span style={{fontWeight: '600'}}>Категория: </span> <span>{!correctObjects?.[index]?.category ? '—' : correctObjects?.[index]?.category}</span>
                        </div>
                    </div>
                    <div className='table_row4'>
                        <div className='table_row4_comment'>
                            <span style={{fontWeight: '600'}}>Назначение платежа:&nbsp;</span> <span>{!correctObjects?.[index]?.description ? '—' : correctObjects?.[index]?.description}</span>
                        </div>
                    </div>
                    <div className='table_row5'>
                        <div className='table_row5_sum_gp' id={`${correctObjects?.[index]?.id}_sum`}style={correctObjects[index]?.status === 'plan' ? {color: '#8c92ff'} :  correctObjects[index]?.type === 'expense' || correctObjects?.[index]?.status === 'expired' ? {color: '#ff8181'} : correctObjects[index]?.type === 'income' ? {color: '#96f16b'} : {color: 'white'}} onClick={openOperation}>
                       <span style={{color: 'white', fontWeight: '600'}}>Сумма: </span>
                <span className='sum_cell'>{correctObjects[index]?.type === 'expense' ? '-' : correctObjects[index]?.type === 'income' ? '+' : ''}{correctObjects?.[index]?.cost.toLocaleString()}р.</span>
                <div className='mark2' style={correctObjects[index]?.invoice && correctObjects?.[index]?.status === 'expired' ? {display: 'block', marginRight: '-17vw'} : correctObjects?.[index]?.invoice ? {display: 'flex'} : {display: 'none'}}></div>
                <div className='tooltip_invoice'>
                    Инвойс прикреплен
                </div>
                    <svg className='mark' style={correctObjects[index]?.status === 'expired' ? {display: 'block'} : {display: 'none'}} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlSpace="preserve" fill="#000000">
    
    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
    
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
    
    <g id="SVGRepo_iconCarrier"> <g> <path style={{fill:'#ff0000'}} d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M12,19.66 c-0.938,0-1.58-0.723-1.58-1.66c0-0.964,0.669-1.66,1.58-1.66c0.963,0,1.58,0.696,1.58,1.66C13.58,18.938,12.963,19.66,12,19.66z M12.622,13.321c-0.239,0.815-0.992,0.829-1.243,0c-0.289-0.956-1.316-4.585-1.316-6.942c0-3.11,3.891-3.125,3.891,0 C13.953,8.75,12.871,12.473,12.622,13.321z"/> </g> </g>
    
    </svg>
                <div className='tooltip_expired'>
                    Платеж просрочен
                </div>
    
                </div>
                    </div>
                </div>
            )}
        })

        let date = ''

        if (makeDayISO(uniqueDates?.[index]) === makeDayISO(new Date().toISOString()) && makeMonthISO(uniqueDates?.[index]) === makeMonthISO(new Date().toISOString()) && makeYearISO(new Date().toISOString()) === makeYearISO(uniqueDates?.[index])) {
            date = 'Сегодня'
        } else {
            date = `${makeDayISO(uniqueDates?.[index])} ${makeMonthISO(uniqueDates?.[index])} ${makeYearISO(uniqueDates?.[index])}`
        }

        return (
            <div className='group_box'>
                <span className='gp_box_title' style={{fontSize: '5vw', color: 'white', marginLeft: '3vw'}}>{date}</span>
                {CARDS_SORTED}
            </div>
        )
    })

    const Grouped = Array.from({length: uniqueDates?.length}, (_, index) => {
        const correctObjects = props?.data?.filter(item => item.date === uniqueDates?.[index])
        


        const makeCellsCheckboxGrouped = Array.from({length: correctObjects?.length === 0 ? 1 : correctObjects?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' key={index} id={`${correctObjects?.[index]?.id}_id`} style={correctObjects?.[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}}>
                <div class="checkbox-wrapper-46" style={{marginLeft: '0.5vw'}} id={`checkbox_be_${correctObjects?.[index]?.id}`}>
                <input type="checkbox" id={`checkbox_id_${correctObjects?.[index]?.id}`} class="inp-cbx"  onClick={handleJoin}/>
                <label for={`checkbox_id_${correctObjects?.[index]?.id}`} class="cbx">
                    <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px" id={`checkbox_id_${correctObjects?.[index]?.id}`}>
                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                    </span>
                    <span className='check1' id='label_10'>
                        
                    </span>
                </label>
    </div>
    </div>
            )
        })

        const makeCellsIdGrouped = Array.from({length: correctObjects?.length === 0 ? 1 : correctObjects?.length}, (_, index) => {
            let comment = correctObjects?.[index]?.description
            if (comment === null || comment === undefined) {
                comment = ''
            } else {
            if (comment.length > 40) {
                comment = comment.split('')
                comment = comment.slice(0, 40)
                comment = comment.join('')
                comment = comment + '...'
            }
            }
            return (
                <div className='column_helper_cell' key={index} id={`${correctObjects?.[index]?.id}_id`} style={correctObjects[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={openOperation}>
                        {comment}
                </div>
            )
        })
    
        const makeCellsDateGrouped = Array.from({length: correctObjects?.length === 0 ? 1 : correctObjects?.length}, (_, index) => {
    
            let formedDate = []
            let Date
            if (correctObjects[index]?.date === null || correctObjects[index]?.date === undefined) {
                formedDate = ''
            } else {
                for (let i = 5; i < 10; i++) {
                    formedDate.push(correctObjects[index]?.date[i])
                }
        
                for (let i = 10; i < 11; i++) {
                    formedDate.push(' ')
                }
        
                for (let i = 11; i < 16; i++) {
                    formedDate.push(correctObjects[index]?.date[i])
                }
                Date = {
                    day: makeDay(formedDate),
                    hours: makeHours(formedDate),
                    month: makeMonth(formedDate),
                    year: makeYear(correctObjects[index]?.date)
                }
            }
    

    
    
            return (
                <div className='column_helper_cell' key={index} id={`${correctObjects?.[index]?.id}_date`}style={correctObjects[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={correctObjects[index]?.date === undefined ? handleNewOperation : openOperation}>
                    {(correctObjects[index]?.date === undefined) ? '' : `${Date?.day} ${Date?.month} ${Date?.year}`}
    
                </div>
            )
        })
    
        const makeCellsTypeGrouped = Array.from({length: correctObjects?.length === 0 ? 1 : correctObjects?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' key={index} id={`${correctObjects?.[index]?.id}_type`}style={correctObjects[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={correctObjects[index]?.date === undefined ? handleNewOperation : openOperation}>
                    <span style={(correctObjects[index]?.type === 'expense') ? {color: '#ff8181'} : (correctObjects[index]?.type === 'income') ? {color: '#96f16b'} : {color: 'yellow'}}>{correctObjects?.[index]?.product_payment ? 'Платеж по услуге' : correctObjects[index]?.type === 'expense' ? correctObjects?.[index]?.subtype === 'salary' ? 'Зарплата' : 'Расход' : (correctObjects[index]?.type === 'income') ? 'Доход' : (correctObjects?.[index]?.type === 'transfer') ? 'Перемещение' : ''}</span>
                </div>
            )
        })
    
        const makeCellsCategoryGrouped = Array.from({length: correctObjects?.length === 0 ? 1 : correctObjects?.length}, (_, index) => {
            return (
                <div className={correctObjects?.date === undefined ? 'make_new column_helper_cell' : 'column_helper_cell'} key={index} id={`${correctObjects?.[index]?.id}_category`}style={correctObjects[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={correctObjects[index]?.date === undefined ? handleNewOperation : openOperation}>
                        <span style={correctObjects.length === 0 ? {color: 'rgb(99, 99, 99)'} : {}}>{(correctObjects[index]?.category === undefined) ? 'Нет операций' : correctObjects[index]?.category}</span>
                        {correctObjects[index]?.date === undefined ? <div className='create_tooltip2' onClick={handleNewOperation}>Создать операцию</div> : null}
                </div>
            )
        })
    
        const makeCellsDebet1Grouped = Array.from({length: correctObjects?.length === 0 ? 1 : correctObjects?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' key={index} id={`${correctObjects?.[index]?.id}_debet1`}style={correctObjects[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={correctObjects[index]?.date === undefined ? handleNewOperation : openOperation}>
                    {(correctObjects[index]?.debet1 === undefined) ? '' : correctObjects[index]?.debet1}
                </div>
            )
        })
    
        const makeCellsDebet2Grouped = Array.from({length: correctObjects?.length === 0 ? 1 : correctObjects?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' key={index} id={`${correctObjects?.[index]?.id}_debet2`}style={correctObjects[index]?.status === 'plan' ? {color: '#8c92ff'} : {color: 'white'}} onClick={correctObjects[index]?.date === undefined ? handleNewOperation : openOperation}>
                    {(correctObjects[index]?.debet2 === undefined) ? '' : correctObjects[index]?.debet2}
                </div>
            )
        })
    
        const makeCellsSumGrouped = Array.from({length: correctObjects?.length === 0 ? 1 : correctObjects?.length}, (_, index) => {
            return (
                <div className='column_helper_cell' key={index} id={`${correctObjects?.[index]?.id}_sum`}style={correctObjects[index]?.status === 'plan' ? {color: '#8c92ff'} : correctObjects[index]?.type === 'expense' || correctObjects?.[index]?.status === 'expired' ? {color: '#ff8181'} : correctObjects[index]?.type === 'income' ? {color: '#96f16b'} : {color: 'white'}} onClick={openOperation}>
                <span className='sum_cell'  >{correctObjects[index]?.type === 'expense' ? '-' : correctObjects[index]?.type === 'income' ? '+' : ''}{correctObjects[index]?.cost === undefined ? '' : `${correctObjects[index]?.cost?.toLocaleString()}₽`}</span>
                <div className='mark2' style={correctObjects?.[index]?.invoice ? {display: 'block'} : {display: 'none'}}></div>
            <div className='tooltip_invoice'>
                Инвойс прикреплен
            </div>
                    <svg className='mark' style={correctObjects[index]?.status === 'expired' ? {display: 'block'} : {display: 'none'}} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlSpace="preserve" fill="#000000">
    
    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
    
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
    
    <g id="SVGRepo_iconCarrier"> <g> <path style={{fill:'#ff0000'}} d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M12,19.66 c-0.938,0-1.58-0.723-1.58-1.66c0-0.964,0.669-1.66,1.58-1.66c0.963,0,1.58,0.696,1.58,1.66C13.58,18.938,12.963,19.66,12,19.66z M12.622,13.321c-0.239,0.815-0.992,0.829-1.243,0c-0.289-0.956-1.316-4.585-1.316-6.942c0-3.11,3.891-3.125,3.891,0 C13.953,8.75,12.871,12.473,12.622,13.321z"/> </g> </g>
    
    </svg>
                <div className='tooltip_expired'>
                    Платеж просрочен
                </div>
                </div>
            )
        })

        let date = ''

        if (makeDayISO(uniqueDates?.[index]) === makeDayISO(new Date().toISOString()) && makeMonthISO(uniqueDates?.[index]) === makeMonthISO(new Date().toISOString()) && makeYearISO(new Date().toISOString()) === makeYearISO(uniqueDates?.[index])) {
            date = 'Сегодня'
        } else {
            date = `${makeDayISO(uniqueDates?.[index])} ${makeMonthISO(uniqueDates?.[index])} ${makeYearISO(uniqueDates?.[index])}`
        }

        if (index === 0) {
            return (
                <><div className='group_title' style={{marginLeft: '1.5vw', width: '97.5%'}}> 
                    <div className='gp_title'> {date} </div>
                 </div><div className='table_box'>
                    <div className='table_column' id='check_column'>
                        {makeCellsCheckboxGrouped}
                    </div>
                <div className='table_column' id='cell_id'>
                    {makeCellsIdGrouped}
                </div>
                <div className='table_column' id='date_column'>
                    {makeCellsDateGrouped}
                </div>
                <div className='table_column' id='start_balance_column'>
                    {makeCellsTypeGrouped}
                </div>
                <div className='table_column' id='balance_column'>
                    {makeCellsCategoryGrouped}
                </div>
                <div className='table_column' id='debet1_column'>
                    {makeCellsDebet1Grouped}
                </div>
                <div className='table_column' id='number_column'>
                    {makeCellsDebet2Grouped}
                </div>
                <div className='table_column' id='sum_column'>
                    {makeCellsSumGrouped}
                </div>
            </div></>
            )
        }

        return (
            <><div className='group_title' style={{marginLeft: '1.5vw', width: '97.5%'}}> 
                <div className='gp_title'> {date} </div>
             </div><div className='table_box'>
                <div className='table_column' id='check_column'>
                    {makeCellsCheckboxGrouped}
                </div>
                <div className='table_column' id='cell_id'>
                    {makeCellsIdGrouped}
                </div>
                <div className='table_column' id='date_column'>
                    {makeCellsDateGrouped}
                </div>
                <div className='table_column' id='start_balance_column'>
                    {makeCellsTypeGrouped}
                </div>
                <div className='table_column' id='balance_column'>
                    {makeCellsCategoryGrouped}
                </div>
                <div className='table_column' id='debet1_column'>
                    {makeCellsDebet1Grouped}
                </div>
                <div className='table_column' id='number_column'>
                    {makeCellsDebet2Grouped}
                </div>
                <div className='table_column' id='sum_column'>
                    {makeCellsSumGrouped}
                </div>
            </div></>
        )
    })

    if (props?.data === undefined) {
        return (
            <body style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
<circle
class="active"
pathLength="360"
fill="transparent"
stroke-width="32"
cx="192"
cy="192"
r="176"
></circle>
<circle
class="track"
pathLength="360"
fill="transparent"
stroke-width="32"
cx="192"
cy="192"
r="176"
></circle>
</svg>
</body>
        )
    } else {

    if (dateGroup) {
        return (
            <><MediaQuery minWidth={900}>
                <body>
                    <Error errors={!success} message={errorMessage} />
                    <div className='statistic_row' style={propsSet?.isClient ? { display: 'none' } : { display: 'flex' }}>
                        <div className='statistic_cell'>{propsSet?.data?.data?.summary?.transactions_count} {(props?.data.length === 1) ? ' Операция' : (props?.data.length) <= 3 ? ' Операции' : ' Операций'}</div>
                        <div className='statistic_cell' id='income_'>Доходы: +{propsSet?.data?.data?.summary?.income_sum?.toLocaleString()}р.</div>
                        <div className='statistic_cell' id='expense_'>Расходы: -{propsSet?.data?.data?.summary?.expense_sum?.toLocaleString()}р.</div>
                        <div className='statistic_cell' id={propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? 'income_' : 'expense_'}>Итого: {propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? `+${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}` : `-${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}`}р.</div>
                    </div>
                    <BreadCrumbs isClient={propsSet?.isClient} />
                    <div className='first_row'>
                        <div className='operations' style={propsSet?.isClient ? {display: 'flex'} : {}}>{propsSet?.isClient ? 'Операции по клиенту' : 'Операции'}
                            <div className='type_svg' id='big_type_svg' style={propsSet?.isClient ? { display: 'none' } : { display: 'block' }}>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 18.01L12.01 17.9989" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className='create_button' style={propsSet?.isClient ? { display: 'none' } : { display: 'flex' }} onClick={handleNewOperation}>
                            Создать
                        </div>
                        <div className={operations.length >= 2 ? 'create_button' : 'create_button_hidden'} onClick={handleMerge} style={operations.length >= 2 ? { display: 'none', marginLeft: '1vw', width: '9vw', paddingLeft: '0.5vw', paddingRight: '0.5vw' } : { display: 'none', background: 'none', color: 'rgb(159, 159, 159)' }}>
                            Объединить
                        </div>
                        <select className='helper_select' style={propsSet?.isClient ? { marginLeft: '41vw' } : null} onChange={handleGroup} value={grouping}>
                            <option value='no_group'>Без группировки</option>
                            <option value='group_date'>По дате</option>
                        </select>
                        <MediaQuery minWidth={900}>
                        <div className='small_search_box'>
                            <div className='search_icon'></div>
                            <input type='text' className='small_search' placeholder='Поиск' id='small_search' />
                        </div>
                        </MediaQuery>
                    </div>
                    <div className='table_box'>
                        <div className='table_column' id='check_column'>
                            <div className='table_column_title top_column column_helper_cell'></div>
                        </div>
                        <div className='table_column' id='cell_id'>
                            <div className='table_column_title top_column column_helper_cell'>Назначение платежа</div>
                        </div>
                        <div className='table_column' id='date_column'>
                            <div className='table_column_title top_column column_helper_cell'>Дата</div>
                        </div>
                        <div className='table_column' id='start_balance_column'>
                            <div className='table_column_title top_column column_helper_cell'>Тип</div>
                        </div>
                        <div className='table_column' id='balance_column'>
                            <div className='table_column_title top_column column_helper_cell'>Категория</div>
                        </div>
                        <div className='table_column' id='debet1_column'>
                            <div className='table_column_title top_column column_helper_cell'>Счет</div>
                        </div>
                        <div className='table_column' id='number_column'>
                            <div className='table_column_title top_column column_helper_cell'>Счет 2</div>
                        </div>
                        <div className='table_column' id='sum_column'>
                            <div className='table_column_title top_column column_helper_cell'>Сумма</div>
                        </div>
                    </div>
                    {Grouped}
                    <div className='statistic_row_' style={propsSet?.isClient ? { display: 'flex' } : { display: 'none' }}>
                        <div className='statistic_cell_'>{propsSet?.data?.data?.summary?.transactions_count} {(props?.data.length === 1) ? 'Операция' : (props?.data.length) <= 3 ? 'Операции' : 'Операций'}</div>
                        <div className='statistic_cell_' id='income_'>Доходы: +{propsSet?.data?.data?.summary?.income_sum?.toLocaleString()}р.</div>
                        <div className='statistic_cell_' id='expense_'>Расходы: -{propsSet?.data?.data?.summary?.expense_sum?.toLocaleString()}р.</div>
                        <div className='statistic_cell__' id={propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? 'income_' : 'expense_'}>Итого: {propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? `+${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}` : `-${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}`}р.</div>
                    </div>
                    <div className={(showModal) ? 'modal_overlay' : 'modal_inactive'} onClick={closeModal}>
                        <NewOperation update={updateData} mergeType={mergeType} status={status} callBackMerge={callBackMerge} id={id} updating={updating} />
                    </div>
                    <div className={showMerge ? 'modal_overlay' : 'modal_inactive'} onClick={closeMerge}>
                        <MergeOperations operations={operations} mergeType={mergeType} showMerge={showMerge} callBackError={callBackError} preventMerge={preventMerge} closeMerge={closeMergeModal} update={updateMerge} />
                    </div>
                </body>
            </MediaQuery><MediaQuery minWidth={340} maxWidth={900}>
            <body>
            <Error errors={!success} message={errorMessage} />
            <div className='statistic_row' style={propsSet?.isClient ? { display: 'none' } : { display: 'flex' }}>
                <div className='statistic_cell' style={{borderBottom: 'none', borderLeft: 'none', borderRight: 'none', borderTop: 'none'}}>{propsSet?.data?.data?.summary?.transactions_count} {(props?.data.length === 1) ? 'Операция' : (props?.data.length) <= 3 ? 'Операции' : 'Операций'}</div>
                <div className='statistic_cell' style={{borderBottom: 'none', borderRight: 'none', borderTop: 'none'}} id='income_'>Доходы: +{propsSet?.data?.data?.summary?.income_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell' style={{borderBottom: 'none', borderLeft: 'none', borderRight: 'none'}} id='expense_'>Расходы: -{propsSet?.data?.data?.summary?.expense_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell'  style={{borderBottom: 'none', borderRight: 'none'}} id={propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? 'income_' : 'expense_'}>Итого: {propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? `+${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}` : `-${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}`}р.</div>
            </div>

            <div className='first_row' style={!propsSet?.isClient ? {marginLeft: '5vw'} : {marginLeft: '0'}}>
                <div className='operations' style={propsSet?.isClient ? {display: 'flex', marginLeft: '0' } : {}}>{propsSet?.isClient ? 'Операции по клиенту' : 'Операции'}
                    <div className='type_svg' id='big_type_svg' style={propsSet?.isClient ? { display: 'none' } : { display: 'block' }}>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 18.01L12.01 17.9989" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className='small_search_box_' style={{marginLeft: '0'}}>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search' />
                </div>
                <div className={propsSet?.isClient ? 'filters_client' : 'filters'} onClick={() => {setShowFilters(!showFilters)}}>
                    <div className='filters_svg'>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 6H19M21 12H16M21 18H16M7 20V13.5612C7 13.3532 7 13.2492 6.97958 13.1497C6.96147 13.0615 6.93151 12.9761 6.89052 12.8958C6.84431 12.8054 6.77934 12.7242 6.64939 12.5617L3.35061 8.43826C3.22066 8.27583 3.15569 8.19461 3.10948 8.10417C3.06849 8.02393 3.03853 7.93852 3.02042 7.85026C3 7.75078 3 7.64677 3 7.43875V5.6C3 5.03995 3 4.75992 3.10899 4.54601C3.20487 4.35785 3.35785 4.20487 3.54601 4.10899C3.75992 4 4.03995 4 4.6 4H13.4C13.9601 4 14.2401 4 14.454 4.10899C14.6422 4.20487 14.7951 4.35785 14.891 4.54601C15 4.75992 15 5.03995 15 5.6V7.43875C15 7.64677 15 7.75078 14.9796 7.85026C14.9615 7.93852 14.9315 8.02393 14.8905 8.10417C14.8443 8.19461 14.7793 8.27583 14.6494 8.43826L11.3506 12.5617C11.2207 12.7242 11.1557 12.8054 11.1095 12.8958C11.0685 12.9761 11.0385 13.0615 11.0204 13.1497C11 13.2492 11 13.3532 11 13.5612V17L7 20Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                    </div> Фильтры
                </div>
                <select className='helper_select' style={propsSet?.isClient ? { marginLeft: '5vw', marginRight: '0' } : {marginLeft: '4vw', marginRight: '0'}} onChange={handleGroup} value={grouping}>
                    <option value='no_group'>Без группировки</option>
                    <option value='group_date'>По дате</option>
                </select>
                <div className='create_button' style={propsSet?.isClient ? { display: 'none' } : { display: 'flex', marginLeft: '0' }} onClick={handleNewOperation}>
                    Создать
                </div>
                <div className={operations.length >= 2 ? 'create_button' : 'create_button_hidden'} onClick={handleMerge} style={operations.length >= 2 ? { display: 'none', marginLeft: '1vw', width: '9vw', paddingLeft: '0.5vw', paddingRight: '0.5vw' } : { display: 'none', background: 'none', color: 'rgb(159, 159, 159)' }}>
                    Объединить
                </div>
            </div>

            <div className='card_box' style={propsSet?.isClient ? {marginLeft: '0'} : {}}>
                {CARDS_GROUPED}
            </div>
            <div className='statistic_row_' style={propsSet?.isClient ? { display: 'flex' } : { display: 'none' }}>
                <div className='statistic_cell_'>{propsSet?.data?.data?.summary?.transactions_count} {(props?.data.length === 1) ? 'Операция' : (props?.data.length) <= 3 ? 'Операции' : 'Операций'}</div>
                <div className='statistic_cell_' id='income_'>Доходы: +{propsSet?.data?.data?.summary?.income_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell_' id='expense_'>Расходы: -{propsSet?.data?.data?.summary?.expense_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell__' id={propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? 'income_' : 'expense_'}>Итого: {propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? `+${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}` : `${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}`}р.</div>
            </div>
            <div className={(showModal) ? 'modal_overlay' : 'modal_inactive'} onClick={closeModal}>
            <div className='close_modal_x' onClick={() => {setShowModal(false)}}>
                <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00386 9.41816C7.61333 9.02763 7.61334 8.39447 8.00386 8.00395C8.39438 7.61342 9.02755 7.61342 9.41807 8.00395L12.0057 10.5916L14.5907 8.00657C14.9813 7.61605 15.6144 7.61605 16.0049 8.00657C16.3955 8.3971 16.3955 9.03026 16.0049 9.42079L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.0039C15.6133 16.3945 14.9802 16.3945 14.5896 16.0039L12.0057 13.42L9.42097 16.0048C9.03045 16.3953 8.39728 16.3953 8.00676 16.0048C7.61624 15.6142 7.61624 14.9811 8.00676 14.5905L10.5915 12.0058L8.00386 9.41816Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z" fill="white"/>
</svg>
                </div>
                <NewOperation update={updateData} mergeType={mergeType} callBackMerge={callBackMerge} clientID={propsSet?.clientID} clientName={propsSet?.clientName} status={status} id={id} updating={updating} />
            </div>
            </body>
                </MediaQuery></>
            )
    } else {

    return (
        <>
        <MediaQuery minWidth={900}>
    <body>
            <Error errors={!success} message={errorMessage} />
            <div className='statistic_row' style={propsSet?.isClient ? { display: 'none' } : { display: 'flex' }}>
                <div className='statistic_cell'>{propsSet?.data?.data?.summary?.transactions_count} {(props?.data.length === 1) ? 'Операция' : (props?.data.length) <= 3 ? 'Операции' : 'Операций'}</div>
                <div className='statistic_cell' id='income_'>Доходы: +{propsSet?.data?.data?.summary?.income_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell' id='expense_'>Расходы: -{propsSet?.data?.data?.summary?.expense_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell' id={propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? 'income_' : 'expense_'}>Итого: {propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? `+${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}` : `-${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}`}р.</div>
            </div>
            <BreadCrumbs isClient={propsSet?.isClient} />
            <div className='first_row'>
                <div className='operations' style={propsSet?.isClient ? {display: 'flex' } : {}}>{propsSet?.isClient ? 'Операции по клиенту' : 'Операции'}
                    <div className='type_svg' id='big_type_svg' style={propsSet?.isClient ? { display: 'none' } : { display: 'block' }}>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 18.01L12.01 17.9989" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className='create_button' style={propsSet?.isClient ? { display: 'none' } : { display: 'flex' }} onClick={handleNewOperation}>
                    Создать
                </div>
                <div className={operations.length >= 2 ? 'create_button' : 'create_button_hidden'} onClick={handleMerge} style={operations.length >= 2 ? { display: 'none', marginLeft: '1vw', width: '9vw', paddingLeft: '0.5vw', paddingRight: '0.5vw' } : { display: 'none', background: 'none', color: 'rgb(159, 159, 159)' }}>
                    Объединить
                </div>
                <select className='helper_select' style={propsSet?.isClient ? { marginLeft: '41vw', } : null} onChange={handleGroup} value={grouping}>
                    <option value='no_group'>Без группировки</option>
                    <option value='group_date'>По дате</option>
                </select>
                <div className='small_search_box'>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search' />
                </div>
            </div>
            <div className='table_box'>
                <div className='table_column' id='check_column'>
                    <div className='table_column_title top_column column_helper_cell'></div>
                    {makeCellsCheckbox}
                </div>
                <div className='table_column' id='cell_id'>
                    <div className='table_column_title top_column column_helper_cell'>Назначение платежа</div>
                    {makeCellsId}
                </div>
                <div className='table_column' id='date_column'>
                    <div className='table_column_title top_column table_column_title_clickable column_helper_cell' onClick={handleSort}>Дата {propsSet?.sort === 'date' ? '↑' : propsSet?.sort === '-date' ? '↓' : ''}</div>
                    {makeCellsDate}
                </div>
                <div className='table_column' id='start_balance_column'>
                    <div className='table_column_title top_column column_helper_cell'>Тип</div>
                    {makeCellsType}
                </div>
                <div className='table_column' id='balance_column'>
                    <div className='table_column_title top_column column_helper_cell'>Категория</div>
                    {makeCellsCategory}
                </div>
                <div className='table_column' id='debet1_column'>
                    <div className='table_column_title top_column column_helper_cell'>Счет</div>
                    {makeCellsDebet1}
                </div>
                <div className='table_column' id='number_column'>
                    <div className='table_column_title top_column column_helper_cell'>Счет 2</div>
                    {makeCellsDebet2}
                </div>
                <div className='table_column' id='sum_column'>
                    <div className='table_column_title top_column table_column_title_clickable column_helper_cell' onClick={handleSort}>Сумма {propsSet?.sort === 'cost' ? '↑' : propsSet?.sort === '-cost' ? '↓' : ''}</div>
                    {makeCellsSum}
                </div>
            </div>
            <div className='statistic_row_' style={propsSet?.isClient ? { display: 'flex' } : { display: 'none' }}>
                <div className='statistic_cell_'>{propsSet?.data?.data?.summary?.transactions_count} {(props?.data.length === 1) ? 'Операция' : (props?.data.length) <= 3 ? 'Операции' : 'Операций'}</div>
                <div className='statistic_cell_' id='income_'>Доходы: +{propsSet?.data?.data?.summary?.income_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell_' id='expense_'>Расходы: -{propsSet?.data?.data?.summary?.expense_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell__' id={propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? 'income_' : 'expense_'}>Итого: {propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? `+${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}` : `${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}`}р.</div>
            </div>
            <div className={(showModal) ? 'modal_overlay' : 'modal_inactive'} onClick={closeModal}>
                <NewOperation update={updateData} mergeType={mergeType} callBackMerge={callBackMerge} clientID={propsSet?.clientID} clientName={propsSet?.clientName} status={status} id={id} updating={updating} />
            </div>
            <div className={showMerge ? 'modal_overlay' : 'modal_inactive'} onClick={closeMerge}>
                <MergeOperations operations={operations} mergeType={mergeType} showMerge={showMerge} callBackError={callBackError} closeMerge={closeMergeModal} preventMerge={preventMerge} update={updateMerge} />
            </div>
        </body>
        </MediaQuery>
        <MediaQuery minWidth={340} maxWidth={900}>
        <body>
            <Error errors={!success} message={errorMessage} />
            <div className='statistic_row' style={propsSet?.isClient ? { display: 'none' } : { display: 'flex' }}>
                <div className='statistic_cell' style={{borderBottom: 'none', borderLeft: 'none', borderRight: 'none', borderTop: 'none'}}>{propsSet?.data?.data?.summary?.transactions_count} {(props?.data.length === 1) ? 'Операция' : (props?.data.length) <= 3 ? 'Операции' : 'Операций'}</div>
                <div className='statistic_cell' style={{borderBottom: 'none', borderRight: 'none', borderTop: 'none'}} id='income_'>Доходы: +{propsSet?.data?.data?.summary?.income_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell' style={{borderBottom: 'none', borderLeft: 'none', borderRight: 'none'}} id='expense_'>Расходы: -{propsSet?.data?.data?.summary?.expense_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell'  style={{borderBottom: 'none', borderRight: 'none'}} id={propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? 'income_' : 'expense_'}>Итого: {propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? `+${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}` : `-${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}`}р.</div>
            </div>

            <div className='first_row' style={!propsSet?.isClient ? {marginLeft: '5vw'} : {marginLeft: '0'}}>
                <div className='operations' style={propsSet?.isClient ? { display: 'flex', marginLeft: '0' } : {}}>{propsSet?.isClient ? 'Операции по клиенту' : 'Операции'}
                    <div className='type_svg' id='big_type_svg' style={propsSet?.isClient ? { display: 'none' } : { display: 'block' }}>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 18.01L12.01 17.9989" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className='small_search_box_' style={{marginLeft: '0'}}>
                    <div className='search_icon'></div>
                    <input type='text' className='small_search' placeholder='Поиск' id='small_search' />
                </div>
                <div className={propsSet?.isClient ? 'filters_client' : 'filters'} onClick={() => {setShowFilters(!showFilters)}}>
                    <div className='filters_svg'>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 6H19M21 12H16M21 18H16M7 20V13.5612C7 13.3532 7 13.2492 6.97958 13.1497C6.96147 13.0615 6.93151 12.9761 6.89052 12.8958C6.84431 12.8054 6.77934 12.7242 6.64939 12.5617L3.35061 8.43826C3.22066 8.27583 3.15569 8.19461 3.10948 8.10417C3.06849 8.02393 3.03853 7.93852 3.02042 7.85026C3 7.75078 3 7.64677 3 7.43875V5.6C3 5.03995 3 4.75992 3.10899 4.54601C3.20487 4.35785 3.35785 4.20487 3.54601 4.10899C3.75992 4 4.03995 4 4.6 4H13.4C13.9601 4 14.2401 4 14.454 4.10899C14.6422 4.20487 14.7951 4.35785 14.891 4.54601C15 4.75992 15 5.03995 15 5.6V7.43875C15 7.64677 15 7.75078 14.9796 7.85026C14.9615 7.93852 14.9315 8.02393 14.8905 8.10417C14.8443 8.19461 14.7793 8.27583 14.6494 8.43826L11.3506 12.5617C11.2207 12.7242 11.1557 12.8054 11.1095 12.8958C11.0685 12.9761 11.0385 13.0615 11.0204 13.1497C11 13.2492 11 13.3532 11 13.5612V17L7 20Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                    </div> Фильтры
                </div>
                <select className='helper_select' style={propsSet?.isClient ? {marginLeft: '5vw', marginRight: '0'} : {marginLeft: '4vw', marginRight: '0'}} onChange={handleGroup} value={grouping}>
                    <option value='no_group'>Без группировки</option>
                    <option value='group_date'>По дате</option>
                </select>
                <div className='create_button' style={propsSet?.isClient ? { display: 'none' } : { display: 'flex', marginLeft: '0' }} onClick={handleNewOperation}>
                    Создать
                </div>
                <div className={operations.length >= 2 ? 'create_button' : 'create_button_hidden'} onClick={handleMerge} style={operations.length >= 2 ? { display: 'none', marginLeft: '1vw', width: '9vw', paddingLeft: '0.5vw', paddingRight: '0.5vw' } : { display: 'none', background: 'none', color: 'rgb(159, 159, 159)' }}>
                    Объединить
                </div>
            </div>

            <div className='card_box' style={propsSet?.isClient ? {marginLeft: '0'} : {}}>
                {CARDS}
            </div>
            <div className='statistic_row_' style={propsSet?.isClient ? { display: 'flex' } : { display: 'none' }}>
                <div className='statistic_cell_'>{propsSet?.data?.data?.summary?.transactions_count} {(props?.data.length === 1) ? 'Операция' : (props?.data.length) <= 3 ? 'Операции' : 'Операций'}</div>
                <div className='statistic_cell_' id='income_'>Доходы: +{propsSet?.data?.data?.summary?.income_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell_' id='expense_'>Расходы: -{propsSet?.data?.data?.summary?.expense_sum?.toLocaleString()}р.</div>
                <div className='statistic_cell__' id={propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? 'income_' : 'expense_'}>Итого: {propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum > 0 ? `+${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}` : `${(propsSet?.data?.data?.summary?.income_sum - propsSet?.data?.data?.summary?.expense_sum).toLocaleString()}`}р.</div>
            </div>
            <div className={(showModal) ? 'modal_overlay' : 'modal_inactive'} onClick={closeModal}>
                <div className='close_modal_x' onClick={() => {setShowModal(false)}}>
                <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00386 9.41816C7.61333 9.02763 7.61334 8.39447 8.00386 8.00395C8.39438 7.61342 9.02755 7.61342 9.41807 8.00395L12.0057 10.5916L14.5907 8.00657C14.9813 7.61605 15.6144 7.61605 16.0049 8.00657C16.3955 8.3971 16.3955 9.03026 16.0049 9.42079L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.0039C15.6133 16.3945 14.9802 16.3945 14.5896 16.0039L12.0057 13.42L9.42097 16.0048C9.03045 16.3953 8.39728 16.3953 8.00676 16.0048C7.61624 15.6142 7.61624 14.9811 8.00676 14.5905L10.5915 12.0058L8.00386 9.41816Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z" fill="white"/>
</svg>
                </div>
                <NewOperation update={updateData} mergeType={mergeType} callBackMerge={callBackMerge} clientID={propsSet?.clientID} clientName={propsSet?.clientName} status={status} id={id} updating={updating} />
            </div>
            </body>
            </MediaQuery>
        </>
    )
}}
}

export default Table