import { observer } from "mobx-react-lite";
import { planFactStore } from "../stores/plan-fact";
import { TransactionsList } from "./TransactionsList";

const depthToBackground = {
  // 0: "#282B34",
  // 1: "#30343F",
  1: "#23262F",
  2: "#1B1F24",
  3: "#14181F",
  4: "#0D111A",
  5: "#05080E",
};

const Cell = ({ children, depth }) => {
  return (
    <td style={{ backgroundColor: depthToBackground[depth] }} className="cell">
      {children}
    </td>
  );
};

export const RecursiveRow = observer(
  ({
    dates,
    title,
    summaryData = [],
    detailedData = [],
    leafNodes = [],
    depth = 1,
  }) => {
    const key = title;

    const isOpen = planFactStore.getRowState(key);

    const toggleRow = () => {
      planFactStore.toggleRow(key);
    };

    if (detailedData.length === 0) {
      return (
        <tr>
          <th
            style={{
              paddingLeft: depth * 20,
              backgroundColor: depthToBackground[depth],
            }}
          >
            {title}
          </th>
          {summaryData.map((data, index) => (
            <Cell key={index} depth={depth}>
              {data}
            </Cell>
          ))}
        </tr>
      );
    }

    return (
      <>
        <tr style={{ cursor: "pointer" }}>
          <th
            style={{
              paddingLeft: depth * 20,
              backgroundColor: depthToBackground[depth],
            }}
            onClick={toggleRow}
          >
            <button className="row-toggle-button">{isOpen ? "-" : "+"}</button>
            <span style={{ marginLeft: "15px" }}>{title}</span>
          </th>
          {isOpen
            ? summaryData.map((data, index) => (
                <Cell depth={depth} key={index}>
                  {data}
                </Cell>
              ))
            : leafNodes.map((data, index) => (
                <Cell key={index} depth={depth}>
                  <TransactionsList transactions={data} />
                </Cell>
              ))}
        </tr>
        {isOpen &&
          detailedData.map((detail, index) => (
            <RecursiveRow
              dates={dates}
              key={index}
              title={detail.title}
              summaryData={detail.summaryData}
              detailedData={detail.detailedData}
              leafNodes={detail.leafNodes}
              depth={depth + 1}
            />
          ))}
      </>
    );
  }
);
