import './NewOperation.css'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useState, useEffect } from 'react'
import ru from 'date-fns/locale/ru';
import { STORE } from '../STORE.js'
import InputMask from 'react-input-mask'
import { makeDayISO, makeMonthISO, makeYearISO } from '../Logic/DateFormating.js'
import FileInputComponent from 'react-file-input-previews-base64'
import { Link, useNavigate } from 'react-router-dom'
import CurrencyInput from 'react-currency-input-field'
import FileBase64 from 'react-file-base64'
import Error from '../components/Error.js'
import MediaQuery from 'react-responsive'

const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

const NewOperation = (props) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(true)
    const [startDate, setStartDate] = useState(new Date())
    const [currentModal, setCurrentModal] = useState('income_button')
    const [endDate, setEndDate] = useState()
    const [employe_id, setEmploye_id] = useState(1)

    const handleModal = () => {
        props.update()
    }

    const handleChangeModal = (event) => {
        if (event.target.className.length < 14) {
            if (props.status !== 'edit') {
                setCurrentModal(event.target.className)
                setValue('')
                setRemain(false)
            } else {
                setRemain(false)
            }

        }
    }
    const [dataSet, setDataSet] = useState()
    const API = 'https://eternity-tracker.ru/api/finances/transactions/create/'
    useEffect(() => {
        console.log('FETCH_GET')
        fetch(API, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setDataSet(data.data)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
        })
        .catch(error => console.error(error))
    }, [])

    const makeClientOptions = Array.from({length: dataSet?.customers?.length}, (_, index) => {
        const makeClientOptionsInside = Array.from({length: dataSet?.customers[index]?.customers?.length}, (_, ind) => {
            return (
                <option id={`${dataSet?.customers[index]?.customers[ind]?.customer_id}`} value={dataSet?.customers[index]?.customers[ind]?.customer_name}>{dataSet?.customers[index]?.customers[ind]?.customer_name === null ? '—' : dataSet?.customers[index]?.customers[ind]?.customer_name}</option>
            )
        })
        return (
            <optgroup label={dataSet?.customers[index]?.group_name}> {makeClientOptionsInside} </optgroup>
        )

    })

    const makeCategoryOptions = Array.from({length: dataSet?.categories?.length}, (_, index) => {
        const makeCategoryOptionsInside = Array.from({length: dataSet?.categories[index]?.categories?.length}, (_, ind) => {
            return (
                <option id={`${dataSet?.categories[index]?.categories[ind]?.category_id}`} value={dataSet?.categories[index]?.categories[ind]?.category_name}>{dataSet?.categories[index]?.categories[ind]?.category_name === null ? '—' : dataSet?.categories[index]?.categories[ind]?.category_name}</option>
            )
        })  
        return (
            <optgroup label={dataSet?.categories[index]?.group_name}> {makeCategoryOptionsInside} </optgroup>
        )
    })

    const makeScoreOptions = Array.from({length: dataSet?.debets?.length}, (_, index) => {
        return (
            <option id={`${dataSet?.debets[index].id}`} value={dataSet?.debets[index]?.name}>{dataSet?.debets[index]?.name}</option>
        )
    })

    const [operationData, setOperationData] = useState()

    const [type, setType] = useState('income')
    const [date, setDate] = useState(new Date().toLocaleDateString()?.split('.')?.reverse()?.join('-'))
    const [confirmed, setConfirm] = useState(false)
    const [sum, setSum] = useState(null)
    const [debet_id, setDebet_id] = useState('1')
    const [debet2_id, setDebet2_id] = useState(null)
    const [category_id, setCategory_id] = useState(null)
    const [client_id, setClient_id] = useState(null)
    const [comment, setComment] = useState(null)
    const [invoice, setInvoice] = useState(null)
    const [inputValue, setInputValue] = useState(0)

    const [errors, setErrors] = useState()
    
    useEffect(() => {
        console.log('set_on_props_change')
        setType('income')
        setCurrentModal('income_button')
        setValue('')
        setSelectedScore(dataSet?.debets?.[0]?.name)
        setSelectedScore2(dataSet?.debets?.[0]?.name)
        setCategory_id(null)
        setSelectedCategory('—')
        setSelectedClient('—')
        setClient_id(null)
        setSelectedComment('')
        setEmploye_id(null)
        setStartDate(new Date())
    }, [props])

    useEffect(() => {
        console.log(debet_id, 'debet_id________________')
    }, [debet_id])

    const handleSelect = (event) => {
        if (currentModal === 'outlay_button') {
            setType('expense')
        } 
        if (currentModal === 'move_button') {
            setType('transfer')
        } 
        if (currentModal === 'income_button') {
            setType('income')
        }

        if (event.currentTarget.id === 'score_input' || event.currentTarget.id === 'score_input_move') {
            setDebet_id(event.currentTarget.options[event.currentTarget.selectedIndex].id)
            setSelectedScore(event.currentTarget.options[event.currentTarget.selectedIndex].name)
        }
        if (event.currentTarget.id === 'category_input') {
            setCategory_id(event.currentTarget.options[event.currentTarget.selectedIndex].id === 'null' ? null : event.currentTarget.options[event.currentTarget.selectedIndex].id)
            setSelectedCategory(event.currentTarget.options[event.currentTarget.selectedIndex].name)
        }
        if (event.currentTarget.id === 'project_input') {
            console.log(event.currentTarget.options[event.currentTarget.selectedIndex].id)
            setClient_id(event.currentTarget.options[event.currentTarget.selectedIndex].id === 'null' ? null : event.currentTarget.options[event.currentTarget.selectedIndex].id)
            setSelectedClient(event.currentTarget.options[event.currentTarget.selectedIndex].name)
        }

        if (event.currentTarget.id === 'score_input_move2') {
            setDebet2_id(event.currentTarget.options[event.currentTarget.selectedIndex].id)
            setSelectedScore2(event.currentTarget.options[event.currentTarget.selectedIndex].name)
        }
    }

    useEffect(() => {
        console.log('startDate')
        setDate(startDate?.toLocaleDateString()?.split('.')?.reverse()?.join('-'))
        const today = new Date()
        if (startDate > today) {
            setConfirm(false)
            setShowToolTip(true)
            setCanConfirm(false)
        } else {
            setShowToolTip(false)
            setCanConfirm(true)
        }
    }, [startDate])

    const [showToolTip, setShowToolTip] = useState(false)
    const [canConfirm, setCanConfirm] = useState(true)

    const handleConfirm = () => {
        const today = new Date()
        if (startDate > today) {
            setConfirm(false)
        } else {
            setConfirm(!confirmed)
            setCanConfirm(true)
        }
    }
    const [value, setValue] = useState('');

    const addCommas = num => num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const removeNonNumeric = num => num?.toString()?.replace(/[^0-9]/g, "");
    const [cost, setCost] = useState('')

    const handleSum = (event) => {

        if (currentModal === 'outlay_button') {
            setType('expense')
        } 
        if (currentModal === 'move_button') {
            setType('transfer')
        } 
        if (currentModal === 'income_button') {
            setType('income')
        }

       
        console.log(event.target.value)
        setValue(event.target.value)
        let sum = event.target.value.split('')
        sum = sum.join('')
        sum = sum.replace(/\s/g, '')
        sum = sum.replace(/[^\d.,-]/g, '')

        setValue(sum === '' ? null : sum);

    }

    useEffect(() => {
        console.log('currentModal')
        if (currentModal === 'move_button') {
            setDebet2_id(dataSet?.debets?.[0]?.id)
        } else {
            setDebet2_id(null)
        }


    }, [currentModal])

    const [file, setFile] = useState(null)

    const handleComment = (event) => {
        setComment(event.target.value)
    }

    const handleUpload = (event) => {
        setInvoice(event.target.file[0])
    }

    const APIsend = 'https://eternity-tracker.ru/api/finances/transactions/create/'
    const APIedit = 'https://eternity-tracker.ru/api/finances/transactions/edit/'
    const APIdelete = 'https://eternity-tracker.ru/api/finances/transactions/delete/'

    const [remain, setRemain] = useState(false)

    const handleRemainModal = (event) => {
        setRemain(event.target.checked)
    }

    const APIdetail = `https://eternity-tracker.ru/api/finances/transactions/detail/${props.id}`

    useEffect(() => {
        console.log('FETCH_DETAIL')
        if (props.id !== undefined) {
            setLoading(true)
        fetch(APIdetail, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false)
            setOperationData(data?.data)
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            setCurrentModal(data?.data?.type === 'income' ? 'income_button' : data?.data?.type === 'expense' ? 'outlay_button' : 'move_button')
            
        })
        .then((data) => {
            if (data?.data?.subtype === 'unification_payment') {
                props.callBackMerge(true)
            } else {
                props.callBackMerge(false)
            }
        })
        
        .catch(error => console.error(error))
    }
    }, [props.id, props.updating])

    const [selectedScore, setSelectedScore] = useState()
    const [selectedScore2, setSelectedScore2] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const [selectedClient, setSelectedClient] = useState()
    const [selectedComment, setSelectedComment] = useState()

    useEffect(() => {
        console.log('props_on_2')
        if (props.status === 'edit') {
        setSelectedScore(operationData?.debet?.name === undefined || operationData?.debet?.name === null ? '—' : operationData?.debet?.name)
        setSelectedScore2(operationData?.debet2?.name)
        setValue(operationData?.cost)
        setSelectedCategory(operationData?.category?.name === undefined || operationData?.category?.name === null ? '—' : operationData?.category?.name)
        setSelectedClient(operationData?.customer?.name)
        setSelectedComment(operationData?.comment)
        setStartDate( (operationData?.date === null || operationData?.date === undefined || operationData?.date === '') ? new Date() : new Date(operationData?.date))
        setConfirm(operationData?.confirm)
        setCategory_id(operationData?.category === null ? null : operationData?.category?.id)
        setClient_id(operationData?.customer === null ? null : operationData?.customer?.id)
        setDebet_id(operationData?.debet?.id)
        setDebet2_id(operationData?.debet2?.id === undefined ? null : operationData?.debet2?.id)
        setBase64()
        setComment(operationData?.comment)
        }
        if (props.status === 'create') {
            setDebet_id(dataSet?.debets?.[0]?.id)
            setCategory_id(null)
            setClient_id(null)
        }
    }, [operationData, props.status, props.id])

    useEffect(() => {
        console.log('types')
        if (props.status === 'edit') {
            if (operationData?.type === 'income') {
                setCurrentModal('income_button')
            }
            if (operationData?.type === 'expense') {
                setCurrentModal('outlay_button')
            }
            if (operationData?.type === 'transfer') {
                setCurrentModal('move_button')
            }
            setType(operationData?.type)
        }

        if (props.status === 'create') {
            setOperationData({invoice: null})
        }

    }, [props.status])

    useEffect(() => {
        if (props.status === 'edit') {
            setType(operationData?.type)
        }
    }, [operationData, props.status, props.id])

    useEffect(() => {
        console.log('status_props')
        if (props.status === 'edit') {
            setCommentError(null)
            setErrors(null)
            setDebetError(null)
        }
    }, [props.status])
    const [debetError, setDebetError] = useState(null)
    const [categoryError, setCategoryError] = useState(null)
    const [commentError, setCommentError] = useState(false)
    const handleSubmit = () => {

        if (comment === null || comment === undefined || comment === '') {
            setCommentError(true)
        } else {
            
        setCommentError(false)

        if (props?.status === 'create') {
            if (debet_id === debet2_id) {
                setErrors({
                    debet_id: 'error',
                    debet2_id: 'Невозможно выбрать 2 одинаковых счета'
                })
                setSuccess(true)
            }
            else {

                if (date === null || date === undefined) {
                    setErrors({
                        date: 'Поле не может быть пустым'
                    })
                } else {
                    if (value === null || value === undefined || value === '') {
                        setErrors({
                            cost: 'Поле не может быть пустым'
                        })
                    } else {
                        setErrors({
                            cost: null,
                            date: null,
                        })
                    
                


            fetch(APIsend, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    type: type,
                    date: date,
                    confirm: confirmed,
                    debet_id: debet_id,
                    debet2_id: (type === 'transfer' && debet2_id === null) ? '1' : debet2_id,
                    cost: (value === 0 || value === undefined || value === null || value === '') ? null : Number(value?.split(' ')?.join('')),
                    customer_id: props?.clientID === undefined || props?.clientID === null ? client_id : props?.clientID,
                    category_id: category_id,
                    comment: comment,
                    invoice: base64,
                    employee_id: employe_id
                })
            })
            .then(response => response.json())
            .then(data => {
                setErrors(data?.errors)
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                sessionStorage.setItem('notification', data?.notification === undefined ? 'Операция была успешно создана' : data?.notification)
                sessionStorage.setItem('type', 'create_operation')
                    if (remain) {
    
                    } else if (!remain) {
                        handleModal()
                        props.update()

                    }
                }

            })
            .catch(error => console.error(error))
        }}}
        } 
        if (props?.status === 'edit') {
            if (props.mergeType === 'edit') {



            } else {
            if (debet_id !== undefined) {
                console.log(type)
            fetch(APIedit, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${STORE.readToken()}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    transaction_id: props.id,
                    edit_data: {
                        type: type,
                        date: date,
                        confirm: confirmed,
                        debet_id: debet_id,
                        debet2_id: debet2_id,
                        cost: (value === 0 || value === undefined || value === null || value === '') ? null : Number(value),
                        customer_id: props?.clientID !== undefined || props?.clientID !== null ? client_id : props?.clientID,
                        category_id: category_id,
                        comment: comment,
                        invoice: base64,
                    }
                })
            })
            .then(response => response.json())
            .then(data => {
                setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
                if (data.success) {
                    sessionStorage.setItem('notification', data?.notification === undefined ? 'Операция была успешно изменена' : data?.notification)
                    sessionStorage.setItem('type', 'create_operation')
                    if (remain) {
    
                    } else if (!remain) {
                        handleModal()
                        props.update()
                    }
                }
                setErrors(data?.errors)
            })
            .catch(error => console.error(error))
        } else {
            if (debet_id === undefined) {
                setDebetError('Выберите счет')
            } else {
                setDebetError(null)
            }
        }
        }}
    }
    }

    useEffect(() => {
        console.log(props, currentModal, '_________________________________________________________')
    }, [props])

    const deleteOperation = () => {
        if (!operationData?.product_payment) {
        fetch(APIdelete, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                transaction_id: props.id,
            })
        })
        .then(response => response.json())
        .then(data => {
            setSuccess(data?.success)
                        setTimeout(() => {
                            setSuccess(true)
                        }, 10)
            if (data.success) {
                if (remain) {

                } else if (!remain) {
                    handleModal()
                    props.update()
                }
            }
            setErrors(data?.errors)
        })
        .catch(error => console.error(error))
    }
    }

    const [day, setDay] = useState()
    const [month, setMonth] = useState()
    const [year, setYear] = useState()

    useEffect(() => {
        console.log('operationData')
        if (operationData?.created_at !== undefined) {
            setDay(makeDayISO(operationData?.created_at))
            setMonth(makeMonthISO(operationData?.created_at))
            setYear(makeYearISO(operationData?.created_at))
        }
    }, [operationData])



    const [selectedFile, setSelectedFile] = useState(null);
    const [base64, setBase64] = useState()

  function handleFileChange(event) {
    const file = event.target.files[0];
    if (!file) return;
    
    setSelectedFile(file);

    
    convertFileToBase64(file).then((base64String) => {
      setBase64(base64String);
    }).catch((error) => {
      console.error("Error converting file:", error);
    });
  }

  function handleRemoveFile() {
    setSelectedFile(null);
  }

    
    const navigate = useNavigate()

    const handleNavigate = () => {
        if (operationData?.invoice !== null || operationData?.invoice !== undefined) {
            navigate(`https://eternity-tracker.ru/${operationData?.invoice}`)
        }
    }

    const deleteFile = () => {
        setBase64(null)
        operationData.invoice = null
    }

    const makeProducts = Array.from({length: operationData?.products?.length}, (_, index) => {
        return (
            <div className='project_input__' style={index === operationData?.products?.length - 1 ? {border: 'none', justifyContent: 'space-between', paddingLeft: '0'} : {justifyContent: 'space-between', paddingLeft: '0'}}>
                <span>{operationData?.products?.[index]?.name}</span> <span>{operationData?.products?.[index]?.price?.toLocaleString()}₽</span>
            </div>
        )
    })

    useEffect(() => {
        console.log('creal_errors')
        setErrors(null)
        setCommentError(false)
    }, [currentModal])

    const handleClient = (event) => {
        navigate('/directory/clients/detail', { state: event.currentTarget.id?.split('_')[0] })
        sessionStorage.setItem('ID', event.currentTarget.id?.split('_')[0])
    }

    const fullClient = Array.from({length: 1}, (_, index) => {
        if (operationData?.product_payment || operationData?.subtype === 'unification_payment') {
            return (
                <div className='project_input' id={`${operationData?.customer?.id}_client_id`} style={{paddingLeft: '0'}} onClick={handleClient}>
                    {operationData?.customer?.name}
                </div>
            )
        } else {
        return (
            <select disabled={operationData?.product_payment} id='project_input' value={selectedClient} placeholder='Не выбран' onChange={handleSelect}>
                <option value='null' id='null' style={operationData?.product_payment ? {display:'none'} : {display: 'block'}} selected>—</option>
            {makeClientOptions}
        </select>
        )}
    })

    const EMPLOYEES = 'https://eternity-tracker.ru/api/finances/directory/employees/'

    const [employe, setEmploye] = useState()

    useEffect(() => {
            setLoading(true)
        fetch(EMPLOYEES, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setEmploye(data?.data)
        })
        .catch(error => console.error(error))
        setLoading(false)
    }, [props.id, props.updating])


    const employeList = employe?.map(item => {
        return (
            <optgroup label={item.department_name}>
                {item?.employees?.map((emp, i) => {
                    return (
                        <option value={emp.id}>{emp.name} {emp.last_name}</option>
                    )
                })}
            </optgroup>
        )
    })

    const handleEmploye = (event) => {
        setEmploye_id(event.currentTarget.options[event.currentTarget.selectedIndex].value === 'null' ? null : event.currentTarget.options[event.currentTarget.selectedIndex].value)
    }
    

    if (loading) {
        return (
            <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
  <circle
    class="active"
    pathLength="360"
    fill="transparent"
    stroke-width="32"
    cx="192"
    cy="192"
    r="176"
  ></circle>
  <circle
    class="track"
    pathLength="360"
    fill="transparent"
    stroke-width="32"
    cx="192"
    cy="192"
    r="176"
  ></circle>
</svg>
</div>
        )
    } else {

    if (currentModal === 'income_button') {
        return (
            
            <div className='new_operation_container'>
                        <Error errors={!success} message={errors} />
            <div className='row1'>{operationData?.subtype === 'unification_payment' ? 'Объединенный платеж' : operationData?.product_payment ? 'Платеж по услуге' : props?.status === 'edit' ? `Операция` : 'Создание операции'}
                <div className='creator_box' style={props?.status === 'edit' ? {display: 'flex'} : {display: 'none'}}>
                    <span>Создатель: {operationData?.creator?.name}</span>
                    <span>Создана: {day} {month} {year}</span>
                </div>
            </div>
            <div className='buttons_row'>
                <div className={currentModal === 'income_button' ? 'income_button_active' : 'income_button'} onClick={handleChangeModal}>Доход</div>
                <div className={currentModal === 'outlay_button' ? 'outlay_button_active' : 'outlay_button'} style={props.status === 'edit' && currentModal !== 'outlay_button' ? {color: 'rgb(99, 99, 99)', cursor: 'not-allowed'} : {color: 'white'} } onClick={handleChangeModal}>Расход</div>
                <div className={currentModal === 'move_button' ? 'move_button_active' : 'move_button'} style={props.status === 'edit' && currentModal !== 'move_button' ? {color: 'rgb(99, 99, 99)', cursor: 'not-allowed'} : {color: 'white'} } onClick={handleChangeModal}>Перемещение</div>
            </div>

            <div className='data_row modal_row'>
                Дата оплаты
                <DatePicker className={typeof errors?.date?.[0] === 'string' ? 'data_pick_error' : operationData?.product_payment ? 'data_pick_blocked' : 'data_pick'} disabled={operationData?.product_payment || operationData?.subtype === 'unification_payment'} dateFormat='dd/MM/YYYY' selected={startDate} onChange={(date) => { if (!operationData?.product_payment) {setStartDate(date)} }} placeholderText={'Укажите дату'} locale={ru}/>
                <div class="checkbox-wrapper-46" id='checkbox10_'>
                            <div className={showToolTip ? 'tooltip' : 'tooltip_inactive'}>Невозможно подтвердить будущую операцию</div>
                            <input type="checkbox" id="cbx-40"  onClick={handleConfirm} checked={confirmed} class="inp-cbx"/>
                            <label for="cbx-40" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1' id='label_10' style={!canConfirm ? {color: 'rgb(99, 99, 99)'} : {color: 'white'}}>
                                    Подтвердить оплату
                                </span>
                            </label>
                        </div>
            </div>
            <div className='score_row modal_row'>
                Счет
                <select type='text' id='score_input' placeholder='Выберите счет...' value={selectedScore} onChange={handleSelect} style={(typeof debetError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}}>
                <option value='—' selected style={operationData?.debet === null ? {display: 'block'} : {display: 'none'}}>—</option>
                    {makeScoreOptions}
                </select>
                <label for='score_input' className='error_message_'>{debetError}</label>
            </div>
            <div className='sum_row modal_row'>
                Сумма
                <CurrencyInput decimalSeparator='.' disabled={operationData?.product_payment || operationData?.subtype === 'unification_payment'} prefix='₽' decimalsLimit={2} value={value} onChange={handleSum} id='sum_input' placeholder='0' style={(typeof errors?.cost?.[0] === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}}/>
                <label for='sum_input' className='error_message_'>{typeof errors?.cost?.[0] === 'string' ? 'Поле не может быть пустым' : null}</label>
            </div>
            <div className='category_row modal_row'>
                Категория
                <select type='text' id='category_input' value={selectedCategory} placeholder='Выберите категорию...' onChange={handleSelect} style={(typeof categoryError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}} >
                    <option value='null' id='null' selected>—</option>
                    {makeCategoryOptions}
                </select>
                <label for='category_input' className='error_message_'>{categoryError}</label>
            </div>
            <div className='project_row modal_row'  style={props?.clientID !== undefined ? {display: 'none'} : {display: 'flex'}}>
                <span>Клиент</span>
                {fullClient}
            </div>
            <div className='project_row modal_row' style={operationData?.product_payment || operationData?.subtype === 'unification_payment' ? {display: 'flex'} : {display: 'none'}}><span style={{width: '3.4vw'}}>Услуга</span>
                <div className='project_input__' style={operationData?.subtype === 'unification_payment' ? {display: 'none'} : {display: 'flex'}}>
                    {operationData?.product}
                </div>
                <div className='merge_products' style={operationData?.subtype === 'unification_payment' ? {display: 'flex'} : {display: 'none'}}>
                        {makeProducts}
                    </div>
            </div>
            <div className='comment_row modal_row' onChange={handleComment}>
                Назначение платежа
                <textarea id='comment_input' value={selectedComment} style={commentError ? {border: '0.1vw solid rgb(255, 0, 0)', marginLeft: '2.5vw'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}} onChange={(event) => setSelectedComment(event.target.value)}/>
                <label for='comment_input' className='error_message_'>{commentError ? 'Поле не может быть пустым' : null}</label>
            </div>
            <div className='file_row modal_row'>
            <MediaQuery minWidth={900}>
                Инвойс 
                <label class="input-file">
                    {(operationData?.invoice === null || operationData?.invoice === undefined) ? <>	   	            <input type="file" name="file" onChange={handleFileChange}/>		
                    <span>{selectedFile?.name === null || selectedFile?.name === undefined ? 'Выберите файл' : selectedFile?.name}</span></> : <div className='upload_button'> <Link target='_blank' style={{color: 'white'}} to={`https://eternity-tracker.ru${operationData?.invoice}`}> <div className='open_file'> Открыть файл </div> </Link> </div> }
 	            </label>
                <div className='delete_file' style={operationData?.invoice === null || operationData?.invoice === undefined ? {display: 'none'} : {display: 'flex'}}  onClick={deleteFile}>
                    X
                </div>
                </MediaQuery>
                <MediaQuery minWidth={340} maxWidth={900}>
                Инвойс 
                <div className='file_row_'>
                <label class="input-file">
                    {(operationData?.invoice === null || operationData?.invoice === undefined) ? <>	   	            <input type="file" name="file" onChange={handleFileChange}/>		
                    <span>{selectedFile?.name === null || selectedFile?.name === undefined ? 'Выберите файл' : selectedFile?.name}</span></> : <div className='upload_button'> <Link target='_blank' style={{color: 'white'}} to={`https://eternity-tracker.ru${operationData?.invoice}`}> <div className='open_file'> Открыть файл </div> </Link> </div> }
 	            </label>
                <div className='delete_file' style={operationData?.invoice === null || operationData?.invoice === undefined ? {display: 'none'} : {display: 'flex'}}  onClick={deleteFile}>
                    X
                </div>
                </div>
                </MediaQuery>
            </div>
            <div className='footer_row'>
                <div className={operationData?.product_payment ? 'delete_button_blocked' : 'delete_button'} onClick={deleteOperation} style={props?.status === 'edit' && operationData?.subtype !== 'unification_payment'  ? {display: 'flex'} :  {display: 'none'}}>
                    Удалить
                </div>
                <div className='cancel' style={props?.status === 'edit' ? {display: 'none'} : {display: 'flex'}} onClick={handleModal}>
                    Отменить
                </div>
                <div className='create' id='create_income' onClick={handleSubmit}>
                    {props?.status === 'edit' ? 'Сохранить' : 'Создать'}
                </div>
            </div>
        </div>
        )
    } else if (currentModal === 'outlay_button') {
        return (
            <div className='new_operation_container'>
                        <Error errors={!success} message={errors} />
            <div className='row1'>{props?.status === 'edit' ? `Операция` : 'Создание операции'}
                <div className='creator_box' style={props?.status === 'edit' ? {display: 'flex'} : {display: 'none'}}>
                    <span>Создатель: {operationData?.creator?.name}</span>
                    <span>Создана: {day} {month} {year}</span>
                </div>
            </div>
            <div className='buttons_row'>
                <div className={currentModal === 'income_button' ? 'income_button_active' : 'income_button'} style={props.status === 'edit' && currentModal !== 'income_button' ? {color: 'rgb(99, 99, 99)', cursor: 'not-allowed'} : {color: 'white'} } onClick={handleChangeModal}>Доход</div>
                <div className={currentModal === 'outlay_button' ? 'outlay_button_active' : 'outlay_button'}  onClick={handleChangeModal}>Расход</div>
                <div className={currentModal === 'move_button' ? 'move_button_active' : 'move_button'} style={props.status === 'edit' && currentModal !== 'move_button' ? {color: 'rgb(99, 99, 99)', cursor: 'not-allowed'} : {color: 'white'} } onClick={handleChangeModal}>Перемещение</div>
            </div>

            <div className='data_row modal_row'>
                Дата оплаты
                <DatePicker className={typeof errors?.date?.[0] === 'string' ? 'data_pick_error' : operationData?.product_payment ? 'data_pick_blocked' : 'data_pick'} readOnly={operationData?.product_payment} dateFormat='dd/MM/YYYY' selected={startDate} onChange={(date) => { if (!operationData?.product_payment) {setStartDate(date)} }} placeholderText={'Укажите дату'} locale={ru}/>
                <div class="checkbox-wrapper-46" id='checkbox10_'>
                <div className={showToolTip ? 'tooltip' : 'tooltip_inactive'}>Невозможно подтвердить будущую операцию</div>
                            <input type="checkbox" id="cbx-40"  onClick={handleConfirm} checked={confirmed} class="inp-cbx"/>
                            <label for="cbx-40" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1' id='label_10'  style={!canConfirm ? {color: 'rgb(99, 99, 99)'} : {color: 'white'}}>
                                    Подтвердить оплату
                                </span>
                            </label>
                        </div>
            </div>
            <div className='score_row modal_row'>
                Счет
                <select type='text' id='score_input' placeholder='Выберите счет...' value={selectedScore} onChange={handleSelect}>
                <option value='—' selected style={operationData?.debet === null ? {display: 'block'} : {display: 'none'}}>—</option>
                    {makeScoreOptions}
                </select>
            </div>
            <div className='sum_row modal_row' onChange={handleSum}>
                Сумма
                <CurrencyInput decimalSeparator='.'type='text' disabled={operationData?.product_payment} prefix='₽' value={value} onChange={handleSum} id='sum_input' placeholder='0' style={(typeof errors?.cost?.[0] === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}}/>
                <label htmlFor='sum_input' className='error_message_'>{typeof errors?.cost?.[0] === 'string' ? 'Поле не может быть пустым' : null}</label>
            </div>
            <div className='category_row modal_row'>
                Категория
                <select type='text' id='category_input' value={selectedCategory} placeholder='Выберите категорию...' onChange={handleSelect} style={(typeof categoryError === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}} >
                    <option value='null' id='null' selected>—</option>
                    {makeCategoryOptions}
                </select>
                <label for='category_input' className='error_message_'>{categoryError}</label>
            </div>
            <div className='project_row modal_row'  style={props?.clientID !== undefined ? {display: 'none'} : {display: 'flex'}}>
                Клиент
                {fullClient}
            </div>
            <div className='project_row modal_row' style={operationData?.subtype === 'salary' || props.status === 'create' ? {display: 'flex'} : {display: 'none'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '4.75vw'}}>
                    <span>Сотрудник</span>
                    <span style={{marginTop: '0.2vw', color: 'rgb(150, 150, 150)'}}>Для зарплат</span>
                </div>
                <select onChange={handleEmploye} id='category_input'>
                    {employeList}
                </select>
            </div>
            <div className='project_row modal_row' style={operationData?.product_payment ? {display: 'flex'} : {display: 'none'}}><span style={{width: '3.4vw'}}>Услуга</span>
                <div disabled={operationData?.product_payment} id={`project_input`} style={{cursor: 'pointer'}} >
                    <option value={operationData?.product}>{operationData?.product}</option>
                </div>
            </div>
            <div className='comment_row modal_row' onChange={handleComment}>
                Назначение платежа
                <textarea id='comment_input' value={selectedComment} style={commentError ? {border: '0.1vw solid rgb(255, 0, 0)', marginLeft: '2.5vw'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}} onChange={(event) => setSelectedComment(event.target.value)}/>
                <label htmlFor='comment_input' className='error_message_'>{commentError ? 'Поле не может быть пустым' : null}</label>
            </div>
            <div className='file_row modal_row'>
                <MediaQuery minWidth={900}>
                Инвойс 
                <label class="input-file">
                    {(operationData?.invoice === null || operationData?.invoice === undefined) ? <>	   	            <input type="file" name="file" onChange={handleFileChange}/>		
                    <span>{selectedFile?.name === null || selectedFile?.name === undefined ? 'Выберите файл' : selectedFile?.name}</span></> : <div className='upload_button'> <Link target='_blank' style={{color: 'white'}} to={`https://eternity-tracker.ru${operationData?.invoice}`}> <div className='open_file'> Открыть файл </div> </Link> </div> }
 	            </label>
                <div className='delete_file' style={operationData?.invoice === null || operationData?.invoice === undefined ? {display: 'none'} : {display: 'flex'}}  onClick={deleteFile}>
                    X
                </div>
                </MediaQuery>
                <MediaQuery minWidth={340} maxWidth={900}>
                Инвойс 
                <div className='file_row_'>
                <label class="input-file">
                    {(operationData?.invoice === null || operationData?.invoice === undefined) ? <>	   	            <input type="file" name="file" onChange={handleFileChange}/>		
                    <span>{selectedFile?.name === null || selectedFile?.name === undefined ? 'Выберите файл' : selectedFile?.name}</span></> : <div className='upload_button'> <Link target='_blank' style={{color: 'white'}} to={`https://eternity-tracker.ru${operationData?.invoice}`}> <div className='open_file'> Открыть файл </div> </Link> </div> }
 	            </label>
                <div className='delete_file' style={operationData?.invoice === null || operationData?.invoice === undefined ? {display: 'none'} : {display: 'flex'}}  onClick={deleteFile}>
                    X
                </div>
                </div>
                </MediaQuery>
            </div>
            <div className='footer_row'>
                <div className={operationData?.product_payment ? 'delete_button_blocked' : 'delete_button'} onClick={deleteOperation} style={props?.status === 'edit' ? {display: 'flex'} : {display: 'none'}}>
                    Удалить
                </div>
                <div className='cancel' style={props?.status === 'edit' ? {display: 'none'} : {display: 'flex'}} onClick={handleModal}>
                    Отменить
                </div>
                <div className='create' id='create_income' onClick={handleSubmit}>
                    {props?.status === 'edit' ? 'Сохранить' : 'Создать'}
                </div>
            </div>
        </div>
        )
    } else if (currentModal === 'move_button') {
        return (
            <div className='new_operation_container' id='container_move_modal'>
                        <Error errors={!success} message={errors} />
            <div className='row1'>{props?.status === 'edit' ? `Операция` : 'Создание операции'}</div>
            <div className='buttons_row'>
                <div className='income_button' onClick={handleChangeModal} style={props.status === 'edit' && currentModal !== 'income_button' ? {color: 'rgb(99, 99, 99)', cursor: 'not-allowed'} : {color: 'white'} }>Доход</div>
                <div className='outlay_button' onClick={handleChangeModal} style={props.status === 'edit' && currentModal !== 'outlay_button' ? {color: 'rgb(99, 99, 99)', cursor: 'not-allowed'} : {color: 'white'} }>Расход</div>
                <div className='move_button_active' onClick={handleChangeModal}>Перемещение</div>
            </div>
            <div className='data_row modal_row' id='modal_row_move_modal'>
            <div className='from_line_box'>
                <div className='from_line'></div>
                Откуда
                <div className='from_line'></div>
            </div>
                Дата оплаты
                <DatePicker className={typeof errors?.date?.[0] === 'string' ? 'data_pick_error_move' : 'data_pick_move'} selected={startDate} dateFormat='dd/MM/YYYY' onChange={(date) => setStartDate(date)} placeholderText={'Укажите дату'}                    locale={ru}/>
                <div class="checkbox-wrapper-46" id='checkbox10_'>
               <div className={showToolTip ? 'tooltipmove' : 'tooltip_inactive'}>Невозможно подтвердить будущую операцию</div>
                            <input type="checkbox" id="cbx-40"  onClick={handleConfirm} checked={confirmed} class="inp-cbx" />
                            <label for="cbx-40" class="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check1' id='label_10'  style={!canConfirm ? {color: 'rgb(99, 99, 99)'} : {color: 'white'}}>
                                    Подтвердить оплату
                                </span>
                            </label>
                        </div>
            </div>
            <div className='score_row modal_row' id='score_input_move_box'>
                Счет
                <select type='text' id='score_input_move' placeholder='Выберите счет...' style={(typeof errors?.debet_id?.[0] === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}} value={selectedScore} onChange={handleSelect}>
                    {makeScoreOptions}
                </select>
                <label htmlFor='score_input_move' className='error_message_'>{errors?.debet2_id}</label>
            </div>
            <div className='sum_row modal_row'>
                Сумма списания
                <CurrencyInput decimalSeparator='.'type='text' prefix='₽' value={value} onChange={handleSum} id='sum_input_move' placeholder='0' style={(typeof errors?.cost?.[0] === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}}/>
                <label htmlFor='sum_input_move' className='error_message_'>{typeof errors?.cost?.[0] === 'string' ? 'Поле не может быть пустым' : null}</label>
            </div>
            <div className='score_row modal_row' id='score_input_move_box2'>
            <div className='to_line_box'>
                <div className='from_line'></div>
                Куда
                <div className='from_line'></div>
            </div>
                Счет
                <select type='text' id='score_input_move2' placeholder='Выберите счет...' style={(typeof errors?.debet2_id?.[0] === 'string') ? {border: '0.1vw solid rgb(255, 0, 0)'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}} value={selectedScore2} onChange={handleSelect}>
                    {makeScoreOptions}
                </select>
            </div>
            <div className='modal_row'>
                Сумма зачисления
                <CurrencyInput decimalSeparator='.'type='text' id='sum_input_move_modal2' prefix='₽' value={value} disabled='disabled' style={{color: 'gray'}}/>
            </div>
            <div className='comment_row modal_row' onChange={handleComment}>
                Назначение платежа
                <textarea type='text' id='comment_input_move' style={commentError ? {border: '0.1vw solid rgb(255, 0, 0)', marginLeft: '3.5vw'} : {border: '0.1vw solid rgb(255, 255, 255, 0.15);'}}  value={selectedComment} onChange={(event) => setSelectedComment(event.target.value)} />
                <label htmlFor='comment_input_move' className='error_message_'>{commentError ? 'Поле не может быть пустым' : null}</label>
            </div>
                        <div className='file_row modal_row'>
                        <MediaQuery minWidth={900}>
                Инвойс 
                <label class="input-file" style={{marginLeft: '6vw'}}>
                    {(operationData?.invoice === null || operationData?.invoice === undefined) ? <>	   	            <input type="file" name="file" onChange={handleFileChange}/>		
                    <span>{selectedFile?.name === null || selectedFile?.name === undefined ? 'Выберите файл' : selectedFile?.name}</span></> : <div className='upload_button'> <Link target='_blank' style={{color: 'white'}} to={`https://eternity-tracker.ru${operationData?.invoice}`}> <div className='open_file'> Открыть файл </div> </Link> </div> }
 	            </label>
                <div className='delete_file' style={operationData?.invoice === null || operationData?.invoice === undefined ? {display: 'none'} : {display: 'flex'}}  onClick={deleteFile}>
                    X
                </div>
                </MediaQuery>
                <MediaQuery minWidth={340} maxWidth={900}>
                Инвойс 
                <div className='file_row_'>
                <label class="input-file">
                    {(operationData?.invoice === null || operationData?.invoice === undefined) ? <>	   	            <input type="file" name="file" onChange={handleFileChange}/>		
                    <span>{selectedFile?.name === null || selectedFile?.name === undefined ? 'Выберите файл' : selectedFile?.name}</span></> : <div className='upload_button'> <Link target='_blank' style={{color: 'white'}} to={`https://eternity-tracker.ru${operationData?.invoice}`}> <div className='open_file'> Открыть файл </div> </Link> </div> }
 	            </label>
                <div className='delete_file' style={operationData?.invoice === null || operationData?.invoice === undefined ? {display: 'none'} : {display: 'flex'}}  onClick={deleteFile}>
                    X
                </div>
                </div>
                </MediaQuery>
            </div>
            <div className='footer_row'>
                <div className='delete_button' onClick={deleteOperation}  style={props?.status === 'edit' ? {display: 'flex'} : {display: 'none'}}>
                    Удалить
                </div>
                <div className='cancel' style={props?.status === 'edit' ? {display: 'none'} : {display: 'flex'}} onClick={handleModal}>
                    Отменить
                </div>
                <div className='create' id='create_income' onClick={handleSubmit}>
                    {props?.status === 'edit' ? 'Сохранить' : 'Создать'}
                </div>
            </div>
        </div>
        )
    }}

}

export default NewOperation