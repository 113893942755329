import ImportantIcon from "../../../images/important.png";

export const TransactionsList = ({ transactions }) => {
  return (
    <div className="chart-transactions">
      {transactions.map((transaction) => (
        <TransactionListItem key={transaction.id} transaction={transaction} />
      ))}
    </div>
  );
};

const TransactionListItem = ({ transaction }) => {
  const transactionTypeClassName = {
    income: "income",
    expense: "expense",
  }[transaction.type];

  const title = {
    income: "Доход",
    expense: "Расход",
  }[transaction.type];

  const costPrefix = {
    income: "+",
    expense: "-",
  }[transaction.type];

  const transactionStatusClassName = {
    plan: "plan",
    expired: "expired",
  }[transaction.status];

  const formatCost = (cost) => {
    if (!cost) return "0 ₽";
    return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₽";
  };

  return (
    <div
      className={`chart-transaction chart-transaction-${transactionTypeClassName} chart-transaction-status-${transactionStatusClassName}`}
    >
      <p className="chart-transaction-title">{title}</p>
      <div style={{ marginTop: "10px" }}>
        <p>Назначение платежа: -</p>
        <p>Счет: -</p>
        <div className="chart-transaction-cost-wrapper">
          <div>
            Сумма:{" "}
            <span className="chart-transaction-cost">
              {costPrefix}
              {formatCost(transaction.cost)}
            </span>
          </div>
          {transaction.status === "expired" && (
            <img
              className="chart-transaction-important-icon"
              src={ImportantIcon}
              alt="important"
            />
          )}
        </div>
      </div>
    </div>
  );
};
